<template>
  <v-container>
    <v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="8" lg="6">
          <v-card color="primary">
            <v-img
              class="white--text align-end"
              :src="companySetup.image"
              width="1000"
            >
            </v-img>

            <v-card-text style="color: white">
              <h2 class="mt-5"></h2>
              <h3>{{textApp.label00001}} {{version}}</h3>
              <br />
              <p>
                <b>{{companySetup.company_name}}</b> <br />
                {{textApp.label00002}} {{companySetup.piva}} <br />
                {{textApp.label00003}} {{companySetup.pec}}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import { version } from "../config/config.js";
export default {
  data() {
    return {
      version: null
    };
  },
  mounted() {
    this.version = version;
  },
  computed: {
    companySetup: {
      get: function() {
        return this.$store.getters.getCompanySetup;
      }
    },
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      }
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
  }
};
</script>
<style scoped></style>
