<template>
  <v-container class="pt-0 pb-0 mt-3" id="filtriDateAgenti">
    <v-row align="start" justify="end">
      <!--<v-col
        class="pl-0 pr-0 ml-1"
        cols="12"
        sm="4"
        md="2"
        v-if="filterAgentEnable"
      >
        <v-menu v-model="menuAgent" :close-on-content-click="false" offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Agente"
              v-model="agents"
              v-bind="attrs"
              v-on="on"
              append-icon="mdi-account"
              outlined
              dense
            ></v-text-field>
          </template>

          <v-card>
            <v-card-text>
              <v-row v-if="listAgents.length > 0" justify="center">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left"></th>
                        <th class="text-left">ID</th>
                        <th class="text-left">Nome</th>
                        <th class="text-left">Indirizzo IP</th>
                        <th class="text-left">Sistema operativo</th>
                        <th class="text-left">Data collegamento</th>
                        <th class="text-left">Ultimo segnale</th>
                        <th class="text-left">Stato</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in listAgents" :key="i">
                        <td>
                          <v-checkbox v-model="item.selected"></v-checkbox>
                        </td>
                        <td>{{ item.id }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.ip }}</td>
                        <td>{{ item.uname }}</td>
                        <td>{{ item.dateAdd }}</td>
                        <td>{{ item.lastKeepAlive }}</td>
                        <td v-if="item.status == 'Active'" style="color: green">
                          Attivo
                        </td>
                        <td
                          v-if="item.status == 'Disconnected'"
                          style="color: red"
                        >
                          Disconnesso
                        </td>
                        <td v-if="item.status == 'Never connected'">
                          mai connesso
                        </td>
                        <td v-if="item.status == 'Pending'">
                          {{ item.status }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                @click="
                  menuAgent = false;
                  agent = null;
                "
              >
                Annulla
              </v-btn>
              <v-btn color="primary" text @click="selectAgent()">
                Seleziona
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>-->
      <v-col
        class="pl-0 pr-0 ml-1"
        cols="12"
        sm="4"
        md="2"
        v-if="filterGroupEnable"
      >
        <v-select
          :disabled="loadingGroups"
          :items="listGroup"
          v-model="groupSelected"
          :label="textApp.label00069"
          outlined
          dense
          clearable
        ></v-select>
      </v-col>
      <v-col
        class="pl-0 pr-0 ml-1"
        cols="12"
        sm="4"
        md="2"
        v-if="filterDatesEnable"
      >
        <v-menu
          v-model="menuStartDate"
          :close-on-content-click="false"
          offset-x
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :label="textApp.label00070"
              v-model="startDate"
              v-bind="attrs"
              v-on="on"
              append-icon="mdi-calendar"
              outlined
              dense
            ></v-text-field>
          </template>

          <v-card>
            <v-date-picker
              v-model="startDatePicker"
              locale="it-IT"
            ></v-date-picker>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                @click="
                  menuStartDate = false;
                  startDate = null;
                "
              >
                {{textApp.label00012}}
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="
                  menuStartDate = false;
                  startDate = startDatePicker;
                "
              >
                {{textApp.label00072}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
      <v-col
        class="pl-0 pr-0 ml-1"
        cols="12"
        sm="4"
        md="2"
        v-if="filterDatesEnable"
      >
        <v-menu
          v-model="menuEndDate"
          :close-on-content-click="false"
          offset-x
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :label="textApp.label00071"
              v-model="endDate"
              v-bind="attrs"
              v-on="on"
              append-icon="mdi-calendar"
              outlined
              dense
            ></v-text-field>
          </template>

          <v-card>
            <v-date-picker v-model="endDatePicker" locale="it"></v-date-picker>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                @click="
                  menuEndDate = false;
                  endDate = null;
                "
              >
                {{textApp.label00012}}
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="
                  menuEndDate = false;
                  endDate = endDatePicker;
                "
              >
                {{textApp.label00072}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
      <v-tooltip bottom v-if="filterDatesEnable">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="mr-5 ml-1 mt-3"
            color="primary"
            style="height: 40px"
            @click="setDateFilters()"
          >
            {{textApp.label00074}}
            <v-icon right dark> mdi-cached </v-icon>
          </v-btn>
        </template>
        <span>{{textApp.label00073}}</span>
      </v-tooltip>
    </v-row>
  </v-container>
</template>

<script>
import timezone from "../utils/timezone.js"
export default {
  data() {
    return {
      menuStartDate: null,
      menuEndDate: null,
      menuAgent: null,
      startDatePicker: null,
      endDatePicker: null,
      agentPicker: null,
      startDate: null,
      endDate: null,
      agents: null,
      listAgents: [],
      listGroup: [],
      loadingGroups: false,
      groupSelected: null
    };
  },
  mounted() {
    this.startDatePicker = timezone.getLocalDateStr(this.filterStartDate).substr(0, 10);
    this.endDatePicker = timezone.getLocalDateStr(this.filterEndDate).substr(0, 10);
    this.startDate = timezone.getLocalDateStr(this.filterStartDate).substr(0, 10);
    this.endDate = timezone.getLocalDateStr(this.filterEndDate).substr(0, 10);
    if (this.company != null) {
      this.loadData();
    }
  },
  created() {
    //this.interval = setInterval(() => this.loadData(), 30 * 60 * 1000);
  },
  methods: {
    setDateFilters() {
      this.filterStartDate = timezone.convertDateToUTC(this.startDate + "T00:00:00");
      this.filterEndDate = timezone.convertDateToUTC(this.endDate + "T" + (new Date()).toLocaleTimeString('en-US', { hour12: false }));
      this.agentsSelected = this.agents;
      this.filterGroup = this.groupSelected;
      this.reloadButton ++;
    },
    loadData() {
      if(this.company != null) {
        this.groupSelected = null;
        this.filterGroup = null;
        this.listGroup = [];
        this.loadingGroups = true;
        this.axios({
          method: "GET",
          url: "/agents/groups",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.token}`
          },
          params: {
            company: this.company.id
          }
        }).then(resp => {
          console.log(resp.data);
          resp.data.forEach(item => {
            this.listGroup.push(item.name);
          });
          this.loadingGroups = false;
        });
      }
    },
    selectAgent() {
      this.menuAgent = false;
      this.agents = [];
      this.listAgents.forEach(item => {
        if (item.selected) {
          this.agents.push(item.id);
        }
      });
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      }
    },
    filterStartDate: {
      get: function() {
        return this.$store.getters.getStartDate;
      },
      set: function(value) {
        this.$store.dispatch("setStartDate", value);
      }
    },
    filterEndDate: {
      get: function() {
        return this.$store.getters.getEndDate;
      },
      set: function(value) {
        this.$store.dispatch("setEndDate", value);
      }
    },
    agentsSelected: {
      get: function() {
        return this.$store.getters.getAgents;
      },
      set: function(value) {
        this.$store.dispatch("setAgents", value);
      }
    },
    filterGroup: {
      get: function() {
        return this.$store.getters.getGroup;
      },
      set: function(value) {
        this.$store.dispatch("setGroup", value);
      }
    },
    filterDatesEnable: {
      get: function() {
        return this.$store.getters.getFilterDatesEnable;
      },
      set: function(value) {
        this.$store.dispatch("setFilterDatesEnable", value);
      }
    },
    reloadButton: {
      get: function() {
        return this.$store.getters.getReloadButton;
      },
      set: function(value) {
        this.$store.dispatch("setReloadButton", value);
      }
    },
    /*filterAgentEnable: {
      get: function() {
        return this.$store.getters.getFilterAgentEnable;
      },
      set: function(value) {
        this.$store.dispatch("setFilterAgentEnable", value);
      }
    },*/
    filterGroupEnable: {
      get: function() {
        return this.$store.getters.getFilterGroupEnable;
      },
      set: function(value) {
        this.$store.dispatch("setFilterGroupEnable", value);
      }
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
    token() {
      return this.$store.getters.getToken;
    }
  },
  watch: {
    token() {
      this.loadData();
    },
    filterGroupEnable() {
      if (this.filterGroupEnable) {
        this.loadData();
      } else {
        this.groupSelected = null;
        this.filterGroup = null;
      }
    },
    filterStartDate() {
      this.startDatePicker = timezone.getLocalDateStr(this.filterStartDate).substr(0, 10);
      this.startDate = timezone.getLocalDateStr(this.filterStartDate).substr(0, 10);
    },
    filterEndDate() {
      this.endDatePicker = timezone.getLocalDateStr(this.filterEndDate).substr(0, 10);
      this.endDate = timezone.getLocalDateStr(this.filterEndDate).substr(0, 10);
    },
    reloadButton() {
      this.startDatePicker = timezone.getLocalDateStr(this.filterStartDate).substr(0, 10);
      this.startDate = timezone.getLocalDateStr(this.filterStartDate).substr(0, 10);
      this.endDatePicker = timezone.getLocalDateStr(this.filterEndDate).substr(0, 10);
      this.endDate = timezone.getLocalDateStr(this.filterEndDate).substr(0, 10);
      this.filterStartDate = timezone.convertDateToUTC(this.startDate + "T00:00:00");
      this.filterEndDate = timezone.convertDateToUTC(this.endDate + "T23:59:59");
    }
  }
};
</script>
<style scoped>
#filtriDateAgenti {
  height: 60px;
}
</style>
