import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#E94B5A", //"#FF5252",
        secondary: "#243A85", //"#455A64",
        accent: "#00A8C1", //"#82B1FF",
        error: "#B71C1C",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        levelCritical: "#C51162",
        levelHigh: "#E53935",
        levelMedium: "#FF8F00",
        levelLow: "#2E7D32"
      }
    }
  }
});
