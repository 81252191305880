var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-dialog',{attrs:{"width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"secondary"}},Object.assign({}, tooltip, dialog)),[_c('v-icon',[_vm._v("mdi-format-list-bulleted-square")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.textApp.label00165))])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('h4',{style:({ color: _vm.$vuetify.theme.themes.light.secondary })},[_vm._v(" "+_vm._s(_vm.textApp.label00166)+" ")])]),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-card',{staticClass:"mb-3 mt-3",attrs:{"outlined":""}},[_c('v-card-text',[_c('h3',{staticClass:"mb-2",style:({ color: _vm.$vuetify.theme.themes.light.secondary })},[_vm._v(" "+_vm._s(_vm.textApp.label00167)+" ")]),_vm._l((_vm.listPartecipants),function(p,j){return _c('b',{key:j,staticClass:"mb-0"},[_vm._v(" - "+_vm._s(p.name)+" "+_vm._s(p.surname)+" "),_c('br')])})],2)],1),_c('v-divider'),_c('v-card',{staticClass:"mt-3",attrs:{"outlined":""}},[_c('v-card-text',[_c('h3',{staticClass:"mb-2",style:({ color: _vm.$vuetify.theme.themes.light.secondary })},[_vm._v(" "+_vm._s(_vm.textApp.label00168)+" ")]),_c('b',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.warning.sender_name)+" "+_vm._s(_vm.warning.sender_surname))]),_c('h4',[_vm._v(_vm._s(_vm.warning.object))]),_c('p',[_c('span',{staticStyle:{"white-space":"pre-line"}},[_vm._v(_vm._s(_vm.warning.description))])]),(_vm.warning.metadata != '')?_c('span',[(JSON.parse(_vm.warning.metadata).type == 'generic')?_c('span',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-3 mr-7",attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.applyFilters(JSON.parse(_vm.warning.metadata))}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.textApp.label00440)+" ")])]}}],null,false,1013922210)},[_c('span',[_vm._v(_vm._s(_vm.textApp.label00441))])])],1),_c('v-tabs',{attrs:{"vertical":""}},[_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-chart-line-variant ")]),_vm._v(" "+_vm._s(_vm.textApp.label00106)+" ")],1),_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-chart-pie ")]),_vm._v(" "+_vm._s(_vm.textApp.label00169)+" ")],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('LineTemplate',{attrs:{"title":_vm.textApp.label00106,"label":_vm.textApp.label00170,"linkAPI":"/charts/generic/events","heightStyle":"200px","heightChart":1,"widthChart":2,"filters":JSON.parse(_vm.warning.metadata).filters,"colorsSet":"colorsChartLineDirigente"}})],1)],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('DoughnutTemplate',{attrs:{"title":_vm.textApp.label00169,"linkAPI":"/charts/generic/rid","heightStyle":"200px","widthChart":2,"filters":JSON.parse(_vm.warning.metadata).filters,"colorsSet":"colorsChartRID"}})],1)],1)],1)],1)],1):_vm._e(),(JSON.parse(_vm.warning.metadata).type == 'categ')?_c('span',[_c('v-row',{staticClass:"mb-3",attrs:{"justify":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.applyFilters(JSON.parse(_vm.warning.metadata))}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.textApp.label00440)+" ")])]}}],null,false,1093423735)},[_c('span',[_vm._v(_vm._s(_vm.textApp.label00441))])])],1),_c('BarTemplate',{attrs:{"title":_vm.textApp.label00103,"linkAPI":"/charts/categ/tags","filters":JSON.parse(_vm.warning.metadata).filters,"colorsSet":"colorsChartCategDPO"}})],1):_vm._e()]):_vm._e()])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[(_vm.listWarning.length > 0)?_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('h3',{staticClass:"mb-0",style:({ color: _vm.$vuetify.theme.themes.light.secondary })},[_vm._v(" "+_vm._s(_vm.textApp.label00171)+" ")])]),_vm._l((_vm.listWarning),function(warn,i){return _c('v-card-text',{key:i},[_c('b',{staticClass:"mb-0"},[_vm._v(_vm._s(warn.sender_name))]),_c('p',{staticStyle:{"white-space":"pre-line"},domProps:{"textContent":_vm._s(warn.txt)}})])})],2):_vm._e()],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" "+_vm._s(_vm.textApp.label00042)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }