<template>
  <v-container class="pa-0">
    <v-card outlined>
      <v-card-text v-if="!loading">
        <v-row align="center" justify="space-between">
          <h4
            class="ml-5 mt-5"
            :style="{ color: $vuetify.theme.themes.light.secondary }"
          >
            {{ title }}
          </h4>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                v-if="dataChart.labels.length > 0"
                class="mr-5 mt-1"
                color="secondary"
                icon
                @click="dialog = true"
              >
                <v-icon>mdi-arrow-expand</v-icon>
              </v-btn>
            </template>
            <span>{{textApp.label00075}}</span>
          </v-tooltip>
        </v-row>

        <v-row>
          <v-divider class="mt-3 ml-2 mr-2"></v-divider>
        </v-row>

        <v-card
          v-if="this.dataChart.labels.length == 0"
          class="elevation-0 ma-3"
        >
          <v-card-text>
            <v-row style="height: 100px" align="center" justify="center">
              <p class="ma-5">
                <v-icon class="mr-5">mdi-alert</v-icon>{{textApp.label00076}}
              </p>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card
          v-if="this.dataChart.labels.length == 1"
          class="elevation-0 ma-3"
        >
          <v-card-text>
            <v-row style="height: 100px" align="center" justify="center">
              <p class="ma-5">
                <v-icon class="mr-5">mdi-alert</v-icon>{{textApp.label00078}}
              </p>
            </v-row>
          </v-card-text>
        </v-card>
        <v-col
          v-if="this.dataChart.labels.length > 1"
          :style="{ 'overflow-y': 'auto', height: heightStyle }"
        >
          <line-chart
            class="mt-3"
            :chart-data="dataChart"
            :width="widthChart"
            :height="heightChart"
            :options="options"
          ></line-chart>
        </v-col>
      </v-card-text>
      <v-card-text v-else style="padding-top: 50px; padding-bottom: 50px">
        <v-row align="center" justify="center">
          <v-progress-circular
            :size="120"
            :width="10"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-row>
      </v-card-text>
      <v-dialog v-model="dialog" width="900">
        <v-card>
          <v-card-title>
            <h4 :style="{ color: $vuetify.theme.themes.light.secondary }">
              {{ title }}
            </h4>
          </v-card-title>

          <v-card-text>
            <v-row align="center" justify="space-between">
              <v-col cols="12" md="12" lg="12">
                <line-chart
                  :chart-data="dataChart"
                  :width="2"
                  :height="1"
                ></line-chart>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false"> {{textApp.label00042}} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import LineChart from "../components/LineChart.js";
import colors from "../config/color-charts";
export default {
  components: {
    LineChart
  },
  data() {
    return {
      dialog: false,
      loading: false,
      dataChart: {
        labels: [],
        datasets: [
          {
            label: "",
            backgroundColor: [],
            data: []
          }
        ]
      },
      options: {
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }
    };
  },
  props: {
    title: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    linkAPI: {
      type: String,
      required: true
    },
    widthChart: {
      type: Number,
      default: 5
    },
    heightChart: {
      type: Number,
      default: 1
    },
    heightStyle: {
      type: String,
      default: "350px"
    },
    filters: {
      type: Object,
      default: null
    },
    colorsSet: {
      type: String,
      default: "default"
    },
    agent: {
      type: String,
      required: false
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    setDataChart(l, d) {
      this.dataChart = {
        labels: l,
        datasets: [
          {
            label: this.label,
            backgroundColor: this.colorsChart,
            data: d
          }
        ]
      };
    },
    loadData() {
      this.loading = true;
      this.setDataChart([], []);
      this.axios({
        method: "GET",
        url: this.linkAPI,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        params: {
          filterStartDate: this.filterStartDate.substring(
            0,
            this.filterStartDate.length - 5
          ),
          filterEndDate: this.filterEndDate.substring(
            0,
            this.filterStartDate.length - 5
          ),
          filterAgentSelected: this.filterAgents,
          company: this.company.id,
          agent: this.agent
        }
      }).then(resp => {
        console.log(resp.data);
        if (!resp.data.Error) {
          this.setDataChart(resp.data.labels, resp.data.data);
        }
        this.loading = false;
      });
    },
    getRandomColor(array) {
      let currentIndex = array.length,
        temporaryValue,
        randomIndex;
      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }
      return array[0];
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      }
    },
    filterStartDate: {
      get: function() {
        if (this.filters == null) {
          return this.$store.getters.getStartDate;
        } else {
          return this.filters.startDate;
        }
      },
      set: function(value) {
        if (this.filters == null) {
          this.$store.dispatch("setStartDate", value);
        }
      }
    },
    filterEndDate: {
      get: function() {
        if (this.filters == null) {
          return this.$store.getters.getEndDate;
        } else {
          return this.filters.endDate;
        }
      },
      set: function(value) {
        this.$store.dispatch("setEndDate", value);
      }
    },
    filterAgents: {
      get: function() {
        return this.$store.getters.getAgents;
      },
      set: function(value) {
        this.$store.dispatch("setAgents", value);
      }
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
    token() {
      return this.$store.getters.getToken;
    },
    colorsChart() {
      if (colors[this.colorsSet].length > 0) {
        return colors[this.colorsSet];
      }
      return colors.default;
    }
  },
  watch: {
    filterStartDate() {
      this.loadData();
    },
    filterEndDate() {
      this.loadData();
    },
    filterAgents() {
      this.loadData();
    },
    token() {
      this.loadData();
    }
  }
};
</script>
<style scoped></style>
