<template>
    <v-dialog v-model="dialog" width="700">
      <template #activator="{ on: dialog }">
        <v-tooltip bottom>
          <template #activator="{ on: tooltip }">
            <v-btn
              class="mb-5 ml-3"
              color="primary"
              style="height: 40px;"
              v-on="{ ...tooltip, ...dialog }"
            >
              {{ textApp.label00502 }}
              <v-icon right dark>
                mdi-format-list-bulleted
              </v-icon>
            </v-btn>
          </template>
          <span>{{ textApp.label00503 }}</span>
        </v-tooltip>
      </template>

      <v-card>
        <v-card-title>
          <h4 :style="{ color: $vuetify.theme.themes.light.secondary }">{{ textApp.label00502 }}</h4>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-row>
            <v-tabs
              v-model="tab"
              align-with-title
              class="mb-4 mt-4"
            >
              <v-tabs-slider></v-tabs-slider>

              <v-tab key="win">
                <v-icon class="mr-2">mdi-microsoft-windows</v-icon> {{ textApp.label00504 }}
              </v-tab>
              <v-tab key="deb">
                <v-icon class="mr-2">mdi-linux</v-icon> {{ textApp.label00505 }}
              </v-tab>
            </v-tabs>
          </v-row>
          <v-tabs-items
            class="mt-2"
            v-model="tab"
          >
            <v-card outlined>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ textApp.label00506 }}
                            </th>
                            <th class="text-left">
                              {{ textApp.label00507 }}
                            </th>
                            <th class="text-left">
                              {{ textApp.label00508 }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in listInstallerWin"
                            :key="item.name"
                          >
                            <td>{{ item.tag }}</td>
                            <td>{{ timezone.getLocalDateStr(item.tstp).replace("T", ' ') }}</td>
                            <td>
                              <v-tooltip right>
                                <template #activator="{ on: tooltip }">
                                  <v-btn
                                    color="secondary"
                                    small
                                    dark
                                    fab
                                    outlined
                                    v-on="{ ...tooltip }"
                                    @click.stop="downloadMsi(item)"
                                  >
                                    <v-icon>mdi-download</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ textApp.label00509 }}</span>
                              </v-tooltip>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ textApp.label00506 }}
                            </th>
                            <th class="text-left">
                              {{ textApp.label00507 }}
                            </th>
                            <th class="text-left">
                              {{ textApp.label00508 }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in listInstallerDeb"
                            :key="item.name"
                          >
                            <td>{{ item.tag }}</td>
                            <td>{{ timezone.getLocalDateStr(item.tstp).replace("T", ' ') }}</td>
                            <td>
                              <v-tooltip right>
                                <template #activator="{ on: tooltip }">
                                  <v-btn
                                    color="secondary"
                                    small
                                    dark
                                    fab
                                    outlined
                                    v-on="{ ...tooltip }"
                                    @click.stop="downloadDeb(item)"
                                  >
                                    <v-icon>mdi-download</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ textApp.label00510 }}</span>
                              </v-tooltip>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-card>
          </v-tabs-items>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()"> {{textApp.label00164}} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
//const FileDownload = require("js-file-download");
import timezone from "../utils/timezone.js"
export default {
  data() {
    return {
      dialog: false,
      tab: null,
      listInstallerWin: [],
      listInstallerDeb: [],
      timezone: null
    };
  },
  props: ["adminLevel", "target_company"],
  mounted() {
    this.timezone = timezone;

    let params = {};
    switch(this.adminLevel) {
      case "L2":
        params = {
          company: this.company.id,
          target_company: this.target_company
        };
        break;
      case "L3":
        params = {
          company: this.company.id
        };
        break;
    }

    this.axios({
      method: "GET",
      url: `/admin/${this.adminLevel}/company/installer`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`
      },
      params: params
    }).then(resp => {
      console.log(resp.data);
      this.listInstallerWin = resp.data.filter(item => item.key_type == "win");
      this.listInstallerDeb = resp.data.filter(item => item.key_type == "deb");
    });
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    credentials: {
      get: function() {
        return this.$store.getters.getCredentials;
      },
      set: function(value) {
        this.$store.dispatch("setCredentials", value);
      }
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    downloadMsi(item) {
      let params = {};
      switch(this.adminLevel) {
        case "L2":
          params = {
            company: this.company.id,
            target_company: this.target_company,
            id_installer: item.id
          };
          break;
        case "L3":
          params = {
            company: this.company.id,
            id_installer: item.id
          };
          break;
      }

      this.axios({
        method: 'GET',
        url: `/admin/${this.adminLevel}/company/downloadMsi`,
        headers: {
          Authorization: `Bearer ${this.token}`
        },
        responseType: 'blob',
        params: params
      })
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `mastio-${item.tag}.msi`);
        document.body.appendChild(link);
        link.click();
        //FileDownload(resp.data, "mastio.msi");
      })
      .catch((error) => {
        console.error('Error downloading the file:', error);
      });
    },
    downloadDeb(item) {
      let params = {};
      switch(this.adminLevel) {
        case "L2":
          params = {
            company: this.company.id,
            target_company: this.target_company,
            id_installer: item.id
          };
          break;
        case "L3":
          params = {
            company: this.company.id,
            id_installer: item.id
          };
          break;
      }

      this.axios({
        method: 'GET',
        url: `/admin/${this.adminLevel}/company/downloadDeb`,
        headers: {
          Authorization: `Bearer ${this.token}`
        },
        responseType: 'blob',
        params: params
      })
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `mastio.zip`);
        document.body.appendChild(link);
        link.click();
        //FileDownload(resp.data, "mastio.msi");
      })
      .catch((error) => {
        console.error('Error downloading the file:', error);
      });
    }
  }
};
</script>
<style scoped></style>
