<template>
  <v-container>
    <transition name="bounce">
      <v-btn
        v-scroll="onScroll"
        v-show="btnScrollTop"
        fab
        small
        dark
        fixed
        bottom
        right
        color="primary"
        @click="toTop"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </transition>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      btnScrollTop: false,
    };
  },
  mounted() {},
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.btnScrollTop = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
  computed: {}
};
</script>
<style scoped>
.bounce-enter-active {
  animation: bounce-in 0.4s;
}
.bounce-leave-active {
  animation: bounce-in 0.4s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}</style>
