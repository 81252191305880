<template>
  <v-container class="pa-0 ma-0">
    <v-row align="center" justify="center" class="mt-5">
      <v-simple-table v-if="!loadingVulnList">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">{{textApp.label00212}}</th>
              <th class="text-left">{{textApp.label00121}}</th>
              <th class="text-left">{{textApp.label00213}}</th>
              <th v-if="agent == null" class="text-left">{{textApp.label00256}}</th>
              <th class="text-left">{{textApp.label00214}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in listVulnerabilities" :key="i">
              <td>{{ item.name }}</td>
              <td>
                {{ item.version }}
              </td>
              <td>
                <div v-for="(cond, j) in item.conditions" :key="j">
                  {{ cond }}
                </div>
              </td>
              <td v-if="agent == null">
                <VulnerabilityAgentList :agents="item.agents"></VulnerabilityAgentList>
              </td>
              <td class="pt-1 pb-1">
                <div v-for="(sev, k) in item.severities" :key="k">
                  <VulnerabilitySeverity
                    :severity="sev.severity"
                    :cves="sev.cves"
                  ></VulnerabilitySeverity>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-row v-if="loadingVulnList" align="center" justify="center">
        <v-progress-circular
          :size="120"
          :width="10"
          color="primary"
          indeterminate
          class="ma-5"
        ></v-progress-circular>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import VulnerabilityAgentList from "../components/VulnerabilityAgentList";
import VulnerabilitySeverity from "../components/VulnerabilitySeverity";
export default {
  components: {
    VulnerabilitySeverity,
    VulnerabilityAgentList
  },
  data() {
    return {
      listVulnerabilities: [],
      loadingVulnList: false
    };
  },
  props: ["id_agent", "reload"],
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loadingVulnList = true;
      this.axios({
        method: "GET",
        url: "/charts/vulnerability/programs/list",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        params: {
          filterStartDate: this.filterStartDate.substring(
            0,
            this.filterStartDate.length - 5
          ),
          filterEndDate: this.filterEndDate.substring(
            0,
            this.filterStartDate.length - 5
          ),
          filterAgentSelected: this.filterAgents,
          company: this.company.id,
          agent: this.agent
        }
      }).then(resp => {
        console.log(resp.data);
        for (let i = 0; i < resp.data.length; i++) {
          let list = resp.data[i];
          for (let q = 0; q < list.severities.length; q++) {
            let sev = list.severities[q];
            switch (sev.severity) {
              case "Critical":
                sev.order = 1;
                break;
              case "High":
                sev.order = 2;
                break;
              case "Medium":
                sev.order = 3;
                break;
              case "Low":
                sev.order = 4;
                break;
            }
            list.severities.sort((a, b) =>
              a.order > b.order ? 1 : b.order > a.order ? -1 : 0
            );
          }
        }
        this.loadingVulnList = false;
        this.listVulnerabilities = resp.data;
        console.log(resp.data);
      });
    }
  },
  watch: {
    reload () {
      this.loadData();
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
    token() {
      return this.$store.getters.getToken;
    },
    filterStartDate: {
      get: function() {
        return this.$store.getters.getStartDate;
      },
      set: function(value) {
        this.$store.dispatch("setStartDate", value);
      }
    },
    filterEndDate: {
      get: function() {
        return this.$store.getters.getEndDate;
      },
      set: function(value) {
        this.$store.dispatch("setEndDate", value);
      }
    },
    filterAgents: {
      get: function() {
        return this.$store.getters.getAgents;
      },
      set: function(value) {
        this.$store.dispatch("setAgents", value);
      }
    },
    agent() {
      if(this.$route.params.idAgent != undefined && this.$route.params.idAgent != null) {
        return this.$route.params.idAgent;
      } else if (this.id_agent != null && this.id_agent != undefined) {
        return this.id_agent;
      }
      return null;
    },
  }
};
</script>
<style scoped></style>
