<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="3">
        <span v-if="stats.length > 0">
          <bar-percentage-list-visualize
            :title="textApp.label00186"
            :type="constants.REQUIREMENTS.type_code"
            :list="stats[4]"
            :loading="loading"
          ></bar-percentage-list-visualize>
          <v-divider></v-divider>
          <bar-percentage-list-visualize
            :title="textApp.label00498"
            :type="constants.NAMEAGENTS.type_code"
            :list="stats[5]"
            :loading="loading"
          ></bar-percentage-list-visualize>
          <v-divider></v-divider>
          <bar-percentage-list-visualize
            :title="textApp.label00182"
            :type="constants.IDAGENTS.type_code"
            :list="stats[0]"
            :loading="loading"
          ></bar-percentage-list-visualize>
          <v-divider></v-divider>
          <bar-percentage-list-visualize
            :title="textApp.label00183"
            :type="constants.APADDRESS.type_code"
            :list="stats[1]"
            :loading="loading"
          ></bar-percentage-list-visualize>
          <v-divider></v-divider>
          <bar-percentage-list-visualize
            :title="textApp.label00184"
            :type="constants.SEVERITY.type_code"
            :list="stats[2]"
            :loading="loading"
          ></bar-percentage-list-visualize>
          <v-divider></v-divider>
          <bar-percentage-list-visualize
            :title="textApp.label00176"
            :type="constants.DESCRIPTION.type_code"
            :list="stats[3]"
            :loading="loading"
          ></bar-percentage-list-visualize>
        </span>
      </v-col>
      <v-col cols="12" md="9">
        <v-row justify="end">
          <span class="mr-3"
            >{{ sizeResult }} {{ textApp.label00187 }}
            {{ numberWithCommas(totalEvents) }} {{ textApp.label00188 }}</span
          >
        </v-row>
        <v-data-table
          :headers="headers"
          :items="listLogSelectedRequirement"
          :single-expand="true"
          :expanded.sync="expanded"
          item-key="id_event"
          show-expand
          class="elevation-0"
          disable-pagination
          hide-default-footer
          dense
        >
          <template v-slot:item.timestamp="{ item }">
            {{ convertDateToLocalDate(item.timestamp) }}
          </template>
          <template v-slot:expanded-item="{ headers }">
            <td :colspan="headers.length">
              <v-simple-table class="mt-5 mb-5 ml-5 mr-5">
                <template v-slot:default>
                  <tr>
                    <td style="min-width: 120px">
                      <b>{{ textApp.label00151 }}</b>
                    </td>
                    <td>{{ convertDateToLocalDate(expanded[0].timestamp) }}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>{{ textApp.label00185 }}</b>
                    </td>
                    <td>{{ String(expanded[0].gdpr) }}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>{{ textApp.label00182 }}</b>
                    </td>
                    <td>{{ expanded[0].idAgent }}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>{{ textApp.label00183 }}</b>
                    </td>
                    <td>{{ expanded[0].ip }}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>{{ textApp.label00176 }}</b>
                    </td>
                    <td>{{ expanded[0].desc }}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>{{ textApp.label00184 }}</b>
                    </td>
                    <td>{{ expanded[0].level }}</td>
                  </tr>
                  <tr v-if="expanded[0].mitre_tech != null">
                    <td>
                      <b>{{ textApp.label00189 }}</b>
                    </td>
                    <td>{{ String(expanded[0].mitre_tech.technique) }}</td>
                  </tr>
                  <tr v-if="expanded[0].active_directory != undefined">
                    <td>
                      <b>{{ textApp.label00190 }}</b>
                    </td>
                    <td>
                      <span style="white-space: pre-line">{{
                        String(expanded[0].active_directory)
                      }}</span>
                    </td>
                  </tr>
                  <tr v-if="expanded[0].win_binary != ''">
                    <td style="vertical-align: top">
                      <b>Win binary</b>
                    </td>
                    <td>
                      <span style="white-space: pre-line">{{
                        String(expanded[0].win_binary)
                      }}</span>
                    </td>
                  </tr>
                  <tr v-if="expanded[0].win_data != ''">
                    <td style="vertical-align: top">
                      <b>Win data</b>
                    </td>
                    <td>{{ expanded[0].win_data }}</td>
                  </tr>
                  <tr v-if="expanded[0].win_eventID != ''">
                    <td style="vertical-align: top">
                      <b>Win event ID</b>
                    </td>
                    <td>{{ expanded[0].win_eventID }}</td>
                  </tr>
                  <tr v-if="expanded[0].win_keywords != ''">
                    <td style="vertical-align: top">
                      <b>Win keywords</b>
                    </td>
                    <td>{{ expanded[0].win_keywords }}</td>
                  </tr>
                  <tr v-if="expanded[0].win_level != ''">
                    <td style="vertical-align: top">
                      <b>Win level</b>
                    </td>
                    <td>{{ expanded[0].win_level }}</td>
                  </tr>
                  <tr v-if="expanded[0].win_message != ''">
                    <td style="vertical-align: top">
                      <b>Win message</b>
                    </td>
                    <td>{{ expanded[0].win_message }}</td>
                  </tr>
                  <tr v-if="expanded[0].win_providerName != ''">
                    <td style="vertical-align: top">
                      <b>Win provider</b>
                    </td>
                    <td>{{ expanded[0].win_providerName }}</td>
                  </tr>
                  <tr>
                    <td style="vertical-align: top">
                      <b>{{ textApp.label00191 }}</b>
                    </td>
                    <td>{{ expanded[0].full_log }}</td>
                  </tr>
                </template>
              </v-simple-table>
            </td>
          </template>
          <template v-slot:no-data>
            <v-card-text>
              {{ textApp.label00089 }}
            </v-card-text>
          </template>
        </v-data-table>
        <v-card
          v-if="
            !loading &&
            listLogSelectedRequirement.length > 0 &&
            sizeResult < totalEvents
          "
          class="mt-2"
          @click="getListFilteredGDPR(null, false)"
        >
          <v-card-text style="text-align: center">
            {{ textApp.label00192 }}
          </v-card-text>
        </v-card>
        <v-row v-if="loading" align="center" justify="center">
          <v-progress-circular
            :size="120"
            :width="10"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-row>
      </v-col>
    </v-row>
    <ScrollToTop></ScrollToTop>
  </v-container>
</template>

<script>
import BarPercentageListVisualize from "./BarPercentageListVisualize";
import ScrollToTop from "../components/ScrollToTop";
import constants from "../config/constants";
export default {
  components: {
    BarPercentageListVisualize,
    ScrollToTop,
  },
  data() {
    return {
      totalEvents: 0,
      listLogSelectedRequirement: [],
      expanded: [],
      singleExpand: false,
      headers: [],
      sizeResult: 0,
      stats: [],
      loading: false,
      constants: constants
    };
  },
  mounted() {
    this.getListFilteredGDPR(null, true);
    this.headers = [
      { text: "", value: "data-table-expand" },
      {
        text: this.textApp.label00151,
        align: "left",
        sortable: true,
        value: "timestamp",
      },
      { text: this.textApp.label00182, value: "idAgent" },
      { text: this.textApp.label00183, value: "ip" },
      { text: this.textApp.label00176, value: "desc_short" },
    ];
  },
  //props: ["filters"],
  methods: {
    getListFilteredGDPR(r, refresh) {
      this.loading = true;
      if (refresh) {
        this.sizeResult = 0;
        this.listLogSelectedRequirement = [];
      }
      this.axios({
        method: "GET",
        url: "/events/specific/detailList", //"/gdprDetailListEvents",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        params: {
          company: this.company.id,
          filterStartDate: this.filterStartDate.substring(
            0,
            this.filterStartDate.length - 5
          ),
          filterEndDate: this.filterEndDate.substring(
            0,
            this.filterStartDate.length - 5
          ),
          filterAgentSelected: this.filterAgents,
          requirement: r,
          from: this.sizeResult,
          filters: this.filters,
          filterGroupSelected: this.filterGroup,
        },
      }).then((resp) => {
        console.log(resp.data);
        if (!resp.data.Error) {
          const LENGHT_DESC = 50;
          resp.data.forEach((item, index_item) => {
            if (item.desc.length > LENGHT_DESC) {
              item.desc_short = item.desc.substring(0, LENGHT_DESC) + "...";
            } else {
              item.desc_short = item.desc;
            }
            item.id_event =
              item.timestamp + item.id + item.idAgent + item.ip + index_item;
            let win_binary_temp = item.win_binary;
            if (win_binary_temp.length > 0) {
              item.win_binary = "";
              for (let i = 0; i * 100 < win_binary_temp.length; i++) {
                item.win_binary =
                  item.win_binary +
                  win_binary_temp.substring(i * 100, i * 100 + 100) +
                  " ";
              }
            }
          });
          this.listLogSelectedRequirement =
            this.listLogSelectedRequirement.concat(resp.data);
          this.sizeResult = this.listLogSelectedRequirement.length;
        }
        this.loading = false;
      });
      this.axios({
        method: "GET",
        url: "/events/specific/totalCount",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        params: {
          company: this.company.id,
          filterStartDate: this.filterStartDate.substring(
            0,
            this.filterStartDate.length - 5
          ),
          filterEndDate: this.filterEndDate.substring(
            0,
            this.filterStartDate.length - 5
          ),
          filters: this.filters,
          filterGroupSelected: this.filterGroup,
        },
      }).then((resp) => {
        console.log(resp.data);
        this.totalEvents = resp.data.count;
      });
      this.axios({
        method: "GET",
        url: "/charts/stats/selectors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        params: {
          company: this.company.id,
          filterStartDate: this.filterStartDate.substring(
            0,
            this.filterStartDate.length - 5
          ),
          filterEndDate: this.filterEndDate.substring(
            0,
            this.filterStartDate.length - 5
          ),
          filterAgentSelected: this.filterAgents,
          filters: this.filters,
        },
      }).then((resp) => {
        let idAgentTemp = resp.data.agent_id;
        let tot = 0;
        for (let i = 0; i < idAgentTemp.data.length; i++) {
          tot += idAgentTemp.data[i];
        }
        idAgentTemp["percentage"] = [];
        for (let i = 0; i < idAgentTemp.data.length; i++) {
          idAgentTemp.percentage[i] = (idAgentTemp.data[i] / tot) * 100;
        }

        let nameAgentTemp = resp.data.agent_name;
        tot = 0;
        for (let i = 0; i < nameAgentTemp.data.length; i++) {
          tot += nameAgentTemp.data[i];
        }
        nameAgentTemp["percentage"] = [];
        for (let i = 0; i < nameAgentTemp.data.length; i++) {
          nameAgentTemp.percentage[i] = (nameAgentTemp.data[i] / tot) * 100;
        }

        let ipAgentTemp = resp.data.agent_ip;
        tot = 0;
        for (let i = 0; i < ipAgentTemp.data.length; i++) {
          tot += ipAgentTemp.data[i];
        }
        ipAgentTemp["percentage"] = [];
        for (let i = 0; i < ipAgentTemp.data.length; i++) {
          ipAgentTemp.percentage[i] = (ipAgentTemp.data[i] / tot) * 100;
        }

        let severityLevelTemp = resp.data.rule_level;
        tot = 0;
        for (let i = 0; i < severityLevelTemp.data.length; i++) {
          tot += severityLevelTemp.data[i];
        }
        severityLevelTemp["percentage"] = [];
        for (let i = 0; i < severityLevelTemp.data.length; i++) {
          severityLevelTemp.percentage[i] =
            (severityLevelTemp.data[i] / tot) * 100;
        }

        let descriptionTemp = resp.data.description;
        tot = 0;
        for (let i = 0; i < descriptionTemp.data.length; i++) {
          tot += descriptionTemp.data[i];
        }
        descriptionTemp["percentage"] = [];
        for (let i = 0; i < descriptionTemp.data.length; i++) {
          descriptionTemp.percentage[i] = (descriptionTemp.data[i] / tot) * 100;
        }

        let ruleGDPRTemp = resp.data.rule_gdpr;
        tot = 0;
        for (let i = 0; i < ruleGDPRTemp.data.length; i++) {
          tot += ruleGDPRTemp.data[i];
        }
        ruleGDPRTemp["percentage"] = [];
        for (let i = 0; i < ruleGDPRTemp.data.length; i++) {
          ruleGDPRTemp.percentage[i] = (ruleGDPRTemp.data[i] / tot) * 100;
        }

        for (let j = 0; j < ruleGDPRTemp.key.length; j++) {
          ruleGDPRTemp.labels[j] = this.dataLabel[ruleGDPRTemp.key[j]].name;
        }

        this.stats = [
          idAgentTemp,
          ipAgentTemp,
          severityLevelTemp,
          descriptionTemp,
          ruleGDPRTemp,
          nameAgentTemp
        ];
      });
    },
    convertDateToLocalDate(string) {
      return new Date(
        new Date(string).toISOString().replace("T", " ")
      ).toLocaleString();
    },
    numberWithCommas(x) {
      if (x < 1000) {
        return x;
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    dataLabel: {
      get: function () {
        return this.$store.getters.getDataLabel;
      },
    },
    filterStartDate: {
      get: function () {
        return this.$store.getters.getStartDate;
      },
      set: function (value) {
        this.$store.dispatch("setStartDate", value);
      },
    },
    filterEndDate: {
      get: function () {
        return this.$store.getters.getEndDate;
      },
      set: function (value) {
        this.$store.dispatch("setEndDate", value);
      },
    },
    filterAgents: {
      get: function () {
        return this.$store.getters.getAgents;
      },
      set: function (value) {
        this.$store.dispatch("setAgents", value);
      },
    },
    filterGroup: {
      get: function () {
        return this.$store.getters.getGroup;
      },
      set: function (value) {
        this.$store.dispatch("setGroup", value);
      },
    },
    reloadButton: {
      get: function() {
        return this.$store.getters.getReloadButton;
      },
      set: function(value) {
        this.$store.dispatch("setReloadButton", value);
      }
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function () {
        return this.$store.getters.getCompany;
      },
      set: function (value) {
        this.$store.dispatch("setCompany", value);
      },
    },
    filtersApplication: {
      get: function () {
        return this.$store.getters.getFiltersApplication;
      }
    },
    filters() {
      return {
        requirementsSelected: this.filtersApplication.filter(e => e.type_code == "requirements").map(x => x.value),
        IDAgentsSelected: this.filtersApplication.filter(e => e.type_code == "IDAgents").map(x => x.value),
        nameAgentsSelected: this.filtersApplication.filter(e => e.type_code == "nameAgents").map(x => x.value),
        IPAddressSelected: this.filtersApplication.filter(e => e.type_code == "IPAddress").map(x => x.value),
        severitySelected: this.filtersApplication.filter(e => e.type_code == "severity").map(x => x.value),
        descriptionSelected: this.filtersApplication.filter(e => e.type_code == "descriptions").map(x => x.value),
      }
    }
  },
  watch: {
    filterStartDate() {
      this.getListFilteredGDPR(null, true);
    },
    filterEndDate() {
      this.getListFilteredGDPR(null, true);
    },
    filterAgents() {
      this.getListFilteredGDPR(null, true);
    },
    filterGroup() {
      this.getListFilteredGDPR(null, true);
    },
    token() {
      this.getListFilteredGDPR(null, true);
    },
    reloadButton() {
      this.getListFilteredGDPR(null, true);
    }
  },
};
</script>
<style scoped></style>
