<template>
  <v-container class="pr-5 pl-5">
    <v-row align="start" justify="start">
      <v-col cols="12" md="12">
        <v-card outlined>
          <v-card-text>
            <h4>{{ textApp.label00475 }}</h4>
          </v-card-text>
        </v-card>
        <v-stepper
          class="mt-2"
          v-model="stepper"
          vertical
          outlined
        >
          <v-stepper-step
            :complete="stepper > 1"
            step="1"
          >
            {{ textApp.label00457 }}
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-card
              class="mb-12 pa-3 elevation-0"
              height="150px"
            >
            <v-card-text v-if="loading_agent" style="padding-top: 50px; padding-bottom: 50px">
              <v-row align="center" justify="center">
                <v-progress-circular
                  :size="120"
                  :width="10"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-row>
            </v-card-text>
              <v-autocomplete
                v-else
                v-model="agents_selected"
                :label="textApp.label00458"
                :items="agent_list"
                item-text="str_id"
                item-value="id"
                outlined
                clearable
              ></v-autocomplete>
            </v-card>
            <v-btn
              :loading="loading_btn"
              :disabled="agents_selected.length == 0"
              color="primary"
              @click="countResults"
            >
              {{ textApp.label00304 }}
            </v-btn>
          </v-stepper-content>

          <v-stepper-step 
            step="2"
            :complete="stepper > 2"
          >
            {{ textApp.label00464 }}
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-card
              class="mb-12 elevation-0"
              height="150px"
            >
              <v-card-text v-if="stepper == 2">
                <h3 class="mb-2">{{ textApp.label00465 }}</h3>

                <table v-if="summary_data != null">
                  <tr>
                    <td>
                      {{ textApp.label00068 }}
                    </td>
                    <td>
                      <b>{{ agents_selected.toString() }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ textApp.label00476 }}
                    </td>
                    <td>
                      <b>{{ summary_data.packages.length }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ textApp.label00477 }}
                    </td>
                    <td>
                      <b>{{ summary_data.ports.length }}</b>
                    </td>
                  </tr>
                </table>
              </v-card-text>
              
              <v-row v-if="loading" align="center" justify="center">
                <v-progress-circular
                  :size="120"
                  :width="10"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-row>
            </v-card>
            <v-btn
              :disabled="!ready_generation"
              class="mt-5"
              color="primary"
              :loading="loading_btn"
              @click="generateReport"
            >
              {{ textApp.label00464 }}
            </v-btn>
            <v-btn 
              class="mt-5"
              text
              @click="stepper = 1"
            >
              {{ textApp.label00314 }}
            </v-btn>
          </v-stepper-content>
          <v-btn
            v-if="stepper > 2"
            class="mt-10 ml-5"
            color="primary"
            @click="stepper = 1"
          >
            {{ textApp.label00471 }}
          </v-btn>
        </v-stepper>
        <v-card outlined class="mt-3" v-if="report_data != null" >
          <v-card-text>
            <v-btn
              class="mr-1"
              color="secondary"
              dark
              @click="dialog = true;"
            >
              {{ textApp.label00472 }}
            </v-btn>
            <v-btn 
              class="mr-1"
              color="secondary"
              dark  
              @click="exportDataOS"
            >
              {{ textApp.label00478 }}
            </v-btn>
            <v-btn 
              class="mr-1"
              color="secondary"
              dark  
              @click="exportDataHW"
            >
              {{ textApp.label00479 }}
            </v-btn>
            <v-btn 
              class="mr-1"
              color="secondary"
              dark  
              @click="exportDataPorts"
            >
              {{ textApp.label00480 }}
            </v-btn>
            <v-btn 
              class="mr-1"
              color="secondary"
              dark  
              @click="exportDataPrograms"
            >
              {{ textApp.label00481 }}
            </v-btn>
          </v-card-text>
        </v-card>
        <v-card outlined class="mt-3" v-if="report_data != null" >
          <v-card-text>
            <v-row class="mt-3 mb-5">
              <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
                <AgentOS
                  :data="report_data.os"
                  :id_agent="agents_selected"
                ></AgentOS>
                <AgentHardware
                  :data="report_data.hardware"
                >
                </AgentHardware>
                <AgentPorts
                  :data="report_data.ports"
                ></AgentPorts>
                <AgentInventoryData
                  :data="report_data.packages"
                ></AgentInventoryData>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <!-- Anteprima -->
        <v-dialog
          v-model="dialog"
          scrollable
          width="800"
        >
          <v-card>
            <v-card-title>
              <h4 :style="{ color: $vuetify.theme.themes.light.secondary }">{{ textApp.label00474 }}</h4>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text>
              <v-row class="mt-3 mb-5" v-if="report_data != null" id="content-pdf">
                <v-col cols="12" md="12" class="mt-2 mb-3">
                  <h2 :style="{ color: $vuetify.theme.themes.light.secondary }">{{ textApp.label00475 }}</h2>
                </v-col>
                <v-col cols="12" md="12" class="pb-0 pt-0">
                  <AgentOS
                    :data="report_data.os"
                    :id_agent="agents_selected"
                  ></AgentOS>
                  <AgentHardware
                    :data="report_data.hardware"
                  >
                  </AgentHardware>
                  <AgentPorts
                    :data="report_data.ports"
                  ></AgentPorts>
                  <AgentInventoryData
                    :data="report_data.packages"
                  ></AgentInventoryData>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="exportToPDF"
              >
                {{ textApp.label00482 }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import timezone from "../utils/timezone.js"
import html2pdf from "html2pdf.js";
import exportFromJSON from "export-from-json";
import AgentOS from "../components/AgentOS"
import AgentInventoryData from "../components/AgentInventoryData";
import AgentHardware from "../components/AgentHardware";
import AgentPorts from "../components/AgentPorts";
export default {
  components: {
    AgentOS,
    AgentInventoryData,
    AgentHardware,
    AgentPorts
  },
  data() {
    return {
      loading_agent: true,
      agent_list: [],
      agents_selected: [],
      stepper: 1,
      loading_btn: false,
      ready_generation: false,
      loading: false,
      summary_data: null,
      report_data: null,
      dialog: false
    };
  },
  mounted() {    
    this.axios({
      method: "GET",
      url: "/agents/list",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      params: {
        company: this.company.id,
      },
    }).then((resp) => {
      console.log(resp.data);
      if (!resp.data.Error) {
        resp.data.forEach((a, i) => {
          resp.data[i]['str_id'] = `${a.id} - ${a.name}`;
        });
        this.agent_list = resp.data;
        this.loading_agent = false;
      }
    });
  },
  methods: {
    countResults() {
      this.ready_generation = false;
      this.loading_btn = true;
      this.axios({
        method: "POST",
        url: "/reports/asset/data",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          company: this.company.id,
          agent: this.agents_selected,
          filterStartDate: new Date(this.startDatetime).toISOString().substr(0, 19),
          filterEndDate: new Date(this.endDatetime).toISOString().substr(0, 19),
          filters: this.filters
        },
      }).then((resp) => {
        console.log(resp.data);
        this.loading_btn = false;
        this.stepper = 2;
        this.ready_generation = true;
        this.summary_data = resp.data.data;
        console.log(this.summary_data);
      });
    },
    generateReport() {
      this.report_data = this.summary_data;
      this.stepper = 3;
      /*this.loading_btn = true;
      this.axios({
        method: "POST",
        url: "/reports/asset/data",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          company: this.company.id,
          agent: this.agents_selected,
          filterStartDate: new Date(this.startDatetime).toISOString().substr(0, 19),
          filterEndDate: new Date(this.endDatetime).toISOString().substr(0, 19),
          filters: this.filters
        },
      }).then((resp) => {
        console.log(resp.data);
        this.stepper = 4;
        this.report_data = resp.data.data.timeline_agents;
        //this.list_data = resp.data.data.detail_list;
        this.loading_btn = false;
        
        const LENGHT_DESC = 50;
        resp.data.data.detail_list.forEach((item, index_item) => {
            if (item.desc.length > LENGHT_DESC) {
              item.desc_short = item.desc.substring(0, LENGHT_DESC) + "...";
            } else {
              item.desc_short = item.desc;
            }
            item.id_event =
              item.timestamp + item.id + item.idAgent + item.ip + index_item;
          });
          this.list_data = resp.data.data.detail_list; 
      });*/
    },
    exportToPDF() {
      let name = this.companySetup.service_name;
      html2pdf()
      .from(document.getElementById("content-pdf"))
        .set({
            margin:       [30, 20, 30, 20],
            filename:     timezone.getLocalDateStr(new Date()).replaceAll(/T/g, "_").replaceAll(/-/g, "").replaceAll(/:/g, ""),
            image:        { type: 'jpeg',quality: 0.98 },
            html2canvas:  { scale: 2, logging: true, dpi: 192, letterRendering: true },
            jsPDF:        { unit: 'mm', format: 'a4', orientation: 'p' },
            pagebreak: { before: '.page-break', avoid: 'table' }
        })
        .toPdf()
        .get('pdf').then(function (pdf) {
          var totalPages = pdf.internal.getNumberOfPages();

          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(14);
            pdf.setTextColor(150);
            pdf.text(15, 15, name);
            pdf.text(pdf.internal.pageSize.getWidth() - 65, 15, timezone.getLocalDateStr(new Date()).replaceAll(/T/g, " "));
            pdf.setFontSize(10);
            pdf.text('SIDNEO ' + i + '/' + totalPages+'', pdf.internal.pageSize.getWidth() - 40, pdf.internal.pageSize.getHeight() - 8);
          } 
      }).save();
    },
    exportDataPrograms() {
      this.exportData("packages")
    },
    exportDataPorts() {
      this.exportData("ports")
    },
    exportDataHW() {
      this.exportData("hardware")
    },
    exportDataOS() {
      this.exportData("os")
    },
    exportData(type) {
      if (!this.report_data) return;
      try {
        let data = this.report_data[type];
        const fileName = timezone.getLocalDateStr(new Date()).replaceAll(/T/g, "_").replaceAll(/-/g, "").replaceAll(/:/g, "");
        const exportType = exportFromJSON.types["csv"];
        exportFromJSON({ data, fileName, exportType });
      } catch (e) {
        throw new Error("Parsing failed!");
      }
    },
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      }
    },
    dataLabel: {
      get: function () {
        return this.$store.getters.getDataLabel;
      },
    },
    companySetup: {
      get: function() {
        return this.$store.getters.getCompanySetup;
      }
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
    startDatetime() {
      return `${this.startDate}T${this.startTime}:00`
    },
    endDatetime() {
      return `${this.endDate}T${this.endTime}:00`
    },
    filters() {
      return {
        requirementsSelected: [],
        IDAgentsSelected: [this.agents_selected],
        IPAddressSelected: [],
        severitySelected: [],
        descriptionSelected: [],
      }
    },
    startDatetimeStr() {
      return  timezone.getLocalDateStr(this.startDatetime).replaceAll(/T/g, " ");
    },
    endDatetimeStr() {
      return  timezone.getLocalDateStr(this.endDatetime).replaceAll(/T/g, " ");
    }
  }
};
</script>
<style scoped>
td {
  padding: 0px 5px 0px 0px;
}
</style>
