<template>
  <v-container class="pr-5 pl-5">
    <v-row align="center" justify="start" class="ml-1">
      <span>
        <ReportCategGDPR></ReportCategGDPR>
      </span>
      <span>
        <Warning
          reportType="categ"
          :filters="{ startDate: filterStartDate, endDate: filterEndDate }"
        ></Warning>
      </span>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="ml-0"
            color="secondary"
            small
            to="/gdpr-categ-comparison"
          >
            {{textApp.label00100}}
          </v-btn>
        </template>
        <span>{{textApp.label00177}}</span>
      </v-tooltip>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <bar-template
          :title="textApp.label00103"
          linkAPI="/charts/categ/tags"
          colorsSet="colorsChartCategDPO"
          :type_code="constants.REQUIREMENTS.type_code"
          :type_label="textApp[constants.REQUIREMENTS.type_label]"
        ></bar-template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BarTemplate from "../components/BarTemplate";
import ReportCategGDPR from "../components/ReportCategGDPR";
import Warning from "../components/Warning";
import constants from "../config/constants";
export default {
  components: {
    BarTemplate,
    ReportCategGDPR,
    Warning
  },
  data() {
    return {
      constants: constants
    };
  },
  mounted() {},
  methods: {},
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    filterStartDate: {
      get: function() {
        return this.$store.getters.getStartDate;
      },
      set: function(value) {
        this.$store.dispatch("setStartDate", value);
      }
    },
    filterEndDate: {
      get: function() {
        return this.$store.getters.getEndDate;
      },
      set: function(value) {
        this.$store.dispatch("setEndDate", value);
      }
    },
  },
  watch: {}
};
</script>
<style scoped></style>
