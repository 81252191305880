var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0"},[_c('v-card',{staticClass:"pl-5 pr-5 pb-5",attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"mt-5",style:({ color: _vm.$vuetify.theme.themes.light.secondary })},[_c('h4',[_vm._v(_vm._s(_vm.textApp.label00083))])]),_c('v-row',{attrs:{"justify":"start"}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.textApp.label00084,"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',{attrs:{"justify":"start"}},[_c('v-data-table',{staticClass:"ma-0",staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.listAgents,"search":_vm.search,"footer-props":_vm.footerProps},on:{"click:row":_vm.viewAgentDetail},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 'active')?_c('span',{staticStyle:{"color":"green"}},[_vm._v(" "+_vm._s(_vm.textApp.label00085)+" ")]):_vm._e(),(item.status == 'disconnected')?_c('span',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.textApp.label00086)+" ")]):_vm._e(),(item.status == 'never_connected')?_c('span',{staticStyle:{"color":"brown"}},[_vm._v(" "+_vm._s(_vm.textApp.label00087)+" ")]):_vm._e(),(item.status == 'pending')?_c('span',{staticStyle:{"color":"darkgray"}},[_vm._v(" "+_vm._s(_vm.textApp.label00088)+" ")]):_vm._e()]}},{key:"item.vulnerability",fn:function(item){return [(_vm.loadingVulnStats)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"size":20,"width":2,"color":"primary","indeterminate":""}})],1):_c('VulnerabilityAgentStats',{attrs:{"agent":item.item.id,"vulnstats":item.item.id in _vm.vulnStats? _vm.vulnStats[item.item.id] : null}})]}},{key:"item.menu",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","small":"","dark":"","fab":"","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('OpenRDP',{attrs:{"agent":item}})],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('OpenRemoteFileSystem',{attrs:{"agent":item}})],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('OpenRemoteShell',{attrs:{"agent":item}})],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('DismissAgent',{attrs:{"agent":item},on:{"reloadListAgents":function($event){return _vm.loadData()}}})],1)],1)],1)],1)]}},{key:"no-data",fn:function(){return [_c('v-card-text',[_vm._v(" "+_vm._s(_vm.textApp.label00089)+" ")])]},proxy:true}])})],1),(_vm.loading)?_c('v-row',[_c('v-card-text',{staticStyle:{"padding-top":"50px","padding-bottom":"50px"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"size":120,"width":10,"color":"primary","indeterminate":""}})],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }