<template>
  <v-container fluid>
    <v-container fluid v-if="!enabled2fa">
      <v-overlay :value="loading" absolute>
        <v-card-text style="padding-top: 50px; padding-bottom: 50px">
          <v-progress-circular
            :size="120"
            :width="10"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-overlay>
      <v-row justify="center">
        <v-col cols="12" md="6" sm="12">
          <v-alert v-if="errorSQL" outlined type="error">
            {{ textApp.label00130 }}
          </v-alert>
          <v-alert v-if="errorCredentials" outlined type="error">
            {{ textApp.label00329 }}
          </v-alert>
          <v-alert v-if="errorPassword" outlined type="error">
            {{ textApp.label00330 }}
          </v-alert>
          <v-alert v-if="errorEmail" outlined type="error">
            {{ textApp.label00331 }}
          </v-alert>
          <form>
            <v-card>
              <v-card-title
                >{{ textApp.label00332 }} <b class="ml-2">{{companySetup.service_name}}</b></v-card-title
              >
              <v-card-text>
                <v-row justify="center" class="ml-4 mr-4">
                  <v-text-field
                    v-model="username"
                    label="Username"
                    single-line
                    @keydown.enter="confirmLogIn"
                  ></v-text-field>
                </v-row>
                <v-row justify="center" class="ml-4 mr-4">
                  <v-text-field
                    v-model="password"
                    :append-icon="showPsw ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="showPsw ? 'text' : 'password'"
                    name="input-10-1"
                    label="Password"
                    :hint="textApp.label00288"
                    @click:append="showPsw = !showPsw"
                    counter
                    @keydown.enter="confirmLogIn"
                  ></v-text-field>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class="elevation-0 mt-1">
              <v-card-text>
                <v-row justify="end">
                  <v-btn color="primary" class="mb-1" @click="confirmLogIn">
                    {{ textApp.label00333 }}
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </form>
        </v-col>
      </v-row>
    </v-container>
    <Login2FA v-else :secret="secret2fa" :tmpToken="tmpToken" @verified="verified2fa"></Login2FA>
  </v-container>
</template>

<script>
import { cookie_key } from "../config/config";
import { baseUrlSetup } from "../config/config";
import Login2FA from "../components/Login2FA";
export default {
  components: {
    Login2FA,
  },
  data() {
    return {
      errorCredentials: false,
      errorPassword: false,
      errorEmail: false,
      errorSQL: false,
      showPsw: false,
      username: null,
      password: null,
      rules: {},
      loading: false,
      enabled2fa: false,
      secret2fa: null,
      tmpToken: null
    };
  },
  mounted() {
    if (this.token == null) {
      this.$cookies.remove("permanent");
    }
    this.rules = {
      required: (value) => !!value || this.textApp.label00327,
      min: (v) => (v != null && v.length) >= 8 || this.textApp.label00288,
    };
  },
  methods: {
    confirmLogIn() {
      this.loading = true;
      this.errorSQL = false;
      this.errorCredentials = false;
      this.errorPassword = false;
      this.errorEmail = false;
      this.doLogin(false);
    },
    doLogin(otpVerified) {
      this.axios({
        method: "POST",
        url: "/user/login",
        auth: {
          username: this.username,
          password: this.password,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((resp) => {
        console.log(resp.data);
        if (!resp.data.Error) {
          if(resp.data.enabled2fa && !otpVerified) {
            this.secret2fa = resp.data.secret2fa;
            this.enabled2fa = resp.data.enabled2fa;
            this.tmpToken = resp.data.token;
          } else {
            this.setAuthenticated(resp);
          }
        } else {
          if (resp.data.code == 0) {
            this.errorSQL = true;
          } else if (resp.data.code == 1100) {
            this.errorCredentials = true;
          } else if (resp.data.code == 1101) {
            this.errorPassword = true;
          } else if (resp.data.code == 1102) {
            this.errorEmail = true;
          }
        }
      })
      .catch((err) => {
        console.log(err);
        this.errorSQL = true;
      })
      .finally(() => {
        this.loading = false;
      });
    },
    updateLang(newlang) {
      this.axios({
        method: "GET",
        url: `${baseUrlSetup}/text`,
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          lang: newlang
        }
      }).then((resp) => {
        console.log(resp.data);
        this.textApp = resp.data;
        this.$router.push("/");
      });
    },
    setAuthenticated(resp) {
      this.$store.dispatch("setAuthenticated");
      this.credentials = {
        id: resp.data.currUser,
        user: this.username,
        name: resp.data.name,
        surname: resp.data.surname,
        lang: resp.data.lang
      };
      this.token = resp.data.token;
      this.module = resp.data.module;
      this.L1Permission = resp.data.L1_permissions;

      let cookie_data = {
        authenticated: true,
        credentials: this.credentials,
        token: this.token,
        module: this.module,
        L1Permission: this.L1Permission,
      };
      console.log(cookie_data);
      let encryptedData = this.$CryptoJS.AES.encrypt(
        JSON.stringify(cookie_data),
        cookie_key
      ).toString();
      
      this.$cookies.set("permanent", encryptedData, 60 * 60 * 8);

      if(this.textApp.lang != resp.data.lang){
        this.updateLang(resp.data.lang);
      } else {
        this.$router.push("/");
      }

      localStorage.setItem("username", this.username);
    },
    verified2fa(verified) {
      if(verified) {
        this.doLogin(true);
      }
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
      set: function (value) {
        this.$store.dispatch("setTextApplication", value);
      },
    },
    companySetup: {
      get: function() {
        return this.$store.getters.getCompanySetup;
      }
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    credentials: {
      get: function () {
        return this.$store.getters.getCredentials;
      },
      set: function (value) {
        this.$store.dispatch("setCredentials", value);
      },
    },
    token: {
      get: function () {
        return this.$store.getters.getToken;
      },
      set: function (value) {
        this.$store.dispatch("setToken", value);
      },
    },
    module: {
      get: function () {
        return this.$store.getters.getModule;
      },
      set: function (value) {
        this.$store.dispatch("setModule", value);
      },
    },
    role: {
      get: function () {
        return this.$store.getters.getRole;
      },
      set: function (value) {
        this.$store.dispatch("setRole", value);
      },
    },
    L1Permission: {
      get: function () {
        return this.$store.getters.getL1Permission;
      },
      set: function (value) {
        this.$store.dispatch("setL1Permission", value);
      },
    },
  },
};
</script>
<style scoped></style>
