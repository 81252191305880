<template>
  <v-container>
    <v-card outlined v-if="secret == null">
      <v-card-text>
        <v-row class="ma-4">
          <v-col cols="12" md="12">
            <h3>{{ textApp.label00529 }}</h3>
          </v-col>
          <v-col cols="12" md="12">
            <img :src="qrcode" />
          </v-col>
          <v-col cols="12" md="12">
            <v-btn 
              color="primary"
              text
              @click="generateSecret()">
              {{ textApp.label00530 }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="ma-4">
          <v-col cols="12" md="12">
            <h3>{{ textApp.label00531 }}</h3>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field
              label="OTP"
              v-model="otp"
              outlined
              @keyup.enter="verifyOTP"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn 
              color="primary" 
              @click="verifyOTP()">
              {{ textApp.label00532 }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card outlined v-else>
      <v-card-text>
        <v-row class="ma-4">
          <v-col cols="12" md="12">
            <h3>{{ textApp.label00531 }}</h3>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field
              label="OTP"
              v-model="otp"
              outlined
              @keyup.enter="verifyOTP"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn 
              color="primary" 
              @click="verifyOTP()">
              {{ textApp.label00532 }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      otp: '',
      qrcode: null,
    };
  },
  props: ['secret', 'tmpToken'],
  mounted() {
    if(this.secret == null)
      this.generateSecret();
  },
  methods: {
    generateSecret() {
      this.axios({
        method: "POST",
        url: "/user/generate2FA",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.tmpToken}`
        },
      })
      .then((resp) => {
        console.log(resp.data);
        this.qrcode = resp.data.qrcode;
      });
    },
    verifyOTP() {
      this.axios({
        method: "POST",
        url: "/user/verify2FA",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.tmpToken}`
        },
        data: {
          otp: this.otp
        }
      })
      .then((resp) => {
        console.log(resp.data);
        this.$emit('verified', true);
      });
    },
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      }
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    }
  }
};
</script>
<style scoped></style>
