<template>
  <v-container>
    <v-dialog v-model="dialog" width="700">
      <template #activator="{ on: dialog }">
        <v-tooltip bottom>
          <template #activator="{ on: tooltip }">
            <v-btn v-on="{ ...tooltip, ...dialog }" icon color="secondary">
              <v-icon>mdi-format-list-bulleted-square</v-icon>
            </v-btn>
          </template>
          <span>{{ textApp.label00520 }}</span>
        </v-tooltip>
      </template>

      <v-card>
        <v-card-title>
          <h4 :style="{ color: $vuetify.theme.themes.light.secondary }">
            {{ textApp.label00521 }}
          </h4>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-row>
            <v-simple-table class="pa-3 mt-3">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td v-if="alertData.timestamp != null">{{ textApp.label00151 }}</td>
                    <td v-if="alertData.timestamp != null"> {{ alertData.timestamp }}</td>
                  </tr>
                  <tr>
                    <td v-if="alertData.agent != null">{{ textApp.label00068 }}</td>
                    <td v-if="alertData.agent != null"> {{ alertData.agent }}</td>
                  </tr>
                  <tr>
                    <td v-if="alertData.hostname != null">{{ textApp.label00498 }}</td>
                    <td v-if="alertData.hostname != null"> {{ alertData.hostname }}</td>
                  </tr>
                  <tr>
                    <td v-if="alertData.description != null">{{ textApp.label00176 }}</td>
                    <td v-if="alertData.description != null"> {{ alertData.description }}</td>
                  </tr>
                  <tr>
                    <td v-if="alertData.process_id != null">{{ textApp.label00281 }}</td>
                    <td v-if="alertData.process_id != null"> {{ alertData.process_id }}</td>
                  </tr>
                  <tr>
                    <td v-if="alertData.process != null">{{ textApp.label00282 }}</td>
                    <td v-if="alertData.process != null"> {{ alertData.process }}</td>
                  </tr>
                  <tr>
                    <td v-if="alertData.username != null">{{ textApp.label00283 }}</td>
                    <td v-if="alertData.username != null"> {{ alertData.username }}</td>
                  </tr>
                  <tr>
                    <td v-if="alertData.permissions != null">{{ textApp.label00284 }}</td>
                    <td v-if="alertData.permissions != null">
                      <div
                        v-for="(elem, i) in alertData.permissions"
                        :key="i"
                        class="mb-3 mt-3"
                      >
                        {{ elem.name }}:
                        <div v-for="(p, j) in elem.allowed" :key="j" class="ml-3">
                          - {{ p }}
                        </div>
                        <v-divider
                          v-if="i != alertData.permissions.length - 1"
                          class="mt-1"
                        ></v-divider>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()"> {{textApp.label00042}} </v-btn>
          <v-btn v-if="alert.shown == 0" color="primary" @click="takeCharge()"> {{ textApp.label00522 }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      dialog: false,
      headers: [],
    };
  },
  props: ["alert"],
  mounted() {
    this.headers = [
      { text: this.textApp.label00151, value: "timestamp" },
      { text: this.textApp.label00068, value: "agent" },
      { text: this.textApp.label00498, value: "hostname" },
      { text: this.textApp.label00176, value: "description" },
      { text: this.textApp.label00176, value: "description" },
      { text: this.textApp.label00176, value: "process_id" },
      { text: this.textApp.label00176, value: "process" },
    ];
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
    alertData() {
      if(this.alert != null) {
        return this.alert.data;
      }
      return this.alert;
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    takeCharge() {
      this.axios({
        method: "POST",
        url: "/alerts/shown",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          company: this.company.id,
          id_alert: this.alert.id,
        },
      }).then((resp) => {
        console.log(resp.data);
        this.$emit("updateAlerts");
      });
      this.dialog = false;
    },
  },
  watch: {
  }
};
</script>
<style scoped></style>
