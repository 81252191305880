<template>
  <v-container>
    <v-stepper v-model="stepper" vertical>
      <v-stepper-step step="1">
        {{ textApp.label00302 }}
        <small>{{ textApp.label00303 }}</small>
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card class="mb-12 elevation-0" height="100px">
          <v-row class="ma-5">
            <v-col class="pl-0 pr-0" cols="12" sm="6" md="4">
              <v-select
                :items="listGroup"
                v-model="groupSelected"
                :label="textApp.label00069"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-card>
        <v-btn
          :disabled="groupSelected == null"
          color="primary"
          class="mb-1"
          @click="goStep2()"
        >
          {{ textApp.label00304 }}
        </v-btn>
      </v-stepper-content>

      <v-stepper-step step="2">
        {{ textApp.label00305 }}
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-card class="mb-12 elevation-0">
          <v-card
            outlined
            class="mb-2"
            v-for="(dir, i) in listDirectory"
            :key="i"
          >
            <v-card-text>
              <v-row align="start" justify="space-between">
                <v-col class="pb-0 mb-0" cols="12" sm="11" md="11">
                  <v-row>
                    <v-col class="pb-0 mb-0" cols="12" sm="12" md="12">
                      <v-text-field
                        class="mb-0"
                        :label="textApp.label00306"
                        placeholder="C:/Users/user/Desktop"
                        v-model="dir.path"
                        :rules="rules"
                        outlined
                        dense
                        autofocus
                        ref="refToElement"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0 pt-0" cols="12" sm="6" md="3">
                      <v-switch
                        v-model="dir.check_all"
                        flat
                        :label="textApp.label00307"
                      ></v-switch>
                    </v-col>
                    <v-col class="pb-0 pt-0" cols="12" sm="6" md="3">
                      <v-switch
                        v-model="dir.realtime"
                        flat
                        :label="textApp.label00308"
                      ></v-switch>
                    </v-col>
                    <v-col class="pb-0 pt-0" cols="12" sm="6" md="3">
                      <v-switch
                        v-model="dir.report_changes"
                        flat
                        :label="textApp.label00309"
                      ></v-switch>
                    </v-col>
                    <v-col class="pb-0 pt-0" cols="12" sm="6" md="3">
                      <v-switch
                        v-model="dir.whodata"
                        flat
                        :label="textApp.label00310"
                      ></v-switch>
                    </v-col>
                    <v-col class="pb-0 pt-0" cols="12" sm="6" md="3">
                      <v-text-field
                        class="mb-0"
                        :label="textApp.label00311"
                        placeholder="300"
                        v-model="dir.recursion_level"
                        outlined
                        dense
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="pb-0 pt-0" cols="12" sm="1" md="1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-card
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        class="mt-3"
                        @click="removeDirectory(i)"
                      >
                        <v-card-text>
                          <v-row align="center" justify="center">
                            <v-icon color="primary"> mdi-delete </v-icon>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </template>
                    <span>{{ textApp.label00312 }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card outlined style="cursor: pointer">
            <v-card-text>
              <v-row align="center" justify="center" @click="addEmptyDir()">
                <div>
                  <v-icon large color="primary"> mdi-plus </v-icon>
                  {{ textApp.label00313 }}
                </div>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card>
        <v-btn color="primary" class="mb-1" @click="goStep3()">
          {{ textApp.label00304 }}
        </v-btn>
        <v-btn text @click="stepper = 1">
          {{ textApp.label00314 }}
        </v-btn>
      </v-stepper-content>

      <v-stepper-step step="3">
        {{ textApp.label00315 }}
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-card class="mb-12 elevation-0">
          <v-card-text>
            <p>{{ textApp.label00316 }} {{ listDirectory.length }}</p>
            <p>
              {{ textApp.label00317 }}
              {{ listDirectory.filter((x) => x.check_all).length }}
            </p>
            <p>
              {{ textApp.label00318 }}
              {{ listDirectory.filter((x) => x.realtime).length }}
            </p>
            <p>
              {{ textApp.label00319 }}
              {{ listDirectory.filter((x) => x.report_changes).length }}
            </p>
            <p>
              {{ textApp.label00320 }}
              {{ listDirectory.filter((x) => x.whodata).length }}
            </p>
          </v-card-text>
        </v-card>
        <v-btn color="primary" class="mb-1" @click="pushConfig()">
          {{ textApp.label00013 }}
        </v-btn>
        <v-btn text @click="stepper = 2">
          {{ textApp.label00314 }}
        </v-btn>
      </v-stepper-content>
    </v-stepper>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      stepper: 1,
      loading: false,
      listGroup: [],
      groupSelected: null,
      listDirectory: [],
      rules: [],
    };
  },
  mounted() {
    this.loadData();
    this.rules = [
      (v) => {
        if (v == null || v == "") {
          return this.textApp.label00322;
        }
        return true;
      },
    ];
  },
  methods: {
    loadData() {
      this.loading = true;
      this.listGroup = [
        {
          text: this.textApp.label00321,
          value: "default",
        },
      ];
      this.axios({
        method: "GET",
        url: "/agents/groups",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        params: {
          company: this.company.id,
        },
      }).then((resp) => {
        console.log(resp.data);
        if (!resp.data.Error) {
          resp.data.forEach((group) => {
            console.log(group);
            this.listGroup.push({
              text: group.name,
              value: group.name,
            });
          });
        }

        this.loading = false;
      });
    },
    goStep2() {
      this.stepper = 2;
      this.getGroupConfig();
    },
    getGroupConfig() {
      this.loading = true;
      this.listDirectory = [];
      this.axios({
        method: "GET",
        url: "/agents/groups/getConfigurations",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        params: {
          company: this.company.id,
          group_id: this.groupSelected,
        },
      }).then((resp) => {
        console.log(resp.data);
        if (!resp.data.Error) {
          this.listDirectory = resp.data;
        }
        this.loading = false;
      });
    },
    addEmptyDir() {
      this.listDirectory.push({
        check_all: false,
        realtime: false,
        report_changes: false,
        whodata: false,
        recursion_level: "0",
        path: null,
      });
    },
    removeDirectory(index) {
      this.listDirectory.splice(index, 1);
    },
    goStep3() {
      let enableNextStep = true;
      this.listDirectory.forEach((dir) => {
        if (dir.path == "" || dir.path == null) {
          enableNextStep = false;
        }
      });
      if (enableNextStep) {
        this.stepper = 3;
      }
    },
    pushConfig() {
      this.loading = true;
      console.log(this.listDirectory);
      this.axios({
        method: "POST",
        url: "/agents/groups/pushConfiguration",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          company: this.company.id,
          group_id: this.groupSelected,
          config: this.listDirectory,
        },
      }).then((resp) => {
        console.log(resp.data);
        /*if (!resp.data.Error) {
          resp.data.forEach((group) => {
            console.log(group);
            this.listGroup.push({
              text: group.name,
              value: group.name,
            });
          });
        }*/
        this.$router.push("/trade-secret");

        this.loading = false;
      });
    },
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function () {
        return this.$store.getters.getCompany;
      },
      set: function (value) {
        this.$store.dispatch("setCompany", value);
      },
    },
  },
};
</script>
<style scoped></style>
