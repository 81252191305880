<template>
  <v-container class="pa-1">
    <v-tooltip left>
      <template #activator="{ on: tooltip }">
        <v-btn
          color="secondary"
          :disabled="module_rmm == 0"
          small
          :dark="module_rmm == 1"
          tile
          text
          v-on="{ ...tooltip }"
          @click.stop="openTunnel()"
        >
          <v-icon left>mdi-file-tree</v-icon>
          {{ textApp.label00514 }}
        </v-btn>
      </template>
      <span>{{ textApp.label00515 }}</span>
    </v-tooltip>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  props: ["agent"],
  mounted() {},
  methods: {
    openTunnel(){
      this.axios({
        method: "POST",
        url: "/agents/command/openfilesystem",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          company: this.company.id,
          agent: this.agent.id,
        },
      }).then((resp) => {
        console.log(resp.data);
        if(!resp.data.Error) {
          // this.openRDP(resp.data.data.lport);
          this.openWindows(resp.data.url);
        }
      });
    },
    /*openRDP(port) {
      window.open(`https://rport2.sidneo.it:${port}/createToken?width=1024&height=768`, '_blank', "toolbar=0,location=0,menubar=0,width=1044,height=788");
    },*/
    openWindows(url) {
      window.open(url, '_blank', "toolbar=0,location=0,menubar=0,width=1044,height=788");
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    company: {
      get: function () {
        return this.$store.getters.getCompany;
      },
      set: function (value) {
        this.$store.dispatch("setCompany", value);
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    module_rmm: {
      get: function() {
        return this.$store.getters.getModuleRMM;
      }
    },
  },
  watch: {},
};
</script>
<style scoped></style>
