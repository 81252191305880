<template>
  <v-container class="pa-0">
    <v-card outlined>
      <v-card-text v-if="!loading" class="pl-5">
        <v-row align="center" justify="space-between">
          <h4
            class="ml-5 mt-5"
            :style="{ color: $vuetify.theme.themes.light.secondary }"
          >
            {{ title }}
          </h4>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                v-if="dataChart.labels.length > 0"
                class="mr-5 mt-1"
                color="secondary"
                icon
                @click="dialog = true"
              >
                <v-icon>mdi-arrow-expand</v-icon>
              </v-btn>
            </template>
            <span>{{textApp.label00075}}</span>
          </v-tooltip>
        </v-row>

        <v-row>
          <v-divider class="mt-3 ml-2 mr-2"></v-divider>
        </v-row>

        <v-card
          v-if="this.dataChart.labels.length == 0"
          class="elevation-0 ma-3"
        >
          <v-card-text>
            <v-row style="height: 100px" align="center" justify="center">
              <p class="ma-5">
                <v-icon class="mr-5">mdi-alert</v-icon>{{textApp.label00076}}
              </p>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row v-else align="center" justify="space-between">
          <v-col cols="12" md="12" lg="12">
            <bar-chart
              class="mt-3"
              :chart-data="dataChart"
              :options="options"
              :width="widthChart"
              :height="heightChart"
            ></bar-chart>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else style="padding-top: 50px; padding-bottom: 50px">
        <v-row align="center" justify="center">
          <v-progress-circular
            :size="120"
            :width="10"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-row>
      </v-card-text>
      <v-dialog v-model="dialog" width="800">
        <v-card>
          <v-card-title>
            <h4 :style="{ color: $vuetify.theme.themes.light.secondary }" class="mb-5">
              {{ title }}
            </h4>
          </v-card-title>

          <v-card-text>
            <v-row align="center" justify="space-between">
              <v-col cols="12" md="12" lg="12">
                <bar-chart
                  :chart-data="dataChart"
                  :options="options"
                  :width="widthChart"
                  :height="heightChart"
                ></bar-chart>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false"> Chiudi </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import BarChart from "./BarChart.js";
import colors from "../config/color-charts";
export default {
  components: {
    BarChart
  },
  data() {
    return {
      loading: false,
      dataChart: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: []
          }
        ]
      },
      options: {
        legend: {
          display: true,
          position: "right",
          responsive: false
        },
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true
              }
            }
          ]
        },
        responsive: true,
        maintainAspectRatio: true,
        animation: {
          duration: 1
        },
        hover: {
          animationDuration: 1
        },
        responsiveAnimationDuration: 1
      },
      dialog: false
    };
  },
  props: {
    title: {
      type: String,
      required: true
    },
    linkAPI: {
      type: String,
      required: true
    },
    agent: {
      type: String,
      required: false
    },
    params: {
      type: String,
      default: "rid"
    },
    widthChart: {
      type: Number,
      default: 4
    },
    heightChart: {
      type: Number,
      default: 3
    },
    heightStyle: {
      type: String,
      default: "350px"
    },
    filters: {
      type: Object,
      default: null
    },
    calendarIntervals: {
      type: String,
      required: true
    },
    colorsSet: {
      type: String,
      default: "default"
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    setDataChart(array) {
      if (array == null) {
        this.dataChart = {
          labels: [],
          datasets: [
            {
              backgroundColor: this.colorsChart,
              data: []
            }
          ]
        };
      } else {
        this.dataChart.datasets = [];
        let colors = this.colorsChart;

        let labelSet = new Set();
        for (let i = 0; i < array.length; i++) {
          this.dataChart.labels.push(array[i].date);
          for (let j = 0; j < array[i].data.length; j++) {
            if (array[i].data[j].name != null) {
              labelSet.add(this.dataLabel[array[i].data[j].name].name);
            }
          }
        }
        labelSet = Array.from(labelSet);

        for (let k = 0; k < labelSet.length; k++) {
          let data = new Array(array.length).fill(0);
          for (let i = 0; i < array.length; i++) {
            for (let j = 0; j < array[i].data.length; j++) {
              if (this.dataLabel[array[i].data[j].name].name == labelSet[k]) {
                data[i] = array[i].data[j].count;
              }
            }
          }
          
          this.dataChart.datasets.push({
            label: labelSet[k],
            backgroundColor: colors[k],
            data: data
          });
        }
        console.log(this.dataChart);
      }
    },
    loadData() {
      this.loading = true;
      this.setDataChart(null);
      this.axios({
        method: "GET",
        url: this.linkAPI,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        params: {
          filterStartDate: this.filterStartDate.substring(
            0,
            this.filterStartDate.length - 5
          ),
          filterEndDate: this.filterEndDate.substring(
            0,
            this.filterStartDate.length - 5
          ),
          filterAgentSelected: this.filterAgents,
          agent: this.agent,
          calendar_interval: this.calendarIntervals,
          group: this.params,
          company: this.company.id
        }
      }).then(resp => {
        console.log(resp.data);
        if (!resp.data.Error) {
          this.setDataChart(resp.data);
        }
        this.loading = false;
      });
    },
    shuffle(array) {
      let currentIndex = array.length,
        temporaryValue,
        randomIndex;
      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }
      return array;
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      }
    },
    dataLabel: {
      get: function () {
        return this.$store.getters.getDataLabel;
      },
    },
    filterStartDate: {
      get: function() {
        if (this.filters == null) {
          return this.$store.getters.getStartDate;
        } else {
          return this.filters.startDate;
        }
      },
      set: function(value) {
        if (this.filters == null) {
          this.$store.dispatch("setStartDate", value);
        }
      }
    },
    filterEndDate: {
      get: function() {
        if (this.filters == null) {
          return this.$store.getters.getEndDate;
        } else {
          return this.filters.endDate;
        }
      },
      set: function(value) {
        this.$store.dispatch("setEndDate", value);
      }
    },
    filterAgents: {
      get: function() {
        return this.$store.getters.getAgents;
      },
      set: function(value) {
        this.$store.dispatch("setAgents", value);
      }
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
    token() {
      return this.$store.getters.getToken;
    },
    colorsChart() {
      if (colors[this.colorsSet].length > 0) {
        return colors[this.colorsSet];
      }
      return colors.default;
    }
  },
  watch: {
    filterStartDate() {
      this.loadData();
    },
    filterEndDate() {
      this.loadData();
    },
    filterAgents() {
      this.loadData();
    },
    token() {
      this.loadData();
    },
    calendarIntervals() {
      this.loadData();
    }
  }
};
</script>
<style scoped></style>
