<template>
  <v-container class="pa-10">
    <v-card class="pa-5">
      <v-card-title>
        <h4
          :style="{ color: $vuetify.theme.themes.light.secondary }"
          class="mb-5"
        >
          {{ textApp.label00336 }}
        </h4>
      </v-card-title>
      <v-card-text>
        <v-row v-if="loading" class="ma-0">
          <v-card-text style="padding-top: 50px; padding-bottom: 50px">
            <v-row align="center" justify="center">
              <v-progress-circular
                :size="120"
                :width="10"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-row>
          </v-card-text>
        </v-row>
        <v-row v-else>
          <v-col cols="12" sm="12" md="12" class="pb-0">
            <v-radio-group v-model="langSelected">
              <v-radio :label="textApp.label00337" value="it"></v-radio>
              <v-radio :label="textApp.label00338" value="en"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="setLang()">
          {{ textApp.label00013 }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { baseUrlSetup } from "../config/config";
import { cookie_key } from "../config/config";
export default {
  data() {
    return {
      loading: false,
      langSelected: null,
    };
  },
  mounted() {
    this.langSelected = this.textApp.lang;
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
      set: function (value) {
        this.$store.dispatch("setTextApplication", value);
      },
    },
    dataLabel: {
      get: function () {
        return this.$store.getters.getDataLabel;
      },
      set: function (value) {
        this.$store.dispatch("setDataLabel", value);
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function () {
        return this.$store.getters.getCompany;
      },
      set: function (value) {
        this.$store.dispatch("setCompany", value);
      },
    },
  },
  methods: {
    setLang() {
      this.loading = true;
      this.axios({
        method: "POST",
        url: "/user/setLanguage",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          lang: this.langSelected,
        },
      }).then((resp) => {
        console.log(resp.data);
        this.updateLang(this.langSelected);
      });
    },
    updateLang(newlang) {
      this.axios({
        method: "GET",
        url: `${baseUrlSetup}/text`,
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          lang: newlang,
        },
      }).then((resp) => {
        console.log(resp.data);
        this.textApp = resp.data;
        if (this.$cookies.get("permanent") != null) {
          let cookieData = JSON.parse(
            this.$CryptoJS.AES.decrypt(
              this.$cookies.get("permanent"),
              cookie_key
            ).toString(this.CryptoJS.enc.Utf8)
          );
          cookieData.lang = newlang;
        }
        this.updateDataText();
      });
    },
    updateDataText() {
      this.loading_text = true;
      this.axios({
        method: "GET",
        url: "/company/dataText",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        params: {
          company: this.company.id,
        },
      }).then((resp) => {
        console.log(resp.data);
        this.dataLabel = resp.data;
        this.loading = false;
        this.dialog = false;
        this.$router.push("/");
      });
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.error = false;
        this.group = null;
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped></style>
