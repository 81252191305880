<template>
  <v-container class="pr-5 pl-5">
    <v-row align="center" justify="start" class="ml-1">
      <span>
        <ReportGenericGDPR></ReportGenericGDPR>
      </span>
      <span>
        <Warning
          reportType="generic"
          :filters="{ startDate: filterStartDate, endDate: filterEndDate }"
        ></Warning>
      </span>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="ml-0"
            color="secondary"
            small
            to="/gdpr-generic-comparison"
          >
            {{textApp.label00100}}
          </v-btn>
        </template>
        <span>{{textApp.label00147}}</span>
      </v-tooltip>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="12">
        <doughnut-template
          :title="textApp.label00148"
          linkAPI="/charts/generic/rid"
          colorsSet="colorsChartRID"
          :type_code="constants.REQUIREMENTS.type_code"
          :type_label="textApp[constants.REQUIREMENTS.type_label]"
        ></doughnut-template>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="12">
        <doughnut-template
          :title="textApp.label00149"
          linkAPI="/charts/generic/tags"
          colorsSet="colorsChartCategDirigente"
          :type_code="constants.REQUIREMENTS.type_code"
          :type_label="textApp[constants.REQUIREMENTS.type_label]"
        ></doughnut-template>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <line-template
          :title="textApp.label00150"
          :label="textApp.label00170"
          linkAPI="/charts/generic/events"
          colorsSet="colorsChartLineDirigente"
        ></line-template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const FileDownload = require("js-file-download");
import LineTemplate from "../components/LineTemplate";
import DoughnutTemplate from "../components/DoughnutTemplate";
import ReportGenericGDPR from "../components/ReportGenericGDPR";
import Warning from "../components/Warning";
import constants from "../config/constants";

export default {
  components: {
    LineTemplate,
    DoughnutTemplate,
    ReportGenericGDPR,
    Warning
  },
  data() {
    return {
      btnGenerateGenericReportLoading: false,
      errorDownloadSimpleReport: false,
      constants: constants
    };
  },
  mounted() {},
  methods: {
    generateGenericReport() {
      this.btnGenerateGenericReportLoading = true;
      this.axios({
        method: "POST",
        url: "/generateGenericGDPRReport",
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/pdf"
        },
        data: {
          filter: {
            startDate: this.filterStartDate,
            endDate: this.filterEndDate,
            agents: this.filterAgents,
            filters: this.filters
          }
        }
      }).then(resp => {
        console.log(resp);
        if (resp.headers["content-type"] == "application/pdf") {
          FileDownload(resp.data, "Report generico GDPR.pdf");
        } else {
          this.errorDownloadSimpleReport = true;
        }
        this.btnGenerateGenericReportLoading = false;
      });
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    filterStartDate: {
      get: function() {
        return this.$store.getters.getStartDate;
      },
      set: function(value) {
        this.$store.dispatch("setStartDate", value);
      }
    },
    filterEndDate: {
      get: function() {
        return this.$store.getters.getEndDate;
      },
      set: function(value) {
        this.$store.dispatch("setEndDate", value);
      }
    },
    filterAgents: {
      get: function() {
        return this.$store.getters.getAgents;
      },
      set: function(value) {
        this.$store.dispatch("setAgents", value);
      }
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    }
  }
};
</script>
<style scoped></style>
