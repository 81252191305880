<template>
  <v-container class="pa-0 ma-0">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-card
          v-bind="attrs"
          v-on="on"
          class="elevation-0"
          @click="openStatsDetail(stat, level, level_key)"
        >
          <v-card-text v-if="stat != null">
            <h4>{{ label }}</h4>
            <span :style="{ color: color }">{{ stat.length }}</span>
          </v-card-text>
        </v-card>
      </template>
      <span>{{textApp.label00231}}</span>
    </v-tooltip>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["stat", "level", "level_key", "color", "label"],
  mounted() {},
  methods: {
    openStatsDetail() {
      this.$emit("openDetail", this.stat, this.level, this.level_key);
    }
  },
  watch: {},
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
    token() {
      return this.$store.getters.getToken;
    }
  }
};
</script>
<style scoped></style>
