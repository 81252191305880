<template>
  <v-container class="pr-5 pl-5">
    <v-row>
      <v-col class="d-flex" cols="12" sm="3">
        <v-select
          :items="calendarIntervals"
          v-model="selectedCalendarIntervals"
          :label="textApp.label00107"
          item-text="label"
          item-value="key"
        ></v-select>
      </v-col>
      <v-col cols="12" lg="12" md="12" sm="12">
        <multiple-bar-template
          :title="textApp.label00108"
          linkAPI="/charts/comparison/generic/rid"
          params="rid"
          :widthChart="4"
          :heightChart="1"
          :calendarIntervals="selectedCalendarIntervals"
          :agent="agent"
          colorsSet="colorsChartRID"
        ></multiple-bar-template>
      </v-col>
      <v-col cols="12" lg="12" md="12" sm="12">
        <multiple-bar-template
          :title="textApp.label00109"
          linkAPI="/charts/comparison/generic/tags"
          params="level1"
          :widthChart="4"
          :heightChart="1"
          :calendarIntervals="selectedCalendarIntervals"
          :agent="agent"
          colorsSet="colorsChartCategDirigente"
        ></multiple-bar-template>
      </v-col>
      <v-col cols="12" lg="12" md="12" sm="12">
        <multiple-bar-template
          :title="textApp.label00110"
          linkAPI="/charts/comparison/categ"
          :widthChart="4"
          :heightChart="1"
          :calendarIntervals="selectedCalendarIntervals"
          :agent="agent"
          colorsSet="colorsChartCategDPO"
        ></multiple-bar-template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MultipleBarTemplate from "../components/MultipleBarTemplate";

export default {
  components: {
    MultipleBarTemplate,
  },
  data() {
    return {
      btnGenerateGenericReportLoading: false,
      errorDownloadSimpleReport: false,
      calendarIntervals: [],
      selectedCalendarIntervals: "day",
    };
  },
  mounted() {
    this.calendarIntervals = [
      {
        label: this.textApp.label00111,
        key: "day",
      },
      {
        label: this.textApp.label00112,
        key: "week",
      },
      {
        label: this.textApp.label00113,
        key: "month",
      },
    ];
  },
  methods: {},
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    agent() {
      return this.$route.params.idAgent;
    },
  },
};
</script>
<style scoped></style>
