var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pr-5 pl-5"},[_c('v-btn-toggle',{staticClass:"mb-5",attrs:{"color":"primary","mandatory":""},model:{value:(_vm.granularityCompanySelected),callback:function ($$v) {_vm.granularityCompanySelected=$$v},expression:"granularityCompanySelected"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","value":"alert"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-alert-decagram-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.textApp.label00451))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","value":"data"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-database-alert-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.textApp.label00452))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","value":"empty"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-crop-free")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.textApp.label00453))])])],1),(!_vm.loading_text)?_c('div',[_c('v-row',{attrs:{"align":"start","justify":"start"}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('h4',[_vm._v(_vm._s(_vm.textApp.label00079))])])],1)],1)],1),(!_vm.loading && _vm.granularityCompanySelected == 'alert')?_c('v-row',{attrs:{"align":"start","justify":"start"}},_vm._l((_vm.listCompany),function(comp,i){return _c('v-col',{key:i,attrs:{"cols":"12","md":"6","lg":"4","xl":"3","sm":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',_vm._g(_vm._b({attrs:{"outlined":"","elevation":hover ? 3 : 0},on:{"click":function($event){return _vm.selectCompany(comp)}}},'v-card',attrs,false),on),[_c('v-card-text',[_c('h3',[_vm._v(_vm._s(comp.name))]),_c('v-divider',{staticClass:"mt-3 mb-3"}),_c('h4',[_vm._v(_vm._s(_vm.textApp.label00431))]),_vm._l((comp.role),function(role,j){return _c('div',{key:j},[_vm._v(" - "+_vm._s(role.name)+" ")])}),_c('v-divider',{staticClass:"mt-3 mb-3"}),_c('v-chip',{attrs:{"color":"red","label":"","text-color":"white"}},[_c('v-icon',{attrs:{"start":"","icon":"mdi-home"}}),_vm._v(" "+_vm._s(_vm.textApp.label00454)+"   "),_c('b',[_vm._v(_vm._s(comp.n_alert))])],1)],2)],1)]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.textApp.label00081)+" "+_vm._s(comp.name))])])],1)}),1):_vm._e(),(!_vm.loading && _vm.granularityCompanySelected == 'empty')?_c('v-row',{attrs:{"align":"start","justify":"start"}},_vm._l((_vm.listCompany),function(comp,i){return _c('v-col',{key:i,attrs:{"cols":"12","md":"6","lg":"4","xl":"3","sm":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',_vm._g(_vm._b({attrs:{"outlined":"","elevation":hover ? 3 : 0},on:{"click":function($event){return _vm.selectCompany(comp)}}},'v-card',attrs,false),on),[_c('v-card-text',[_c('h3',[_vm._v(_vm._s(comp.name))]),_c('v-divider',{staticClass:"mt-3 mb-1"}),_c('h4',[_vm._v(_vm._s(_vm.textApp.label00431))]),_vm._l((comp.role),function(role,j){return _c('div',{key:j},[_vm._v(" - "+_vm._s(role.name)+" ")])})],2)],1)]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.textApp.label00081)+" "+_vm._s(comp.name))])])],1)}),1):_vm._e(),(!_vm.loading && _vm.granularityCompanySelected == 'data')?_c('v-row',{attrs:{"align":"start","justify":"start"}},_vm._l((_vm.listCompany),function(comp,i){return _c('v-col',{key:i,attrs:{"cols":"12","md":"6","lg":"4","xl":"3","sm":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',_vm._g(_vm._b({attrs:{"outlined":"","elevation":hover ? 3 : 0},on:{"click":function($event){return _vm.selectCompany(comp)}}},'v-card',attrs,false),on),[_c('v-card-text',[_c('h3',[_vm._v(_vm._s(comp.name))]),_c('v-divider',{staticClass:"mt-3 mb-3"}),_c('h4',[_vm._v(_vm._s(_vm.textApp.label00431))]),_vm._l((comp.role),function(role,j){return _c('div',{key:j},[_vm._v(" - "+_vm._s(role.name)+" ")])}),_c('v-divider',{staticClass:"mt-3 mb-3"}),(!comp.alerts_error)?_c('div',[_c('h4',[_vm._v(_vm._s(_vm.textApp.label00432))]),_vm._l((_vm.maxRolesCompanies - comp.role.length),function(k){return _c('div',{key:k},[_c('br')])}),(!comp.alert_error)?_c('v-row',{staticClass:"pa-3"},[_c('v-divider',{staticClass:"mt-3 mb-3"}),_c('SeverityLevelList',{staticClass:"mb-5",attrs:{"severity_levels":comp.alerts}})],1):_vm._e(),(comp.alert_error)?_c('div',{staticClass:"mb-5"},[_vm._v(" "+_vm._s(_vm.textApp.label00080)+" ")]):_vm._e(),_c('v-divider',{staticClass:"mt-3 mb-3"}),(
                        !comp.alert_error && comp.vulnerabilities.length == 0
                      )?_c('div',[_vm._v(" "+_vm._s(_vm.textApp.label00433)+" ")]):_vm._e(),(
                        !comp.alert_error && comp.vulnerabilities.length > 0
                      )?_c('div',[_c('h4',[_vm._v(_vm._s(_vm.textApp.label00402))]),_vm._l((comp.vulnerabilities),function(vuln,k){return _c('div',{key:k},[_c('span',{style:({ color: _vm.colorSeverity(vuln.severity) })},[_vm._v(_vm._s(_vm.getLabelSeverity(vuln.severity, vuln.programs)))])])})],2):_vm._e()],2):_vm._e()],2)],1)]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.textApp.label00081)+" "+_vm._s(comp.name))])])],1)}),1):_vm._e(),(_vm.loading)?_c('v-row',{staticClass:"mt-5",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"size":120,"width":10,"color":"primary","indeterminate":""}})],1):_vm._e()],1):_c('div',[_c('v-row',{staticClass:"mt-5",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"size":120,"width":10,"color":"primary","indeterminate":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }