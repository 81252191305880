<template>
  <v-container class="pa-0">
    <!--<v-card :outlined="outlined" class="elevation-0">
      <v-card-text v-if="!loading" class="pl-5">-->
        <v-row align="center" justify="space-between">
          <h4
            class="ml-5 mt-3 mb-3"
            :style="{ color: $vuetify.theme.themes.light.secondary }"
          >
            {{ title }}
          </h4>
        </v-row>

        <v-card
          v-if="this.dataChart.labels.length == 0 && !this.loading"
          class="elevation-0 ma-3"
        >
          <v-card-text>
            <v-row style="height: 100px" align="center" justify="center">
              <p class="ma-5">
                <v-icon class="mr-5">mdi-alert</v-icon>{{textApp.label00076}}
              </p>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row v-if="this.dataChart.labels.length > 0 && !this.loading" align="center" justify="space-between">
          <v-col cols="12" md="12" lg="12">
            <bar-chart
              :chart-data="dataChart"
              :options="options"
              :width="widthChart"
              :height="1"
            ></bar-chart>
          </v-col>
        </v-row>
      <!--</v-card-text>-->
      <v-card-text v-if="this.loading" style="padding-top: 50px; padding-bottom: 50px">
        <v-row align="center" justify="center">
          <v-progress-circular
            :size="120"
            :width="10"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-row>
      </v-card-text>
    <!--</v-card>-->
  </v-container>
</template>

<script>
import BarChart from "../components/BarChart.js";
import { colorsChartTimelineAgents } from "../config/color-charts";
export default {
  components: {
    BarChart
  },
  data() {
    return {
      loading: false,
      colors: colorsChartTimelineAgents,
      dataChart: {
        labels: [],
        datasets: [
          {
            backgroundColor: colorsChartTimelineAgents,
            data: []
          }
        ]
      },
      options: {
        legend: {
          display: true,
          position: "right",
          responsive: false
        },
        responsive: true,
        dataset: {
          barPercentage: 1,
          categoryPercentage: 0.5
        },
        scales: {
          xAxes: [
            {
              stacked: true
            }
          ],
          yAxes: [
            {
              stacked: true
            }
          ]
        }
      },
      dialog: false
    };
  },
  props: ["title", "linkAPI", "widthChart", "data", "outlined"],
  mounted() {
    this.loadData();
  },
  methods: {
    setDataChart(t, d) {
      let datasets = [];
      d.forEach(item => {
        datasets.push({
          label: item.agent,
          backgroundColor: this.colors[parseInt(item.agent)],
          data: item.data
        });
      });
      this.dataChart = {
        labels: t,
        datasets: datasets,
        yAxes: [
          {
            stacked: true
          }
        ]
      };
    },
    loadData() {
      this.setDataChart([], []);
      if(this.linkAPI != null) {
        this.loading = true;
        this.axios({
          method: "GET",
          url: this.linkAPI,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.token}`
          },
          params: {
            company: this.company.id,
            filterStartDate: this.filterStartDate.substring(
              0,
              this.filterStartDate.length - 5
            ),
            filterEndDate: this.filterEndDate.substring(
              0,
              this.filterStartDate.length - 5
            ),
            filterAgentSelected: this.filterAgents,
            filters: this.filters,
            filterGroupSelected: this.filterGroup
          }
        }).then(resp => {
          console.log(resp.data);
          if (!resp.data.Error) {
            this.setDataChart(this.dateParser(resp.data.time), resp.data.data);
          }
          this.loading = false;
        });
      } else {
        if (this.data != null && !this.data.Error) {
          this.setDataChart(this.dateParser(this.data.time), this.data.data);
        }
      }
    },
    shuffle(array) {
      let currentIndex = array.length,
        temporaryValue,
        randomIndex;
      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }
      return array;
    },
    dateParser(arrayTime) {
      let time = [];
      arrayTime.forEach(item => {
        time.push(item.split("T")[0]);
      });
      return time;
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    filterStartDate: {
      get: function() {
        return this.$store.getters.getStartDate;
      },
      set: function(value) {
        this.$store.dispatch("setStartDate", value);
      }
    },
    filterEndDate: {
      get: function() {
        return this.$store.getters.getEndDate;
      },
      set: function(value) {
        this.$store.dispatch("setEndDate", value);
      }
    },
    filterAgents: {
      get: function() {
        return this.$store.getters.getAgents;
      },
      set: function(value) {
        this.$store.dispatch("setAgents", value);
      }
    },
    filterGroup: {
      get: function() {
        return this.$store.getters.getGroup;
      },
      set: function(value) {
        this.$store.dispatch("setGroup", value);
      }
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
    reloadButton: {
      get: function() {
        return this.$store.getters.getReloadButton;
      },
      set: function(value) {
        this.$store.dispatch("setReloadButton", value);
      }
    },
    filtersApplication: {
      get: function () {
        return this.$store.getters.getFiltersApplication;
      }
    },
    filters() {
      return {
        requirementsSelected: this.filtersApplication.filter(e => e.type_code == "requirements").map(x => x.value),
        IDAgentsSelected: this.filtersApplication.filter(e => e.type_code == "IDAgents").map(x => x.value),
        IPAddressSelected: this.filtersApplication.filter(e => e.type_code == "IPAddress").map(x => x.value),
        severitySelected: this.filtersApplication.filter(e => e.type_code == "severity").map(x => x.value),
        descriptionSelected: this.filtersApplication.filter(e => e.type_code == "descriptions").map(x => x.value),
      }
    }
  },
  watch: {
    filterStartDate() {
      this.loadData();
    },
    filterEndDate() {
      this.loadData();
    },
    filterAgents() {
      this.loadData();
    },
    filterGroup() {
      this.loadData();
    },
    token() {
      this.loadData();
    },
    reloadButton() {
      this.loadData();
    },
    data() {
      this.loadData();
    }
  }
};
</script>
<style scoped></style>
