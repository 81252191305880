var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"pl-3",attrs:{"align":"start","justify":"start"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-0",attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.goToInventoryData()}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.textApp.label00098)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.textApp.label00099))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.goToComparison()}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.textApp.label00100)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.textApp.label00100))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.goToVulnerability()}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.textApp.label00402)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.textApp.label00525))])]),(_vm.module_rmm)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.goToCommand()}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.textApp.label00419)+" ")])]}}],null,false,3080514266)},[_c('span',[_vm._v(_vm._s(_vm.textApp.label00420))])]):_vm._e()],1),_c('v-row',{attrs:{"align":"start","justify":"start"}},[_c('v-col',{attrs:{"cols":"12","lg":"4","md":"4","sm":"12"}},[_c('doughnut-template',{attrs:{"title":_vm.textApp.label00102,"linkAPI":"/charts/agents/actions","heightStyle":"350px","agent":_vm.agent}})],1),_c('v-col',{attrs:{"cols":"12","lg":"8","md":"8","sm":"12"}},[_c('bar-template',{attrs:{"title":_vm.textApp.label00103,"linkAPI":"/charts/categ/tags","agent":_vm.agent}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6","sm":"12"}},[_c('doughnut-template',{attrs:{"title":_vm.textApp.label00104,"linkAPI":"/charts/generic/rid","colorsSet":"colorsChartRID","agent":_vm.agent}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6","sm":"12"}},[_c('doughnut-template',{attrs:{"title":_vm.textApp.label00105,"linkAPI":"/charts/generic/tags","colorsSet":"colorsChartCategDirigente","agent":_vm.agent}})],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('line-template',{attrs:{"title":_vm.textApp.label00106,"label":"Eventi","linkAPI":"/charts/generic/events","colorsSet":"colorsChartLineDirigente","agent":_vm.agent}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }