<template>
  <v-container>

    <v-dialog v-model="dialog" width="700">
      <template #activator="{ on: dialog }">
        <v-tooltip bottom>
          <template #activator="{ on: tooltip }">
            <v-btn
              text
              v-on="{ ...tooltip, ...dialog }"
            >
              {{ agents.length }}
            </v-btn>
          </template>
          <span>
            {{ textApp.label00540 }}
          </span>
        </v-tooltip>
      </template>

      <v-card>
        <v-card-title>
          <h4 :style="{ color: $vuetify.theme.themes.light.secondary }">{{ textApp.label00541 }}</h4>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-row>
            <v-col v-if="agents.length == 0" class="pa-3" cols="12" md="12" sm="12">
              <h3 class="mt-4">{{ textApp.label00542 }}</h3>
            </v-col>
            <v-col v-else class="pa-3 mt-4" cols="12" md="12" sm="12">
              <div v-for="a in agents" :key="a.id">
                <h3 class="mt-2">{{ a.id }} - {{ a.name }}</h3>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> {{ textApp.label00042 }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    
  </v-container>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      dialog: false,
    };
  },
  props: ["agents"],
  mounted() {
  },
  methods: {
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    company: {
      get: function () {
        return this.$store.getters.getCompany;
      },
      set: function (value) {
        this.$store.dispatch("setCompany", value);
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
  },
};
</script>
<style scoped></style>
