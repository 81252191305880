import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login";
import Home from "../views/Home.vue";
import CompanySelection from "../views/CompanySelection.vue";
import ModuleGDPRDirigente from "../views/ModuleGDPRDirigente.vue";
import ComparisonGDPRDirigente from "../views/ComparisonGDPRDirigente.vue";
import ComparisonGDPRDPO from "../views/ComparisonGDPRDPO.vue";
import ComparisonGDPRSingleAgent from "../views/ComparisonGDPRSingleAgent.vue";
import ModuleGDPRDPO from "../views/ModuleGDPRDPO.vue";
import ModuleTradeSecret from "../views/ModuleTradeSecret.vue";
import ModuleTradeSecretWin from "../views/ModuleTradeSecretWin.vue";
import TradeSecretConfig from "../views/TradeSecretConfig.vue";
import ModuleGDPRIT from "../views/ModuleGDPRIT.vue";
import ModuleWarning from "../views/ModuleWarning.vue";
import ModuleVulnerability from "../views/ModuleVulnerability.vue";
import ManageAgents from "../views/ManageAgents";
import AgentDetail from "../views/AgentDetail";
import AgentTechnicalDetail from "../views/AgentTechnicalDetail";
import AgentCommands from "../views/AgentCommands";
import AgentListCommands from "../views/AgentListCommands";
import ManageAgentGroups from "../views/ManageAgentGroups";
import GroupAgentAssigner from "../views/GroupAgentAssigner";
import GroupDetail from "../views/GroupDetail";
import AdminL1 from "../views/AdminL1";
import AdminL2 from "../views/AdminL2";
import AdminL3 from "../views/AdminL3";
import Report from "../views/Report";
import ReportSimple from "../views/ReportSimple";
//import ReportActionAgent from "../views/ReportActionAgent";
import ReportAssetAgent from "../views/ReportAssetAgent";
import ReportWarning from "../views/ReportWarning";
import PasswordManager from "../views/PasswordManager";
import ChangeLang from "../views/ChangeLang";
import PasswordSetter from "../views/PasswordSetter";
import ModuleAlertSOC from "../views/ModuleAlertSOC";
import About from "../views/About";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: CompanySelection,
    meta: {
      breadcrumb: function() {
        return [{ text: "label00409", to: "/" }];
      },
      filterDates: false,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/login",
    component: Login,
    meta: {
      breadcrumb: function() {
        return [{ text: "label00333", to: "/login" }];
      },
      filterDates: false,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/home",
    component: Home,
    meta: {
      breadcrumb: function() {
        return [{ text: "label00245", to: "/home" }];
      },
      filterDates: true,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/gdpr-generic",
    component: ModuleGDPRDirigente,
    meta: {
      breadcrumb: function() {
        return [{ text: "label00248", to: "/gdpr-generic" }];
      },
      filterDates: true,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/gdpr-generic-comparison",
    component: ComparisonGDPRDirigente,
    meta: {
      breadcrumb: function() {
        return [
          { text: "label00248", to: "/gdpr-generic", disabled: false },
          { text: "label00100", to: "/gdpr-generic-comparison" }
        ];
      },
      filterDates: true,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/gdpr-categ",
    component: ModuleGDPRDPO,
    meta: {
      breadcrumb: function() {
        return [{ text: "label00250", to: "/gdpr-categ" }];
      },
      filterDates: true,
      filterAgent: false,
      filterGroup: true
    }
  },
  {
    path: "/gdpr-categ-comparison",
    component: ComparisonGDPRDPO,
    meta: {
      breadcrumb: function() {
        return [
          { text: "label00250", to: "/gdpr-categ", disabled: false },
          { text: "label00100", to: "/gdpr-categ-comparison" }
        ];
      },
      filterDates: true,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/trade-secret",
    component: ModuleTradeSecret,
    meta: {
      breadcrumb: function() {
        return [{ text: "label00254", to: "/trade-secret" }];
      },
      filterDates: true,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/trade-secret-win",
    component: ModuleTradeSecretWin,
    meta: {
      breadcrumb: function() {
        return [{ text: "label00254", to: "/trade-secret-win" }];
      },
      filterDates: true,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/trade-secret-config-rules",
    component: TradeSecretConfig,
    meta: {
      breadcrumb: function() {
        return [
          { text: "label00254", to: "/trade-secret" },
          { text: "label00276", to: "/trade-secret-config-rules" },
        ];
      },
      filterDates: true,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/gdpr-specific",
    component: ModuleGDPRIT,
    meta: {
      breadcrumb: function() {
        return [{ text: "label00252", to: "/gdpr-specific" }];
      },
      filterDates: true,
      filterAgent: false,
      filterGroup: true
    }
  },
  {
    path: "/agents",
    component: ManageAgents,
    meta: {
      breadcrumb: function() {
        return [{ text: "label00256", to: "/agents" }];
      },
      filterDates: true,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/agentdetail/:idAgent",
    component: AgentDetail,
    meta: {
      breadcrumb: function(/*context*/) {
        return [
          { text: "label00256", to: "/agents", disabled: false },
          {
            text: "label00339",// + context.$route.params.idAgent,
            to: "/agentdetail"
          }
        ];
      },
      filterDates: true,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/agentdetail/:idAgent/inventory",
    component: AgentTechnicalDetail,
    meta: {
      breadcrumb: function(context) {
        return [
          { text: "label00256", to: "/agents", disabled: false },
          {
            text: "label00339",// + context.$route.params.idAgent,
            to: `/agentdetail/${context.$route.params.idAgent}`,
            disabled: false,
            exact: true
          },
          {
            text: "label00098",
            to: `/agentdetail/${context.$route.params.idAgent}/inventory`,
            disabled: true
          }
        ];
      },
      filterDates: false,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/agentdetail/:idAgent/commands",
    component: AgentCommands,
    meta: {
      breadcrumb: function(context) {
        return [
          { text: "label00256", to: "/agents", disabled: false },
          {
            text: "label00339",// + context.$route.params.idAgent,
            to: `/agentdetail/${context.$route.params.idAgent}`,
            disabled: false,
            exact: true
          },
          {
            text: "label00410",
            to: `/agentdetail/${context.$route.params.idAgent}/listcommands`,
            disabled: false,
          },
          {
            text: "label00421",
            to: `/agentdetail/${context.$route.params.idAgent}/commands`,
            disabled: true
          }
        ];
      },
      filterDates: false,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/agentdetail/:idAgent/listcommands",
    component: AgentListCommands,
    meta: {
      breadcrumb: function(context) {
        return [
          { text: "label00256", to: "/agents", disabled: false },
          {
            text: "label00339",// + context.$route.params.idAgent,
            to: `/agentdetail/${context.$route.params.idAgent}`,
            disabled: false,
            exact: true
          },
          {
            text: "label00410",
            to: `/agentdetail/${context.$route.params.idAgent}/listcommands`,
            disabled: true
          }
        ];
      },
      filterDates: false,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/agentdetail/:idAgent/comparison",
    component: ComparisonGDPRSingleAgent,
    meta: {
      breadcrumb: function(context) {
        return [
          { text: "label00256", to: "/agents", disabled: false },
          {
            text: "label00339", // + context.$route.params.idAgent,
            to: `/agentdetail/${context.$route.params.idAgent}`,
            disabled: false,
            exact: true
          },
          {
            text: "label00100",
            to: `/agentdetail/${context.$route.params.idAgent}/comparison`,
            disabled: true
          }
        ];
      },
      filterDates: true,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/agentdetail/:idAgent/vulnerability",
    component: ModuleVulnerability,
    meta: {
      breadcrumb: function(context) {
        return [
          { text: "label00256", to: "/agents", disabled: false },
          {
            text: "label00339", // + context.$route.params.idAgent,
            to: `/agentdetail/${context.$route.params.idAgent}`,
            disabled: false,
            exact: true
          },
          {
            text: "label00402",
            to: `/agentdetail/${context.$route.params.idAgent}/vulnerability`,
            disabled: true
          }
        ];
      },
      filterDates: false,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/agentgroups",
    component: ManageAgentGroups,
    meta: {
      breadcrumb: function() {
        return [{ text: "label00258", to: "/agentgroups" }];
      },
      filterDates: true,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/groupdetail/:group",
    component: GroupDetail,
    meta: {
      breadcrumb: function(/*context*/) {
        return [
          { text: "label00258", to: "/agentgroups", disabled: false },
          {
            text: "label00403",// + context.$route.params.group,
            to: "/groupdetail"
          }
        ];
      },
      filterDates: false,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/groupassignment/:group",
    component: GroupAgentAssigner,
    meta: {
      breadcrumb: function(context) {
        return [
          { text: "label00258", to: "/agentgroups", disabled: false },
          {
            text: "label00403",// + context.$route.params.group,
            to: `/groupdetail/${context.$route.params.group}`,
            disabled: false,
            exact: true
          },
          { text: "label00404", to: "/groupassignment" }
        ];
      },
      filterDates: false,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/groupdetail/:idGroup/agentdetail/:idAgent",
    component: AgentDetail,
    meta: {
      breadcrumb: function(context) {
        return [
          { text: "label00258", to: "/agentgroups", disabled: false },
          {
            text: "label00403",// + context.$route.params.idGroup,
            to: `/groupdetail/${context.$route.params.idGroup}`,
            disabled: false,
            exact: true
          },
          {
            text: "label00339",// + context.$route.params.idAgent,
            to: "/agentdetail",
            disabled: true
          }
        ];
      },
      filterDates: false,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/warning",
    component: ModuleWarning,
    meta: {
      breadcrumb: function() {
        return [{ text: "label00174", to: "/warning" }];
      },
      filterDates: false,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/alerts-soc",
    component: ModuleAlertSOC,
    meta: {
      breadcrumb: function() {
        return [{ text: "label00518", to: "/alerts-soc" }];
      },
      filterDates: false,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/vulnerability",
    component: ModuleVulnerability,
    meta: {
      breadcrumb: function() {
        return [{ text: "label00402", to: "/vulnerability" }];
      },
      filterDates: true,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/adminL1",
    component: AdminL1,
    meta: {
      breadcrumb: function() {
        return [{ text: "label00401", to: "/admin" }];
      },
      filterDates: false,
      filterAgent: false,
      filterGroup: false,
      adminL1: true
    }
  },
  {
    path: "/adminL2",
    component: AdminL2,
    meta: {
      breadcrumb: function() {
        return [{ text: "label00401", to: "/admin" }];
      },
      filterDates: false,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/adminL3",
    component: AdminL3,
    meta: {
      breadcrumb: function() {
        return [{ text: "label00401", to: "/admin" }];
      },
      filterDates: false,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/report",
    component: Report,
    meta: {
      breadcrumb: function() {
        return [{ text: "label00455", to: "/report" }];
      },
      filterDates: false,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/report-simple",
    component: ReportSimple,
    meta: {
      breadcrumb: function() {
        return [
          { text: "label00455", to: "/report", disabled: false },
          {
            text: "label00456",
            to: "/report-simple",
            disabled: true
          }
        ];
      },
      filterDates: false,
      filterAgent: false,
      filterGroup: false
    }
  },
  /*{
    path: "/report-action-agent",
    component: ReportActionAgent,
    meta: {
      breadcrumb: function() {
        return [
          { text: "label00456", to: "/report", disabled: false },
          {
            text: "label00339",
            to: "/report-action-agent",
            disabled: true
          }
        ];
      },
      filterDates: false,
      filterAgent: false,
      filterGroup: false
    }
  },*/
  {
    path: "/report-asset-agent",
    component: ReportAssetAgent,
    meta: {
      breadcrumb: function() {
        return [
          { text: "label00455", to: "/report", disabled: false },
          {
            text: "label00475",
            to: "/report-asset-agent",
            disabled: true
          }
        ];
      },
      filterDates: false,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/report-warning",
    component: ReportWarning,
    meta: {
      breadcrumb: function() {
        return [
          { text: "label00455", to: "/report", disabled: false },
          {
            text: "label00489",
            to: "/report-warning",
            disabled: true
          }
        ];
      },
      filterDates: false,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/password-manager",
    component: PasswordManager,
    meta: {
      breadcrumb: function() {
        return [{ text: "label00400", to: "/password-manager" }];
      },
      filterDates: false,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/change-lang",
    component: ChangeLang,
    meta: {
      breadcrumb: function() {
        return [{ text: "label00334", to: "/change-lang" }];
      },
      filterDates: false,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/password/:token",
    component: PasswordSetter,
    meta: {
      breadcrumb: function() {
        return [{ text: "Password", to: "/password" }];
      },
      filterDates: false,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/about",
    component: About,
    meta: {
      breadcrumb: function() {
        return [{ text: "label00274", to: "/about" }];
      },
      filterDates: false,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "/",
    redirect: "/",
    meta: {
      breadcrumb: function() {
        return [{ text: "label00245", to: "/" }];
      },
      filterDates: false,
      filterAgent: false,
      filterGroup: false
    }
  },
  {
    path: "*",
    component: CompanySelection,
    meta: {
      breadcrumb: function() {
        return [{ text: "label00245", to: "/" }];
      },
      filterDates: false,
      filterAgent: false,
      filterGroup: false
    }
  }
];

const router = new VueRouter({
  routes
});

export default router;
