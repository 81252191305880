<template>
  <v-container class="pr-5 pl-5">
    <v-row align="start" justify="start" class="pl-3">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="ml-3"
            color="secondary"
            small
            @click="goToGroupAgentAssigner()"
          >
            {{textApp.label00137}}
          </v-btn>
        </template>
        <span>{{textApp.label00138}}</span>
      </v-tooltip>
    </v-row>
    <v-row align="start" justify="start">
      <v-col cols="12" md="12">
        <ListAgents :group="group"></ListAgents>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListAgents from "../components/ListAgents";
export default {
  components: {
    ListAgents
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    goToGroupAgentAssigner() {
      this.$router.push(`/groupassignment/${this.group}`);
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    group() {
      return this.$route.params.group;
    }
  }
};
</script>
<style scoped></style>
