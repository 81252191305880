<template>
  <v-container class="pa-0">
    <v-row>
      <v-col cols="12" md="12" class="pb-0">
        <div v-for="(alert, q) in severity_levels" :key="alert + q">
          <div
            v-if="alert.severity_level == 1"
            style="height: 8px; width: 8px; background-color: #2E7D32; border-radius: 50%; display: inline-block;"
          ></div>
          <div style="border-left: 6px solid #2E7D32;">
            <span
              v-if="alert.severity_level <= 8 && alert.count > 0"
              class="ml-3"
              >{{textApp.label00211}} {{ alert.severity_level }}:
              <b>{{ alert.count }}</b> {{textApp.label00200}}</span
            >
            <span
              v-if="alert.severity_level <= 8 && alert.count == 0"
              class="ml-3"
              >{{textApp.label00211}} {{ alert.severity_level }}:
              {{ alert.count }} {{textApp.label00200}}</span
            >
          </div>
          <div
            v-if="alert.severity_level == 8"
            style="height: 8px; width: 8px; background-color: #FF8F00; border-radius: 50%; display: inline-block;"
          ></div>
          <div style="border-left: 6px solid #FF8F00;">
            <span
              v-if="
                alert.severity_level > 8 &&
                  alert.severity_level <= 12 &&
                  alert.count > 0
              "
              class="ml-3"
              >{{textApp.label00211}} {{ alert.severity_level }}:
              <b>{{ alert.count }}</b> {{textApp.label00200}}</span
            >
            <span
              v-if="
                alert.severity_level > 8 &&
                  alert.severity_level <= 12 &&
                  alert.count == 0
              "
              class="ml-3"
              >{{textApp.label00211}} {{ alert.severity_level }}:
              {{ alert.count }} {{textApp.label00200}}</span
            >
          </div>
          <div
            v-if="alert.severity_level == 12"
            style="height: 8px; width: 8px; background-color: #E53935; border-radius: 50%; display: inline-block;"
          ></div>
          <div style="border-left: 6px solid #E53935;">
            <span
              v-if="alert.severity_level > 12 && alert.count > 0"
              class="ml-3"
              >{{textApp.label00211}}  {{ alert.severity_level }}:
              <b>{{ alert.count }}</b> {{textApp.label00200}}</span
            >
            <span
              v-if="alert.severity_level > 12 && alert.count == 0"
              class="ml-3"
              >{{textApp.label00211}} {{ alert.severity_level }}:
              {{ alert.count }} {{textApp.label00200}}</span
            >
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["severity_levels"],
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
  }
};
</script>
<style scoped></style>
