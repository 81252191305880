<template>
  <v-container class="pr-5 pl-5">
    <v-row>
      <v-col cols="12" md="12">
        <v-card-text
          v-if="loading"
          style="padding-top: 50px; padding-bottom: 50px"
        >
          <v-row align="center" justify="center">
            <v-progress-circular
              :size="120"
              :width="10"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </v-card-text>
        <v-timeline v-else>
          <span v-for="(item, i) in listDates" :key="i">
            <v-timeline-item
              :color="colorItem(item)"
              small
              :left="!item.warning"
            >
              <template v-if="item.warning" v-slot:opposite>
                <span>{{textApp.label00174}}</span>
              </template>
              <template v-else v-slot:opposite>
                <span>{{textApp.label00178}}</span>
              </template>
              <v-card v-if="item.warning" class="elevation-2">
                <v-card-title>
                  <h4>{{ displayDate(item.date) }}</h4>
                </v-card-title>
                <v-card-text>
                  <div
                    v-for="(warn, w) in item.warningList"
                    :key="w"
                    class="mb-4"
                  >
                    <b>{{textApp.label00152}}</b>: {{ warn.title }} <br />
                    <b>{{textApp.label00176}}</b>: {{ warn.description }}
                  </div>
                </v-card-text>
              </v-card>
              <v-card v-else class="elevation-2">
                <v-card-title>
                  <h4>{{ displayDate(item.date) }}</h4>
                </v-card-title>
                <v-card-text>
                  <div v-for="(elem, j) in item.data" :key="j">
                    <b>{{ dataLabel[elem.name].name }}</b
                    >: {{ elem.count }}
                  </div>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </span>
        </v-timeline>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      listDates: [],
      loading: false
    };
  },
  props: ["calendarIntervals", "url"],
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      this.listDates = [];
      this.axios({
        method: "GET",
        url: this.url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        params: {
          filterStartDate: this.filterStartDate.substring(
            0,
            this.filterStartDate.length - 5
          ),
          filterEndDate: this.filterEndDate.substring(
            0,
            this.filterStartDate.length - 5
          ),
          filterAgentSelected: this.filterAgents,
          agent: this.agent,
          calendar_interval: this.calendarIntervals,
          company: this.company.id
        }
      }).then(resp => {
        console.log(resp.data);
        if (!resp.data.Error) {
          this.listDates = resp.data;
        }
        this.loading = false;
      });
    },
    colorItem(item) {
      return item.warning ? "primary" : "secondary";
    },
    displayDate(date) {
      if (this.calendarIntervals == "day") {
        return (
          date.substring(8, 10) +
          " " +
          date.substring(5, 7) +
          " " +
          date.substring(0, 4)
        );
      } else if (this.calendarIntervals == "week") {
        return (
          this.textApp.label00179 + " " +
          date.substring(8, 10) +
          " " +
          date.substring(5, 7) +
          " " +
          date.substring(0, 4)
        );
      } else {
        return this.textApp.label00180 + " " + date.substring(5, 7) + " " + date.substring(0, 4);
      }
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    dataLabel: {
      get: function () {
        return this.$store.getters.getDataLabel;
      },
    },
    filterStartDate: {
      get: function() {
        return this.$store.getters.getStartDate;
      },
      set: function(value) {
        this.$store.dispatch("setStartDate", value);
      }
    },
    filterEndDate: {
      get: function() {
        return this.$store.getters.getEndDate;
      },
      set: function(value) {
        this.$store.dispatch("setEndDate", value);
      }
    },
    filterAgents: {
      get: function() {
        return this.$store.getters.getAgents;
      },
      set: function(value) {
        this.$store.dispatch("setAgents", value);
      }
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
    token() {
      return this.$store.getters.getToken;
    }
  },
  watch: {
    calendarIntervals() {
      this.loadData();
    },
    filterStartDate() {
      this.loadData();
    },
    filterEndDate() {
      this.loadData();
    },
    filterAgents() {
      this.loadData();
    },
    token() {
      this.loadData();
    }
  }
};
</script>
<style scoped></style>
