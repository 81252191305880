<template>
  <v-container>
    <v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="6" lg="6">
          <v-row>
            <v-text-field
              v-model="oldPsw"
              outlined
              :label="textApp.label00285"
              :append-icon="showOldPsw ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showOldPsw ? 'text' : 'password'"
              @click:append="showOldPsw = !showOldPsw"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              v-model="newPsw1"
              outlined
              :label="textApp.label00286"
              :append-icon="showPsw1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPsw1 ? 'text' : 'password'"
              @click:append="showPsw1 = !showPsw1"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-card outlined width="100%">
              <v-card-text class="pb-0">
                <span>{{textApp.label00287}}</span>
                <v-progress-linear
                  :value="progressPassword"
                  class="mt-3 mb-4"
                ></v-progress-linear>
                <v-checkbox
                  v-model="checkboxLength"
                  :label="textApp.label00288"
                  disabled
                ></v-checkbox>
                <v-checkbox
                  v-model="checkboxNotCapital"
                  :label="textApp.label00289"
                  disabled
                ></v-checkbox>
                <v-checkbox
                  v-model="checkboxCapital"
                  :label="textApp.label00290"
                  disabled
                ></v-checkbox>
                <v-checkbox
                  v-model="checkboxNumber"
                  :label="textApp.label00291"
                  disabled
                ></v-checkbox>
                <v-checkbox
                  v-model="checkboxSpecialChar"
                  :label="textApp.label00292"
                  disabled
                ></v-checkbox>
                <v-checkbox
                  v-model="checkboxConsecutives"
                  :label="textApp.label00293"
                  disabled
                ></v-checkbox>
                <v-checkbox
                  v-model="checkboxContainsName"
                  :label="textApp.label00294"
                  disabled
                ></v-checkbox>
              </v-card-text>
            </v-card>
          </v-row>
          <v-row class="mt-5">
            <v-text-field
              v-model="newPsw2"
              outlined
              :append-icon="showPsw2 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.matchPsw]"
              :type="showPsw2 ? 'text' : 'password'"
              :label="textApp.label00295"
              @click:append="showPsw2 = !showPsw2"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-card outlined width="100%">
              <v-card-text class="pb-0">
                <span>{{textApp.label00296}}</span>
                <v-progress-linear
                  :value="progressPassword2"
                  class="mt-3 mb-4"
                ></v-progress-linear>
                <v-checkbox
                  v-model="checkboxPassword2"
                  :label="textApp.label00297"
                  disabled
                ></v-checkbox>
              </v-card-text>
            </v-card>
          </v-row>
          <v-row>
            <v-btn
              color="primary"
              @click="confirmChangePsw()"
              class="mt-4"
              :disabled="!enableSetPassword"
            >
              {{textApp.label00240}}
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-row>
    <v-dialog v-model="dialogConfirm" width="500">
      <v-card>
        <v-card-title>
          <h4 :style="{ color: $vuetify.theme.themes.light.secondary }">
            {{textApp.label00298}}
          </h4>
        </v-card-title>

        <v-card-text v-if="!loading && !showMessageOK && !showMessageNOK">
          {{textApp.label00299}}
        </v-card-text>
        <v-card-text
          v-if="loading"
          style="padding-top: 50px; padding-bottom: 50px"
        >
          <v-row align="center" justify="center">
            <v-progress-circular
              :size="120"
              :width="10"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </v-card-text>
        <v-card-text v-if="showMessageNOK">
          {{textApp.label00300}}
        </v-card-text>
        <v-card-text v-if="showMessageOK">
          {{textApp.label00301}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions v-if="!loading && !showMessageOK && !showMessageNOK">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogConfirm = false">
            {{textApp.label00012}}
          </v-btn>
          <v-btn color="primary" @click="changePassword()">
            {{textApp.label00013}}
          </v-btn>
        </v-card-actions>

        <v-card-actions v-if="showMessageOK || showMessageNOK">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogConfirm = false">
            {{textApp.label00042}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      showOldPsw: false,
      showPsw1: false,
      showPsw2: false,
      oldPsw: "",
      newPsw1: "",
      newPsw2: "",
      rules: {
        required: value => !!value || "Required.",
        min: v => (v != null && v.length) >= 8 || "Min 8 characters",
        matchPsw: v => v == this.newPsw1 || "Le password devono coincidere!"
      },
      dialogConfirm: false,
      loading: false,
      showMessageOK: false,
      showMessageNOK: false
    };
  },
  mounted() {},
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
    credentials: {
      get: function() {
        return this.$store.getters.getCredentials;
      },
      set: function(value) {
        this.$store.dispatch("setCredentials", value);
      }
    },
    checkboxLength() {
      return this.newPsw1.length >= 8;
    },
    checkboxNumber() {
      return /.*[0-9].*/.test(this.newPsw1);
    },
    checkboxNotCapital() {
      return /.*[a-z].*/.test(this.newPsw1);
    },
    checkboxCapital() {
      return /.*[A-Z].*/.test(this.newPsw1);
    },
    checkboxSpecialChar() {
      return /.*[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~].*/.test(this.newPsw1);
    },
    checkboxConsecutives() {
      return this.checkboxLength && !/([a-zA-Z0-9])\1\1+/.test(this.newPsw1);
    },
    checkboxContainsName() {
      let name = this.newPsw1
        .toLowerCase()
        .includes(this.credentials.name.toLowerCase());
      let surname = this.newPsw1
        .toLowerCase()
        .includes(this.credentials.surname.toLowerCase());
      let company = this.newPsw1
        .toLowerCase()
        .includes(this.company.name.toLowerCase());
      return this.checkboxLength && !name && !surname && !company;
    },
    checkboxPassword2() {
      return this.newPsw1.length > 0 && this.newPsw1 == this.newPsw2;
    },
    progressPassword() {
      let checks = [
        this.checkboxLength,
        this.checkboxNumber,
        this.checkboxNotCapital,
        this.checkboxCapital,
        this.checkboxSpecialChar,
        this.checkboxConsecutives,
        this.checkboxContainsName
      ];
      return checks.filter(Boolean).length * 15;
    },
    progressPassword2() {
      let progress = 0;
      if (this.checkboxPassword2) {
        progress = 100;
      }
      return progress;
    },
    enableSetPassword() {
      return (
        this.checkboxLength &&
        this.checkboxNumber &&
        this.checkboxNotCapital &&
        this.checkboxCapital &&
        this.checkboxSpecialChar &&
        this.checkboxConsecutives &&
        this.checkboxContainsName &&
        this.checkboxPassword2
      );
    }
  },
  methods: {
    confirmChangePsw() {
      this.dialogConfirm = true;
      this.showMessageOK = false;
      this.showMessageNOK = false;
    },
    changePassword() {
      this.loading = true;
      this.axios({
        method: "POST",
        url: "/user/changePassword",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        data: {
          company: this.company.id,
          old_password: this.oldPsw,
          new_password: this.newPsw1,
          confirm_password: this.newPsw2
        }
      }).then(resp => {
        console.log(resp.data);
        if (!resp.data.Error) {
          this.showMessageOK = true;
        } else {
          this.showMessageNOK = true;
        }
        this.loading = false;
      });
    }
  }
};
</script>
<style scoped>
.v-input--selection-controls {
  margin: 0px;
}
</style>
