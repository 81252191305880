<template>
  <v-container>
    <v-btn icon @click="openDialog()">
      <v-icon color="primary">mdi-cellphone-key</v-icon>
    </v-btn>
    <v-dialog v-model="dialog2fa" width="500">
        <v-card>
          <v-card-title>
            <h4 :style="{ color: $vuetify.theme.themes.light.secondary }" class="mb-5">
              {{ textApp.label00526 }}
            </h4>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
                <div>
                  <v-switch
                    v-model="enabled2fa"
                    :label="textApp.label00527"
                  ></v-switch>
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog2fa = false">
              {{textApp.label00012}}
            </v-btn>
            <v-btn color="primary" text @click="save()">
              {{textApp.label00013}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      dialog2fa: false,
      loading: true,
      enabled2fa: false
    };
  },
  props: ["target_company", "base_company_l2", "user", "adminLevel"],
  mounted() {},
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
  },
  methods: {
    loadData() {
      let data = {};
      switch (this.adminLevel) {
        case "L1":
          data = {
            target_usr_company: this.target_company,
            target_usr_id: this.user.id
          };
          break;
        case "L2":
          data = {
            company: this.base_company_l2.id,
            target_company: this.target_company,
            target_usr_id: this.user.id
          };
          break;
        case "L3":
          data = {
            company: this.target_company,
            target_usr_id: this.user.id,
          };
          break;
      }
      this.axios({
        method: "GET",
        url: `/admin/${this.adminLevel}/user/is_enabled2fa`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        params: data
      }).then(resp => {
        console.log(resp.data);
        if (!resp.data.Error) {
          this.enabled2fa = resp.data;
        }
      });
    },
    openDialog() {
      this.dialog2fa = true;
    },
    save() {
      let data = {};
      switch (this.adminLevel) {
        case "L1":
          data = {
            target_usr_id: this.user.id,
            target_usr_company: this.target_company,
            enabled2fa: this.enabled2fa
          };
          break;
        case "L2":
          data = {
            company: this.base_company_l2.id,
            target_company: this.target_company,
            target_usr_id: this.user.id,
            enabled2fa: this.enabled2fa
          };
          break;
        case "L3":
          data = {
            company: this.target_company,
            target_usr_id: this.user.id,
            enabled2fa: this.enabled2fa
          };
          break;
      }
      this.axios({
        method: "POST",
        url: `/admin/${this.adminLevel}/user/enable2fa`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        data: data
      }).then(resp => {
        console.log(resp.data);
        if (!resp.data.Error) {
          this.dialog2fa = false;
        }
      });
    }
  },
  watch: {
    dialog2fa () {
      if(this.dialog2fa) {
        this.loadData();
      }
    }
  }
};
</script>
<style scoped></style>
