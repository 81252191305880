<template>
  <v-container>
    <v-row align="start" justify="start" class="pl-3">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="ml-0"
            color="secondary"
            small
            @click="goToInventoryData()"
          >
            {{textApp.label00098}}
          </v-btn>
        </template>
        <span>{{textApp.label00099}}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="ml-3"
            color="secondary"
            small
            @click="goToComparison()"
          >
            {{textApp.label00100}}
          </v-btn>
        </template>
        <span>{{textApp.label00100}}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="ml-3"
            color="secondary"
            small
            @click="goToVulnerability()"
          >
            {{textApp.label00402}}
          </v-btn>
        </template>
        <span>{{textApp.label00525}}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="module_rmm">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="ml-3"
            color="secondary"
            small
            @click="goToCommand()"
          >
            {{textApp.label00419}}
          </v-btn>
        </template>
        <span>{{textApp.label00420}}</span>
      </v-tooltip>
    </v-row>
    <v-row align="start" justify="start">
      <v-col cols="12" lg="4" md="4" sm="12">
        <doughnut-template
          :title="textApp.label00102"
          linkAPI="/charts/agents/actions"
          heightStyle="350px"
          :agent="agent"
        ></doughnut-template>
      </v-col>
      <v-col cols="12" lg="8" md="8" sm="12">
        <bar-template
          :title="textApp.label00103"
          linkAPI="/charts/categ/tags"
          :agent="agent"
        ></bar-template>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="12">
        <doughnut-template
          :title="textApp.label00104"
          linkAPI="/charts/generic/rid"
          colorsSet="colorsChartRID"
          :agent="agent"
        ></doughnut-template>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="12">
        <doughnut-template
          :title="textApp.label00105"
          linkAPI="/charts/generic/tags"
          colorsSet="colorsChartCategDirigente"
          :agent="agent"
        ></doughnut-template>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <line-template
          :title="textApp.label00106"
          label="Eventi"
          linkAPI="/charts/generic/events"
          colorsSet="colorsChartLineDirigente"
          :agent="agent"
        ></line-template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DoughnutTemplate from "../components/DoughnutTemplate";
import BarTemplate from "../components/BarTemplate";
import LineTemplate from "../components/LineTemplate";

export default {
  components: {
    LineTemplate,
    DoughnutTemplate,
    BarTemplate
  },
  data() {
    return {
      listAgents: []
    };
  },
  mounted() {},
  methods: {
    goToInventoryData() {
      this.$router.push(`/agentdetail/${this.agent}/inventory`);
    },
    goToComparison() {
      this.$router.push(`/agentdetail/${this.agent}/comparison`);
    },
    goToVulnerability() {
      this.$router.push(`/agentdetail/${this.agent}/vulnerability`);
    },
    goToCommand() {
      this.$router.push(`/agentdetail/${this.agent}/listcommands`);
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      }
    },
    agent() {
      return this.$route.params.idAgent;
    },
    token() {
      return this.$store.getters.getToken;
    },
    module_rmm: {
      get: function() {
        return this.$store.getters.getModuleRMM;
      }
    },
  }
};
</script>
<style scoped></style>
