export const reg = {
  state: {
    registrated: false
  },
  getters: {
    isRegistrated: state => state.registrated
  },
  mutations: {
    setRegistrated(state) {
      state.registrated = true;
    },
    setNotRegistrated(state) {
      state.registrated = false;
    }
  },
  actions: {
    setRegistrated(context) {
      context.commit("setRegistrated");
    },
    setNotRegistrated(context) {
      context.commit("setNotRegistrated");
    }
  }
};
