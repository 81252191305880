<template>
  <v-container class="pr-5 pl-5">
    <v-row class="ml-5 mr-5">
      <AdminNewCompany></AdminNewCompany>
      <AdminNewUser
        :listCompany="listCompany"
      ></AdminNewUser>
      <AdminNewCompanyAssignment
        :listCompany="listCompany"
      ></AdminNewCompanyAssignment>
      <AdminCompanyProviderCustomer
        :baseCompanySelected="baseCompanySelected"
      ></AdminCompanyProviderCustomer>
      <AdminAssignCompany
        :listCompany="listCompany"
        :listUser="listUserComplete"
      ></AdminAssignCompany>
    </v-row>
    <v-row class="ml-5 mr-5">
      <v-row v-if="!loading">
        <v-col class="pb-0 pt-0">
          <v-select
            v-model="baseCompanySelected"
            :label="textApp.label00014"
            :items="listCompany"
            item-text="name"
            item-value="id"
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row v-if="loading" align="center" justify="center">
        <v-progress-circular
          :size="120"
          :width="10"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-row>
      <AdminUserList
        :refreshPage="counterUpdate"
        :adminLevel="adminLevel"
        :baseCompanySelected="baseCompanySelected"
        :listCompanyProviderL2="listCompanyProviderL2"
      ></AdminUserList>
    </v-row>
  </v-container>
</template>

<script>
import AdminUserList from "../components/AdminUserList";
import AdminNewUser from "../components/AdminNewUser";
import AdminNewCompany from "../components/AdminNewCompany";
import AdminNewCompanyAssignment from "../components/AdminNewCompanyAssignment";
import AdminCompanyProviderCustomer from "../components/AdminCompanyProviderCustomer";
import AdminAssignCompany from "../components/AdminAssignCompany";
export default {
  components: {
    AdminUserList,
    AdminNewUser,
    AdminNewCompany,
    AdminNewCompanyAssignment,
    AdminCompanyProviderCustomer,
    AdminAssignCompany
  },
  data() {
    return {
      listUserComplete: [],
      listCompany: [],
      listCompanyProviderL2: [],
      baseCompanySelected: null,
      adminLevel: "L1",
      loading: true,
      companyAdminSelected: null,
      counterUpdate: 0
    };
  },
  mounted() {
    this.axios({
      method: "GET",
      url: "/admin/L1/company/get_all",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`
      }
    }).then(resp => {
      console.log(resp.data);
      if (!resp.data.Error) {
        let temp = [];
        resp.data.forEach(company => {
          let obj = {};
          obj["name"] = `${company.id_company} - ${company.name_company}`;
          obj["id"] = company.id_company;
          temp.push(obj);
        });
        this.listCompany = temp;
        this.loading = false;
      }
    });
    this.axios({
      method: "GET",
      url: "/admin/L1/user/get_all",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`
      }
    }).then(resp => {
      console.log(resp.data);
      if (!resp.data.Error) {
        let temp = [];
        resp.data.forEach(user => {
          let obj = {};
          obj["name"] = `${user.id} - ${user.name} ${user.surname}`;
          obj["id"] = user.id;
          temp.push(obj);
        });
        this.listUserComplete = temp;
        this.loading = false;
      }
    });
  },
  methods: {
    refreshPage() {
      this.counterUpdate++;
      this.userToCreate = {
        name: null,
        surname: null,
        email: null,
        password: null,
        role: null
      };
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      }
    },
    token() {
      return this.$store.getters.getToken;
    },
  },
  watch: {
    baseCompanySelected() {
      this.listCompanyProviderL2 = [];
      this.axios({
        method: "GET",
        url: `/admin/${this.adminLevel}/company/customerCompanyList`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        params: {
          target_provider_company: this.baseCompanySelected
        }
      }).then(resp => {
        console.log(resp.data);
        this.listCompanyProviderL2 = resp.data;
      });
    }
  }
};
</script>
<style scoped></style>
