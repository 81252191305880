<template>
  <v-container>
    <v-dialog v-model="dialog" width="700">
      <template #activator="{ on: dialog }">
        <v-tooltip bottom>
          <template #activator="{ on: tooltip }">
            <v-btn v-on="{ ...tooltip, ...dialog }" icon color="primary">
              <v-icon>mdi-arrow-right-bold</v-icon>
            </v-btn>
          </template>
          <span>{{textApp.label00161}}</span>
        </v-tooltip>
      </template>

      <v-card>
        <v-card-title>
          <h4 :style="{ color: $vuetify.theme.themes.light.secondary }">{{textApp.label00155}}</h4>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-select
                v-model="receivers"
                :items="listUser"
                :label="textApp.label00162"
                item-text="name"
                item-value="id"
                multiple
              ></v-select>
            </v-col>
            <v-col cols="12" md="12" class="pt-0">
              <v-textarea
                outlined
                name="input-7-4"
                :label="textApp.label00163"
                v-model="text"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()"> {{textApp.label00164}} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      receivers: [],
      listUser: [],
      text: null
    };
  },
  props: ["warning"],
  mounted() {},
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    credentials: {
      get: function() {
        return this.$store.getters.getCredentials;
      },
      set: function(value) {
        this.$store.dispatch("setCredentials", value);
      }
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    }
  },
  methods: {
    closeDialog() {
      this.axios({
        method: "POST",
        url: "/warnings/newResponse",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        data: {
          id_warning: this.warning.id,
          txt: this.text,
          company: this.company.id
        }
      }).then(resp => {
        console.log(resp.data);
        this.dialog = false;
      });
    }
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.axios({
          method: "GET",
          url: "/warnings/receivers",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.token}`
          },
          params: {
            user: this.credentials.id,
            company: this.company.id
          }
        }).then(resp => {
          console.log(resp.data);
          if (!resp.data.Error) {
            resp.data.forEach(user => {
              this.listUser.push({
                name: user.name + " " + user.surname,
                id: user.id
              });
            });
          }
        });
      }
    }
  }
};
</script>
<style scoped></style>
