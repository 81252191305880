<template>
  <v-container class="pr-5 pl-5">
    <v-btn-toggle
      v-model="granularityCompanySelected"
      color="primary"
      mandatory
      class="mb-5"
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            icon 
            value="alert"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-alert-decagram-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ textApp.label00451 }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            icon 
            value="data"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-database-alert-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ textApp.label00452 }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            icon 
            value="empty"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-crop-free</v-icon>
          </v-btn>
        </template>
        <span>{{ textApp.label00453 }}</span>
      </v-tooltip>
    </v-btn-toggle>
    <div v-if="!loading_text">
      <v-row align="start" justify="start">
        <v-col cols="12" md="12">
          <v-card outlined>
            <v-card-text>
              <h4>{{ textApp.label00079 }}</h4>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="!loading && granularityCompanySelected == 'alert'" align="start" justify="start">
        <v-col
          cols="12"
          md="6"
          lg="4"
          xl="3"
          sm="12"
          v-for="(comp, i) in listCompany"
          :key="i"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-card
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  :elevation="hover ? 3 : 0"
                  @click="selectCompany(comp)"
                >
                  <v-card-text>
                    <h3>{{ comp.name }}</h3>
                    <v-divider class="mt-3 mb-3"></v-divider>
                    <h4>{{ textApp.label00431 }}</h4>
                    <div v-for="(role, j) in comp.role" :key="j">
                      - {{ role.name }}
                    </div>
                    <v-divider class="mt-3 mb-3"></v-divider>
                    <v-chip
                      color="red"
                      label
                      text-color="white"
                    >
                      <v-icon start icon="mdi-home"></v-icon>
                      {{ textApp.label00454 }} &nbsp; <b>{{ comp.n_alert }}</b>
                    </v-chip>
                  </v-card-text>
                </v-card>
              </v-hover>
            </template>
            <span>{{ textApp.label00081 }} {{ comp.name }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row v-if="!loading && granularityCompanySelected == 'empty'" align="start" justify="start">
        <v-col
          cols="12"
          md="6"
          lg="4"
          xl="3"
          sm="12"
          v-for="(comp, i) in listCompany"
          :key="i"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-card
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  :elevation="hover ? 3 : 0"
                  @click="selectCompany(comp)"
                >
                  <v-card-text>
                    <h3>{{ comp.name }}</h3>
                    <v-divider class="mt-3 mb-1"></v-divider>
                    <h4>{{ textApp.label00431 }}</h4>
                    <div v-for="(role, j) in comp.role" :key="j">
                      - {{ role.name }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-hover>
            </template>
            <span>{{ textApp.label00081 }} {{ comp.name }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row v-if="!loading && granularityCompanySelected == 'data'" align="start" justify="start">
        <v-col
          cols="12"
          md="6"
          lg="4"
          xl="3"
          sm="12"
          v-for="(comp, i) in listCompany"
          :key="i"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-card
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  :elevation="hover ? 3 : 0"
                  @click="selectCompany(comp)"
                >
                  <v-card-text>
                    <h3>{{ comp.name }}</h3>
                    <v-divider class="mt-3 mb-3"></v-divider>
                    <h4>{{ textApp.label00431 }}</h4>
                    <div v-for="(role, j) in comp.role" :key="j">
                      - {{ role.name }}
                    </div>
                    <v-divider class="mt-3 mb-3"></v-divider>
                    <div v-if="!comp.alerts_error">
                      <h4>{{ textApp.label00432 }}</h4>
                      <div
                        v-for="k in maxRolesCompanies - comp.role.length"
                        :key="k"
                      >
                        <br />
                      </div>
                      <v-row class="pa-3" v-if="!comp.alert_error">
                        <v-divider class="mt-3 mb-3"></v-divider>
                        <SeverityLevelList
                          :severity_levels="comp.alerts"
                          class="mb-5"
                        ></SeverityLevelList>
                      </v-row>
                      <div v-if="comp.alert_error" class="mb-5">
                        {{ textApp.label00080 }}
                      </div>
                      <v-divider class="mt-3 mb-3"></v-divider>
                      <div
                        v-if="
                          !comp.alert_error && comp.vulnerabilities.length == 0
                        "
                      >
                        {{ textApp.label00433 }}
                      </div>
                      <div
                        v-if="
                          !comp.alert_error && comp.vulnerabilities.length > 0
                        "
                      >
                        <h4>{{ textApp.label00402 }}</h4>
                        <div v-for="(vuln, k) in comp.vulnerabilities" :key="k">
                          <span
                            :style="{ color: colorSeverity(vuln.severity) }"
                            >{{
                              getLabelSeverity(vuln.severity, vuln.programs)
                            }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-hover>
            </template>
            <span>{{ textApp.label00081 }} {{ comp.name }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row v-if="loading" align="center" justify="center" class="mt-5">
        <v-progress-circular
          :size="120"
          :width="10"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-row>
    </div>
    <div v-else>
      <v-row align="center" justify="center" class="mt-5">
        <v-progress-circular
          :size="120"
          :width="10"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import SeverityLevelList from "../components/SeverityLevelList";
export default {
  components: {
    SeverityLevelList
  },
  data() {
    return {
      loading: false,
      loading_text: false
    };
  },
  mounted() {
    switch(this.granularityCompanySelected) {
      case "alert":
        this.loadDataGranularityAlert();
        break;
      case "data":
        this.loadDataGranularityData();
        break;
      case "empty":
        this.loadDataGranularityEmpty();
        break;
    }
  },
  methods: {
    loadDataGranularityData() {
      this.loading = true;
      this.axios({
        method: "GET",
        url: "/user/companiesComplete",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token //`Bearer ${this.token}`
        }
      }).then(resp => {
        console.log(resp.data);
        this.loading = false;
        if (!resp.data.Error) {
          let temp = [];
          resp.data.forEach(company => {
            let obj = {};
            obj["name"] = company.name_company;
            obj["id"] = company.id_company;
            obj["L2_permissions"] = company.L2_permissions;
            obj["L3_permissions"] = company.L3_permissions;
            obj["role"] = [];
            company.roles.forEach(r => {
              obj["role"].push({ name: r.role_name, id: r.id_role });
            });
            obj["module_rmm"] = company.module_rmm;
            obj["module_soc"] = company.module_soc;
            obj["alerts_error"] = company.semaphore.error;
            obj["alerts"] = [];
            if (!company.semaphore.error) {
              company.semaphore.data.severity_levels.forEach(s => {
                obj["alerts"].push({
                  count: s.count,
                  severity_level: s.severity_level
                });
              });
              obj["vulnerabilities"] = [];
              if (company.semaphore.data.vulnerabilities != undefined && company.semaphore.data.vulnerabilities != null) {
                obj["vulnerabilities"] = company.semaphore.data.vulnerabilities;
                let order_vuln = ['Critical', 'High', 'Medium', 'Low'];
                obj.vulnerabilities.sort(
                  function(a, b){
                    if(a.severity == b.severity){
                      return a.severity.localeCompare(b.severity); 
                    }else{
                      return order_vuln.indexOf(a.severity) - order_vuln.indexOf(b.severity);
                    }
                  }
                );
              }
            }
            temp.push(obj);
          });
          this.listCompany = temp;
        }
      });
    },
    loadDataGranularityAlert() {
      this.loading = true;
      this.axios({
        method: "GET",
        url: "/user/companiesAlert",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token //`Bearer ${this.token}`
        }
      }).then(resp => {
        console.log(resp.data);
        this.loading = false;
        if (!resp.data.Error) {
          let temp = [];
          resp.data.forEach(company => {
            let obj = {};
            obj["name"] = company.name_company;
            obj["id"] = company.id_company;
            obj["L2_permissions"] = company.L2_permissions;
            obj["L3_permissions"] = company.L3_permissions;
            obj["module_rmm"] = company.module_rmm;
            obj["module_soc"] = company.module_soc;
            obj["role"] = [];
            company.roles.forEach(r => {
              obj["role"].push({ name: r.role_name, id: r.id_role });
            });
            obj['n_alert'] = company.n_alerts;
            temp.push(obj);
          });
          this.listCompany = temp;
        }
      });
    },
    loadDataGranularityEmpty() {
      this.loading = true;
      this.axios({
        method: "GET",
        url: "/user/companies",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token //`Bearer ${this.token}`
        }
      }).then(resp => {
        console.log(resp.data);
        this.loading = false;
        if (!resp.data.Error) {
          let temp = [];
          resp.data.forEach(company => {
            let obj = {};
            obj["name"] = company.name_company;
            obj["id"] = company.id_company;
            obj["L2_permissions"] = company.L2_permissions;
            obj["L3_permissions"] = company.L3_permissions;
            obj["role"] = [];
            company.roles.forEach(r => {
              obj["role"].push({ name: r.role_name, id: r.id_role });
            });
            temp.push(obj);
          });
          this.listCompany = temp;
        }
      });
    },
    loadDataText() {
      this.loading_text = true;
      this.axios({
        method: "GET",
        url: "/company/dataText",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        params: {
          company: this.company.id,
        },
      }).then((resp) => {
        console.log(resp.data);
        this.dataLabel = resp.data;
        this.$router.push("/home");
      });
    },
    selectCompany(comp) {
      this.company = {
        id: comp.id,
        name: comp.name
      };
      this.role = comp.role;
      this.L2Permission = comp.L2_permissions;
      this.L3Permission = comp.L3_permissions;
      this.module_rmm = comp.module_rmm;
      this.module_soc = comp.module_soc;

      this.$store.dispatch("resetFilterState");

      this.loadDataText();
    },
    getLabelSeverity(severity, programs) {
      switch (severity) {
        case "Critical":
          return `${this.textApp.label00227}: ${programs} ${this.textApp.label00434}`;
        case "High":
          return `${this.textApp.label00228}: ${programs} ${this.textApp.label00434}`;
        case "Medium":
          return `${this.textApp.label00229}: ${programs} ${this.textApp.label00434}`;
        case "Low":
          return `${this.textApp.label00230}: ${programs} ${this.textApp.label00434}`;
        default:
          return this.textApp.label00433;
      }
    },
    colorSeverity(severity) {
      switch (severity) {
        case "Critical":
          return this.$vuetify.theme.themes.light.levelCritical;
        case "High":
          return this.$vuetify.theme.themes.light.levelHigh;
        case "Medium":
          return this.$vuetify.theme.themes.light.levelMedium;
        case "Low":
          return this.$vuetify.theme.themes.light.levelLow;
        default:
          return "#000";
      }
    }
  },
  watch: {
    granularityCompanySelected() {
      switch(this.granularityCompanySelected) {
        case "alert":
          this.loadDataGranularityAlert();
          break;
        case "data":
          this.loadDataGranularityData();
          break; 
        case "empty":
          this.loadDataGranularityEmpty();
          break;
      }
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      }
    },
    dataLabel: {
      get: function() {
        return this.$store.getters.getDataLabel;
      },
      set: function(value) {
        this.$store.dispatch("setDataLabel", value);
      }
    },
    token() {
      return this.$store.getters.getToken;
    },
    credentials: {
      get: function() {
        return this.$store.getters.getCredentials;
      },
      set: function(value) {
        this.$store.dispatch("setCredentials", value);
      }
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
    role: {
      get: function() {
        return this.$store.getters.getRole;
      },
      set: function(value) {
        this.$store.dispatch("setRole", value);
      }
    },
    L2Permission: {
      get: function() {
        return this.$store.getters.getL2Permission;
      },
      set: function(value) {
        this.$store.dispatch("setL2Permission", value);
      }
    },
    L3Permission: {
      get: function() {
        return this.$store.getters.getL3Permission;
      },
      set: function(value) {
        this.$store.dispatch("setL3Permission", value);
      }
    },
    module_rmm: {
      get: function() {
        return this.$store.getters.getModuleRMM;
      },
      set: function(value) {
        this.$store.dispatch("setModuleRMM", value);
      }
    },
    module_soc: {
      get: function() {
        return this.$store.getters.getModuleSOC;
      },
      set: function(value) {
        this.$store.dispatch("setModuleSOC", value);
      }
    },
    granularityCompanySelected: {
      get: function() {
        return this.$store.getters.getGranularityCompanySelected;
      },
      set: function(value) {
        this.$store.dispatch("setGranularityCompanySelected", value);
      }
    },
    listCompany: {
      get: function() {
        return this.$store.getters.getListCompany;
      },
      set: function(value) {
        this.$store.dispatch("setListCompany", value);
      }
    },
    maxRolesCompanies() {
      let max = 0;
      this.listCompany.forEach(c => {
        if (max < c.role.length) {
          max = c.role.length;
        }
      });
      return max;
    }
  }
};
</script>
<style scoped></style>
