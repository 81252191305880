<template>
  <v-container class="pa-0">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-hover v-slot="{ hover }">
          <v-card
            v-bind="attrs"
            v-on="on"
            outlined
            :elevation="hover ? 3 : 0"
            @click="$router.push('/warning')"
          >
            <v-card-text v-if="!loading" :style="{ height: heightStyle }">
              <h4
                class="mt-2"
                :style="{ color: $vuetify.theme.themes.light.secondary }"
              >
                {{textApp.label00174}}
              </h4>
              <v-divider class="mt-3 mb-3"></v-divider>
              <div>{{textApp.label00196}} {{ warningTotalCount }}</div>
              <div>{{textApp.label00197}} {{ warningOpenCount }}</div>
            </v-card-text>
            <v-card-text v-else :style="{ height: heightStyle }">
              <v-row class="mt-5" align="center" justify="center">
                <v-progress-circular
                  :size="120"
                  :width="10"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-row>
            </v-card-text>
          </v-card>
        </v-hover>
      </template>
      <span>{{textApp.label00198}}</span>
    </v-tooltip>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      listWarning: [],
      loading: false
    };
  },
  props: {
    heightStyle: {
      type: String,
      default: "270px"
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      /*this.axios({
        method: "GET",
        url: "/agents/mitre",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        params: {
          company: this.company.id,
          agent: "007"
        }
      }).then(resp => {
        console.log(resp.data);
      });*/
      this.axios({
        method: "GET",
        url: "/warnings/list",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        params: {
          company: this.company.id
        }
      }).then(resp => {
        console.log(resp.data);
        this.listWarning = resp.data;
        this.loading = false;
      });
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
    warningTotalCount() {
      return this.listWarning.length;
    },
    warningOpenCount() {
      return this.listWarning.filter(obj => {
        if (obj.id_status == 1) {
          return true;
        }
        return false;
      }).length;
    }
  }
};
</script>
<style scoped></style>
