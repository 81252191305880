<template>
  <v-container class="pr-5 pl-5">
    <v-row align="start" justify="start">
      <v-col cols="12" md="12">
        <v-card outlined>
          <v-card-text>
            <h4>{{ textApp.label00489 }}</h4>
          </v-card-text>
        </v-card>
        <v-stepper
          class="mt-2"
          v-model="stepper"
          vertical
          outlined
        >
          <v-stepper-step
            :complete="stepper > 1"
            step="1"
          >
            {{ textApp.label00490 }}
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-card
              class="mb-12 pa-3 elevation-0"
              height="150px"
            >
            <v-card-text v-if="loading_agent" style="padding-top: 50px; padding-bottom: 50px">
              <v-row align="center" justify="center">
                <v-progress-circular
                  :size="120"
                  :width="10"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-row>
            </v-card-text>
              <v-autocomplete
                v-else
                v-model="warning_selected"
                :label="textApp.label00168"
                :items="warning_list"
                item-text="str_id"
                item-value="id"
                outlined
                clearable
              ></v-autocomplete>
            </v-card>
            <v-btn
              :disabled="warning_selected.length == 0"
              color="primary"
              @click="stepper = 2"
            >
              {{ textApp.label00304 }}
            </v-btn>
          </v-stepper-content>

          <v-stepper-step
            :complete="stepper > 2"
            step="2"
          >
            {{ textApp.label00491 }}
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-card
              class="mb-12 pa-5 elevation-0"
              height="150px"
            >
              <p style="margin-bottom: 50px;">{{ textApp.label00492 }}</p>
              <v-slider
                class="mt-5"
                v-model="days_slider"
                :max="10"
                :min="1"
                ticks="always"
                tick-size="6"
                thumb-label="always"
              ></v-slider>
              
            </v-card>
            <v-btn
              :loading="loading_btn"
              :disabled="warning_selected.length == 0"
              color="primary"
              @click="countResults"
            >
              {{ textApp.label00304 }}
            </v-btn>
            <v-btn 
              text
              @click="stepper = 1"
            >
              {{ textApp.label00314 }}
            </v-btn>
          </v-stepper-content>

          <v-stepper-step 
            step="3"
            :complete="stepper > 3"
          >
            {{ textApp.label00464 }}
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-card
              class="mb-12 elevation-0"
              height="150px"
            >
              <v-card-text v-if="stepper == 3">
                <h3 class="mb-2">{{ textApp.label00465 }}</h3>

                <table v-if="summary_data != null">
                  <tr>
                    <td>
                      {{ textApp.label00168 }}
                    </td>
                    <td>
                      <b>{{ warning_selected }} - {{ summary_data.warnings.title }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ textApp.label00493 }}
                    </td>
                    <td>
                      <b>{{ ftimezone.getLocalDateStr(summary_data.dates.startDate) }}</b> - <b>{{ ftimezone.getLocalDateStr(summary_data.dates.endDate) }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ textApp.label00494 }}
                    </td>
                    <td>
                      <b>{{ ftimezone.getLocalDateStr(summary_data.dates.newStartDate) }}</b> - <b>{{ ftimezone.getLocalDateStr(summary_data.dates.newEndDate) }}</b>
                    </td>
                  </tr>
                </table>
              </v-card-text>
              
              <v-row v-if="loading" align="center" justify="center">
                <v-progress-circular
                  :size="120"
                  :width="10"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-row>
            </v-card>
            <v-btn
              :disabled="!ready_generation"
              class="mt-5"
              color="primary"
              :loading="loading_btn"
              @click="generateReport"
            >
              {{ textApp.label00464 }}
            </v-btn>
            <v-btn 
              class="mt-5"
              text
              @click="stepper = 2"
            >
              {{ textApp.label00314 }}
            </v-btn>
          </v-stepper-content>
          <v-btn
            v-if="stepper > 3"
            class="mt-10 ml-5"
            color="primary"
            @click="stepper = 1"
          >
            {{ textApp.label00471 }}
          </v-btn>
        </v-stepper>
        <!--<v-card outlined class="mt-3" v-if="report_data != null" >
          <v-card-text>
            <v-btn
              class="mr-1"
              color="secondary"
              dark
              @click="dialog = true;"
            >
              {{ textApp.label00472 }}
            </v-btn>
            <v-btn 
              class="mr-1"
              color="secondary"
              dark  
              @click="exportDataCateg"
            >
              Categ
            </v-btn>
            <v-btn 
              class="mr-1"
              color="secondary"
              dark  
              @click="exportDataNewCateg"
            >
              New Categ
            </v-btn>
          </v-card-text>
        </v-card>-->
        <v-card outlined class="mt-3" v-if="report_data != null" >
          <v-card-text>
            <v-row v-if="'categ_tags' in report_data" class="mt-3 mb-5">
              <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
                <StatsPercentage
                  :data="percentageCategTags"
                ></StatsPercentage>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="mt-5 pb-0 pt-0">
                <bar-template
                  :title="textApp.label00103"
                  colorsSet="colorsChartCategDPO"
                  :data="report_data.categ_tags"
                ></bar-template>
                <bar-template
                  class="mt-3"
                  :title="textApp.label00103"
                  colorsSet="colorsChartCategDPO"
                  :data="report_data.new_categ_tags"
                ></bar-template>
              </v-col>
            </v-row>
            <v-row v-else class="mt-3 mb-5">
              <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
                <StatsPercentage
                  class="mt-3"
                  :data="percentageGenericRid"
                ></StatsPercentage>
              </v-col>
              <v-col cols="12" md="6" sm="12" class="pb-0 pt-0">
                <doughnut-template
                  class="mt-3"
                  :title="textApp.label00103"
                  colorsSet="colorsChartRID"
                  :data="report_data.generic_rid"
                ></doughnut-template>
              </v-col>
              <v-col cols="12" md="6" sm="12" class="pb-0 pt-0">
                <doughnut-template
                  class="mt-3"
                  :title="textApp.label00103"
                  colorsSet="colorsChartRID"
                  :data="report_data.new_generic_rid"
                ></doughnut-template>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
                <StatsPercentage
                  class="mt-3"
                  :data="percentageGenericTags"
                ></StatsPercentage>
              </v-col>
              <v-col cols="12" md="6" sm="12" class="pb-0 pt-0">
                <doughnut-template
                  class="mt-3"
                  :title="textApp.label00103"
                  colorsSet="colorsChartCategDirigente"
                  :data="report_data.generic_tags"
                ></doughnut-template>
              </v-col>
              <v-col cols="12" md="6" sm="12" class="pb-0 pt-0">
                <doughnut-template
                  class="mt-3"
                  :title="textApp.label00103"
                  colorsSet="colorsChartCategDirigente"
                  :data="report_data.new_generic_tags"
                ></doughnut-template>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <!-- Anteprima -->
        <!--<v-dialog
          v-model="dialog"
          scrollable
          width="800"
        >
          <v-card>
            <v-card-title>
              <h4 :style="{ color: $vuetify.theme.themes.light.secondary }">{{ textApp.label00474 }}</h4>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text>
              <v-row class="mt-3 mb-5" v-if="report_data != null" id="content-pdf">
                <v-col cols="12" md="12" class="mt-2 mb-3">
                  <h2 :style="{ color: $vuetify.theme.themes.light.secondary }">{{ textApp.label00475 }}</h2>
                </v-col>
                <v-col cols="12" md="12" class="pb-0 pt-0">
                  <bar-template
                    :title="textApp.label00103"
                    colorsSet="colorsChartCategDPO"
                    :data="report_data.categ_tags"
                  ></bar-template>
                  <bar-template
                    class="mt-3"
                    :title="textApp.label00103"
                    colorsSet="colorsChartCategDPO"
                    :data="report_data.new_categ_tags"
                  ></bar-template>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="exportToPDF"
              >
                {{ textApp.label00482 }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import timezone from "../utils/timezone.js"
//import html2pdf from "html2pdf.js";
//import exportFromJSON from "export-from-json";
import BarTemplate from "../components/BarTemplate";
import DoughnutTemplate from "../components/DoughnutTemplate";
import StatsPercentage from "../components/StatsPercentage";
export default {
  components: {
    BarTemplate,
    DoughnutTemplate,
    StatsPercentage
  },
  data() {
    return {
      loading_agent: true,
      warning_list: [],
      warning_selected: [],
      days_slider: 1, 
      stepper: 1,
      loading_btn: false,
      ready_generation: false,
      loading: false,
      summary_data: null,
      report_data: null,
      dialog: false,
      ftimezone: null
    };
  },
  mounted() {  
    this.ftimezone = timezone;  
    this.axios({
      method: "GET",
      url: "/warnings/list",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      params: {
        company: this.company.id,
      },
    }).then((resp) => {
      console.log(resp.data);
      if (!resp.data.Error) {
        resp.data.forEach((w, i) => {
          resp.data[i]['str_id'] = `${w.id} - ${w.title}`;
        });
        this.warning_list = resp.data;
        this.loading_agent = false;
      }
    });
  },
  methods: {
    countResults() {
      this.ready_generation = false;
      this.loading_btn = true;
      this.axios({
        method: "POST",
        url: "/reports/warning/data",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          company: this.company.id,
          warning: this.warning_selected,
          days: this.days_slider
        },
      }).then((resp) => {
        console.log(resp.data);
        this.loading_btn = false;
        this.stepper = 3;
        this.ready_generation = true;
        this.summary_data = resp.data.data;

        if('categ_tags' in this.summary_data) {
          this.computeData('categ_tags');
          this.computeData('new_categ_tags');
        } else {
          this.computeData('generic_rid');
          this.computeData('new_generic_rid');
          this.computeData('generic_tags');
          this.computeData('new_generic_tags');
        }
        
        console.log(this.summary_data);
      });
    },
    generateReport() {
      console.log(this.report_data);
      console.log(this.summary_data);
      this.report_data = JSON.parse(JSON.stringify(this.summary_data));
      this.stepper = 4;
    },
    computeData(code) {
      this.summary_data[code]["descriptions"] = [];
      this.summary_data[code]["codes"] = [];
      this.summary_data[code].labels.forEach((tag, i) => {
        this.summary_data[code].codes[i] = tag;
        this.summary_data[code].labels[i] = this.dataLabel[tag].name;
        this.summary_data[code].descriptions[i] = this.dataLabel[tag].description;
      });
    }
    /*exportToPDF() {
      let name = this.companySetup.service_name;
      html2pdf()
      .from(document.getElementById("content-pdf"))
        .set({
            margin:       [30, 20, 30, 20],
            filename:     timezone.getLocalDateStr(new Date()).replaceAll(/T/g, "_").replaceAll(/-/g, "").replaceAll(/:/g, ""),
            image:        { type: 'jpeg', quality: 0.98 },
            html2canvas:  { scale: 2, logging: true, dpi: 192, letterRendering: true },
            jsPDF:        { unit: 'mm', format: 'a4', orientation: 'p' },
            pagebreak: { before: '.page-break', avoid: 'table' }
        })
        .toPdf()
        .get('pdf').then(function (pdf) {
          var totalPages = pdf.internal.getNumberOfPages();

          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(14);
            pdf.setTextColor(150);
            pdf.text(15, 15, name);
            pdf.text(pdf.internal.pageSize.getWidth() - 65, 15, timezone.getLocalDateStr(new Date()).replaceAll(/T/g, " "));
            pdf.setFontSize(10);
            pdf.text('SIDNEO ' + i + '/' + totalPages+'', pdf.internal.pageSize.getWidth() - 40, pdf.internal.pageSize.getHeight() - 8);
          } 
      }).save();
    },
    exportDataCateg() {
      this.exportData("categ_tags")
    },
    exportDataNewCateg() {
      this.exportData("new_categ_tags")
    },
    exportData(type) {
      if (!this.report_data) return;
      try {
        let data = this.report_data[type];
        const fileName = timezone.getLocalDateStr(new Date()).replaceAll(/T/g, "_").replaceAll(/-/g, "").replaceAll(/:/g, "");
        const exportType = exportFromJSON.types["csv"];
        exportFromJSON({ data, fileName, exportType });
      } catch (e) {
        throw new Error("Parsing failed!");
      }
    },*/
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      }
    },
    dataLabel: {
      get: function () {
        return this.$store.getters.getDataLabel;
      },
    },
    companySetup: {
      get: function() {
        return this.$store.getters.getCompanySetup;
      }
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
    startDatetime() {
      return `${this.startDate}T${this.startTime}:00`
    },
    endDatetime() {
      return `${this.endDate}T${this.endTime}:00`
    },
    filters() {
      return {
        requirementsSelected: [],
        IDAgentsSelected: [],
        IPAddressSelected: [],
        severitySelected: [],
        descriptionSelected: [],
      }
    },
    startDatetimeStr() {
      return  timezone.getLocalDateStr(this.startDatetime).replaceAll(/T/g, " ");
    },
    endDatetimeStr() {
      return  timezone.getLocalDateStr(this.endDatetime).replaceAll(/T/g, " ");
    },
    percentageCategTags() {
      let perc = [];
      for(let i = 0; i < this.report_data.categ_tags.data.length; i ++) {
        for(let j = 0; j < this.report_data.new_categ_tags.data.length; j ++) {
          if(this.report_data.categ_tags.codes[i] == this.report_data.new_categ_tags.codes[j]) {
            perc.push({
              label: this.report_data.new_categ_tags.labels[j],
              perc: (1 - (this.report_data.new_categ_tags.data[j]/this.report_data.categ_tags.data[i])) * 100
            });
          }
        }
      }
      return perc;
    },
    percentageGenericTags() {
      let perc = [];
      for(let i = 0; i < this.report_data.generic_tags.data.length; i ++) {
        for(let j = 0; j < this.report_data.new_generic_tags.data.length; j ++) {
          if(this.report_data.generic_tags.codes[i] == this.report_data.new_generic_tags.codes[j]) {
            perc.push({
              label: this.report_data.new_generic_tags.labels[j],
              perc: (1 - (this.report_data.new_generic_tags.data[j]/this.report_data.generic_tags.data[i])) * 100
            });
          }
        }
      }
      return perc;
    },
    percentageGenericRid() {
      let perc = [];
      for(let i = 0; i < this.report_data.generic_rid.data.length; i ++) {
        for(let j = 0; j < this.report_data.new_generic_rid.data.length; j ++) {
          if(this.report_data.generic_rid.codes[i] == this.report_data.new_generic_rid.codes[j]) {
            perc.push({
              label: this.report_data.new_generic_rid.labels[j],
              perc: (1 - (this.report_data.new_generic_rid.data[j]/this.report_data.generic_rid.data[i])) * 100
            });
          }
        }
      }
      return perc;
    }
  }
};
</script>
<style scoped>
td {
  padding: 0px 5px 0px 0px;
}
</style>
