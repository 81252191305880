<template>
  <v-container class="pt-0">
    <v-card outlined class="pl-5 pr-5">
      <v-card-text
        ><h4>{{textApp.label00118}} {{ agent }}</h4></v-card-text
      >
      <v-row v-if="list.length > 0" justify="start">
        <v-data-table
          class="ma-0 mb-3 pb-2"
          :headers="headers"
          :items="list"
          :search="search"
          :footer-props="footerProps"
          disable-pagination
          hide-default-footer
        >
          <template v-slot:no-data>
            <v-card-text>
              {{textApp.label00089}}
            </v-card-text>
          </template>
        </v-data-table>
      </v-row>
      <v-row v-if="loading">
        <v-card-text style="padding-top: 50px; padding-bottom: 50px">
          <v-row align="center" justify="center">
            <v-progress-circular
              :size="120"
              :width="10"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </v-card-text>
      </v-row>
    </v-card>
    <ScrollToTop></ScrollToTop>
  </v-container>
</template>

<script>
import ScrollToTop from "./ScrollToTop"
export default {
  components: {
    ScrollToTop
  },
  props: ['data'],
  data() {
    return {
      list: [],
      loading: false,
      search: "",
      headers: [],
      footerProps: null,
    };
  },
  mounted() {
    if(this.data != null) {
      this.list = this.data;
    } else {
      this.loadData();
    }
    this.headers = [
        { text: this.textApp.label00005, value: "name" },
        { text: this.textApp.label00119, value: "format" },
        { text: this.textApp.label00117, value: "architecture" },
        { text: this.textApp.label00120, value: "vendor" },
        { text: this.textApp.label00121, value: "version" }
    ];
    this.footerProps = {
      itemsPerPageText: this.textApp.label00095,
      pageText: `{0}-{1} ${this.textApp.label00096} {2}`,
      "items-per-page-all-text": this.textApp.label00097,
    };
  },
  methods: {
    loadData() {
      this.loading = true;
      this.axios({
        method: "GET",
        url: "/agents/packages",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        params: {
          agent: this.agent,
          company: this.company.id
        }
      }).then(resp => {
        console.log(resp.data);
        if (!resp.data.Error && resp.data.length > 0) {
          this.list = resp.data;
        }
        this.loading = false;
      });
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      }
    },
    agent() {
      return this.$route.params.idAgent;
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    }
  },
  watch: {
    token() {
      this.loadData();
    }
  }
};
</script>
<style scoped></style>
