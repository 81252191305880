import { render, staticRenderFns } from "./AdminL2.vue?vue&type=template&id=a032c238&scoped=true&"
import script from "./AdminL2.vue?vue&type=script&lang=js&"
export * from "./AdminL2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a032c238",
  null
  
)

export default component.exports