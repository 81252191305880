var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0 pb-0 mt-3",attrs:{"id":"filtriDateAgenti"}},[_c('v-row',{attrs:{"align":"start","justify":"end"}},[(_vm.filterGroupEnable)?_c('v-col',{staticClass:"pl-0 pr-0 ml-1",attrs:{"cols":"12","sm":"4","md":"2"}},[_c('v-select',{attrs:{"disabled":_vm.loadingGroups,"items":_vm.listGroup,"label":_vm.textApp.label00069,"outlined":"","dense":"","clearable":""},model:{value:(_vm.groupSelected),callback:function ($$v) {_vm.groupSelected=$$v},expression:"groupSelected"}})],1):_vm._e(),(_vm.filterDatesEnable)?_c('v-col',{staticClass:"pl-0 pr-0 ml-1",attrs:{"cols":"12","sm":"4","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-x":"","max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.textApp.label00070,"append-icon":"mdi-calendar","outlined":"","dense":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}],null,false,3320692314),model:{value:(_vm.menuStartDate),callback:function ($$v) {_vm.menuStartDate=$$v},expression:"menuStartDate"}},[_c('v-card',[_c('v-date-picker',{attrs:{"locale":"it-IT"},model:{value:(_vm.startDatePicker),callback:function ($$v) {_vm.startDatePicker=$$v},expression:"startDatePicker"}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.menuStartDate = false;
                _vm.startDate = null;}}},[_vm._v(" "+_vm._s(_vm.textApp.label00012)+" ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.menuStartDate = false;
                _vm.startDate = _vm.startDatePicker;}}},[_vm._v(" "+_vm._s(_vm.textApp.label00072)+" ")])],1)],1)],1)],1):_vm._e(),(_vm.filterDatesEnable)?_c('v-col',{staticClass:"pl-0 pr-0 ml-1",attrs:{"cols":"12","sm":"4","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-x":"","max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.textApp.label00071,"append-icon":"mdi-calendar","outlined":"","dense":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}],null,false,3001426068),model:{value:(_vm.menuEndDate),callback:function ($$v) {_vm.menuEndDate=$$v},expression:"menuEndDate"}},[_c('v-card',[_c('v-date-picker',{attrs:{"locale":"it"},model:{value:(_vm.endDatePicker),callback:function ($$v) {_vm.endDatePicker=$$v},expression:"endDatePicker"}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.menuEndDate = false;
                _vm.endDate = null;}}},[_vm._v(" "+_vm._s(_vm.textApp.label00012)+" ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.menuEndDate = false;
                _vm.endDate = _vm.endDatePicker;}}},[_vm._v(" "+_vm._s(_vm.textApp.label00072)+" ")])],1)],1)],1)],1):_vm._e(),(_vm.filterDatesEnable)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5 ml-1 mt-3",staticStyle:{"height":"40px"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.setDateFilters()}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.textApp.label00074)+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-cached ")])],1)]}}],null,false,1557142642)},[_c('span',[_vm._v(_vm._s(_vm.textApp.label00073))])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }