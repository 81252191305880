<template>
  <v-breadcrumbs
    :items="itemsBreadcrumbs"
    style="padding: 10px 30px 10px 30px"
  ></v-breadcrumbs>
</template>

<script>
export default {
  data() {
    return {
      itemsBreadcrumbs: [],
    };
  },
  mounted() {
    this.itemsBreadcrumbs = this.convertBreadcrumbsText(this.$route.meta.breadcrumb(this));
  },
  methods: {
    convertBreadcrumbsText(items) {
      items.forEach(item => {
        let code = item.text;
        item.text = this.textApp[code];
      });
      return items;
    }
  },
  watch: {
    $route() {
      this.itemsBreadcrumbs = this.convertBreadcrumbsText(this.$route.meta.breadcrumb(this));
    },
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
  }
};
</script>
<style scoped></style>
