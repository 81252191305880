<template>
  <v-container>
    <v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="6" lg="6">
          <v-row>
            <v-text-field
              v-model="newPsw1"
              outlined
              label="Password"
              :append-icon="showPsw1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPsw1 ? 'text' : 'password'"
              @click:append="showPsw1 = !showPsw1"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-card outlined width="100%">
              <v-card-text class="pb-0">
                <span>{{ textApp.label00287 }}</span>
                <v-progress-linear
                  :value="progressPassword"
                  class="mt-3 mb-4"
                ></v-progress-linear>
                <v-checkbox
                  v-model="checkboxLength"
                  :label="textApp.label00288"
                  disabled
                ></v-checkbox>
                <v-checkbox
                  v-model="checkboxNotCapital"
                  :label="textApp.label00289"
                  disabled
                ></v-checkbox>
                <v-checkbox
                  v-model="checkboxCapital"
                  :label="textApp.label00290"
                  disabled
                ></v-checkbox>
                <v-checkbox
                  v-model="checkboxNumber"
                  :label="textApp.label00291"
                  disabled
                ></v-checkbox>
                <v-checkbox
                  v-model="checkboxSpecialChar"
                  :label="textApp.label00292"
                  disabled
                ></v-checkbox>
                <v-checkbox
                  v-model="checkboxConsecutives"
                  :label="textApp.label00293"
                  disabled
                ></v-checkbox>
              </v-card-text>
            </v-card>
          </v-row>
          <v-row class="mt-5">
            <v-text-field
              v-model="newPsw2"
              outlined
              :append-icon="showPsw2 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.matchPsw]"
              :type="showPsw2 ? 'text' : 'password'"
              :label="textApp.label00295"
              @click:append="showPsw2 = !showPsw2"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-card outlined width="100%">
              <v-card-text class="pb-0">
                <span>{{ textApp.label00296 }}</span>
                <v-progress-linear
                  :value="progressPassword2"
                  class="mt-3 mb-4"
                ></v-progress-linear>
                <v-checkbox
                  v-model="checkboxPassword2"
                  :label="textApp.label00297"
                  disabled
                ></v-checkbox>
              </v-card-text>
            </v-card>
          </v-row>
          <v-row>
            <v-btn
              color="primary"
              @click="confirmChangePsw()"
              class="mt-4"
              :disabled="!enableSetPassword"
            >
              {{ textApp.label00322 }}
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-row>
    <v-dialog v-model="dialogConfirm" width="500">
      <v-card>
        <v-card-title>
          <h4 :style="{ color: $vuetify.theme.themes.light.secondary }">
            {{ textApp.label00323 }}
          </h4>
        </v-card-title>

        <v-card-text v-if="!loading && !showMessageOK && !showMessageNOK">
          {{ textApp.label00324 }}
        </v-card-text>
        <v-card-text
          v-if="loading"
          style="padding-top: 50px; padding-bottom: 50px"
        >
          <v-row align="center" justify="center">
            <v-progress-circular
              :size="120"
              :width="10"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </v-card-text>
        <v-card-text v-if="showMessageNOK">
          {{ textApp.label00325 }}
        </v-card-text>
        <v-card-text v-if="showMessageOK">
          {{ textApp.label00326 }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions v-if="!loading && !showMessageOK && !showMessageNOK">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogConfirm = false">
            {{ textApp.label00012 }}
          </v-btn>
          <v-btn color="primary" @click="changePassword()">
            {{ textApp.label00013 }}
          </v-btn>
        </v-card-actions>

        <v-card-actions v-if="showMessageOK">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="$router.push('/login')">
            {{ textApp.label00042 }}
          </v-btn>
        </v-card-actions>
        <v-card-actions v-if="showMessageNOK">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogConfirm = false">
            {{ textApp.label00042 }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      showPsw1: false,
      showPsw2: false,
      newPsw1: "",
      newPsw2: "",
      rules: {},
      dialogConfirm: false,
      loading: false,
      showMessageOK: false,
      showMessageNOK: false,
    };
  },
  mounted() {
    this.rules = {
      required: (value) => !!value || this.textApp.label00327,
      min: (v) => (v != null && v.length) >= 8 || this.textApp.label00288,
      matchPsw: (v) => v == this.newPsw1 || this.textApp.label00328,
    };
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    checkboxLength() {
      return this.newPsw1.length >= 8;
    },
    checkboxNumber() {
      return /.*[0-9].*/.test(this.newPsw1);
    },
    checkboxNotCapital() {
      return /.*[a-z].*/.test(this.newPsw1);
    },
    checkboxCapital() {
      return /.*[A-Z].*/.test(this.newPsw1);
    },
    checkboxSpecialChar() {
      return /.*[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~].*/.test(this.newPsw1);
    },
    checkboxConsecutives() {
      return this.checkboxLength && !/([a-zA-Z0-9])\1\1+/.test(this.newPsw1);
    },
    checkboxPassword2() {
      return this.newPsw1.length > 0 && this.newPsw1 == this.newPsw2;
    },
    progressPassword() {
      let checks = [
        this.checkboxLength,
        this.checkboxNumber,
        this.checkboxNotCapital,
        this.checkboxCapital,
        this.checkboxSpecialChar,
        this.checkboxConsecutives,
      ];
      return checks.filter(Boolean).length * 17;
    },
    progressPassword2() {
      let progress = 0;
      if (this.checkboxPassword2) {
        progress = 100;
      }
      return progress;
    },
    enableSetPassword() {
      return (
        this.checkboxLength &&
        this.checkboxNumber &&
        this.checkboxNotCapital &&
        this.checkboxCapital &&
        this.checkboxSpecialChar &&
        this.checkboxConsecutives &&
        this.checkboxPassword2
      );
    },
  },
  methods: {
    confirmChangePsw() {
      this.dialogConfirm = true;
      this.showMessageOK = false;
      this.showMessageNOK = false;
    },
    changePassword() {
      this.loading = true;
      this.axios({
        method: "POST",
        url: "/user/setPassword",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          token: this.$route.params.token,
          new_password: this.newPsw1,
          confirm_password: this.newPsw2,
        },
      }).then((resp) => {
        console.log(resp.data);
        if (!resp.data.Error) {
          this.showMessageOK = true;
        } else {
          this.showMessageNOK = true;
        }
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped>
.v-input--selection-controls {
  margin: 0px;
}
</style>
