<template>
  <v-container class="pr-5 pl-5"> <!--v-if="filtersApplication.length > 0"-->
    <v-row align="center" justify="start">
      <v-btn
        class="ml-1 mr-2"
        outlined
        color="secondary"
        @click="resetAllFilters()"
      >
        
        {{textApp.label00435}}
        <v-icon right>
          mdi-close-circle
        </v-icon>
      </v-btn>
      <v-btn
        class="ml-1 mr-2"
        outlined
        color="secondary"
        @click="reload()"
      >
        {{textApp.label00437}}
        <v-icon right>
          mdi-cached
        </v-icon>
      </v-btn>
    </v-row>
    <v-row align="center" justify="start">
      <v-card
        class="ma-1"
        style="min-width: 200px;"
        outlined
        v-for="(item, k) in filtersApplication"
        :key="k"
      >
        <v-card-text
          class="pa-2"
        >
          <v-row align="center" justify="space-between">
            <div class="pa-1 pl-3">
              <div style="font-size: 80%;">{{item.type_label}}</div>
              <v-list-item-subtitle>
                <b>{{item.label}}</b>
              </v-list-item-subtitle>
            </div>
            
            <v-btn
              class="pa-1 pr-5"
              icon
              x-small
              color="secondary"
              @click="removeItemFilterApplication(item)"
            >
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </v-row>
          
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    reload() {
      this.reloadButton ++;
    },
    resetAllFilters() {
      this.filtersApplication = [];
      this.reloadButton ++;
    },
    removeItemFilterApplication(item) {
      this.filtersApplication = this.filtersApplication.filter(elem => elem.value != item.value);
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    dataLabel: {
      get: function () {
        return this.$store.getters.getDataLabel;
      },
    },
    filtersApplication: {
      get: function () {
        return this.$store.getters.getFiltersApplication;
      },
      set: function (value) {
        this.$store.dispatch("setFiltersApplication", value);
      },
    },
    reloadButton: {
      get: function() {
        return this.$store.getters.getReloadButton;
      },
      set: function(value) {
        this.$store.dispatch("setReloadButton", value);
      }
    },
  },
  watch: {},
};
</script>
<style scoped></style>
