<template>
  <v-container class="pr-5 pl-5">
    <v-row align="start" justify="start">
      <v-col 
        cols="12" 
        md="12"
        v-for="(categ, i) in reports"
        :key="i"
      >
        <v-card outlined>
          <v-card-text>
            <h4>{{ categ.group }}</h4>
          </v-card-text>
        </v-card>
        <v-row 
          align="start" 
          justify="start"
          class="pl-2 pr-2"
        >
          <v-col 
            cols="12" 
            md="3"
            sm="12"
            class="pl-1 pr-1"
            v-for="(report, j) in categ.list"
            :key="j"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <v-card
                    outlined
                    class="mt-2"
                    v-bind="attrs"
                    v-on="on"
                    :elevation="hover ? 3 : 0"
                    :to="report.to"
                  >
                    <v-card-text
                      style="text-align: center;"
                    >
                      <v-icon x-large>{{ report.icon }}</v-icon>
                      <h4>{{ report.label }}</h4>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </template>
              <span>{{ report.desc }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      reports: [],
    };
  },
  mounted() {
    this.reports = [
      
    {
          group: this.textApp.label00483,
          list: [
            {
              label: this.textApp.label00485,
              to: "/report-simple",
              icon: "mdi-chart-bar",
              desc: this.textApp.label00487
            }
          ]
        },
        {
          group: this.textApp.label00484,
          list: [
            {
              label: this.textApp.label00486,
              to: "/report-asset-agent",
              icon: "mdi-laptop",
              desc: this.textApp.label00488
            },
            /*{
              label: "Azioni file",
              to: "/",
              icon: "mdi-file-account-outline",
              desc: "Genera un report sulle azioni degli agent sui file"
            },
            {
              label: "Attività",
              to: "/report-action-agent",
              icon: "mdi-account-box",
              desc: "Genera un report sulle attività degli agenti"
            },*/
          ]
        },
        {
          group: this.textApp.label00497,
          list: [
            {
              label: this.textApp.label00495,
              to: "/report-warning",
              icon: "mdi-compare-horizontal",
              desc: this.textApp.label00496
            },
          ]
        },
        {
          group: "Segreto industriale",
          list: [
            {
              label: "Lista eventi",
              to: "/report-warning",
              icon: "mdi-compare-horizontal",
              desc: "Lista degli eventi del segreto industriale"
            },
          ]
        }
    ]
  },
  methods: {
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      }
    },
    dataLabel: {
      get: function () {
        return this.$store.getters.getDataLabel;
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
  }
};
</script>
<style scoped>
</style>
