<template>
  <v-container>
    <v-dialog v-model="dialog" width="700">
      <template #activator="{ on: dialog }">
        <v-tooltip bottom>
          <template #activator="{ on: tooltip }">
            <v-btn v-on="{ ...tooltip, ...dialog }" icon color="secondary">
              <v-icon>mdi-format-list-bulleted-square</v-icon>
            </v-btn>
          </template>
          <span>{{textApp.label00165}}</span>
        </v-tooltip>
      </template>

      <v-card>
        <v-card-title>
          <h4 :style="{ color: $vuetify.theme.themes.light.secondary }">
            {{textApp.label00166}}
          </h4>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-card outlined class="mb-3 mt-3">
                <v-card-text>
                  <h3
                    :style="{ color: $vuetify.theme.themes.light.secondary }"
                    class="mb-2"
                  >
                    {{textApp.label00167}}
                  </h3>
                  <b class="mb-0" v-for="(p, j) in listPartecipants" :key="j">
                    - {{ p.name }} {{ p.surname }} <br />
                  </b>
                </v-card-text>
              </v-card>
              <v-divider></v-divider>

              <v-card outlined class="mt-3">
                <v-card-text>
                  <h3
                    :style="{ color: $vuetify.theme.themes.light.secondary }"
                    class="mb-2"
                  >
                    {{textApp.label00168}}
                  </h3>
                  <b class="mb-0"
                    >{{ warning.sender_name }} {{ warning.sender_surname }}</b
                  >
                  <h4>{{ warning.object }}</h4>
                  <p>
                    <span style="white-space: pre-line">{{
                      warning.description
                    }}</span>
                  </p>
                  <span v-if="warning.metadata != ''">
                    <span v-if="JSON.parse(warning.metadata).type == 'generic'">
                      <v-row justify="end">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                              v-bind="attrs"
                              v-on="on"
                              class="mb-3 mr-7"
                              color="primary" 
                              outlined  
                              @click="applyFilters(JSON.parse(warning.metadata))"> 
                                {{textApp.label00440}}
                            </v-btn>
                          </template>
                          <span>{{textApp.label00441}}</span>
                        </v-tooltip>
                      </v-row>
                      <v-tabs vertical>
                        <v-tab>
                          <v-icon left> mdi-chart-line-variant </v-icon>
                          {{textApp.label00106}}
                        </v-tab>
                        <v-tab>
                          <v-icon left> mdi-chart-pie </v-icon>
                          {{textApp.label00169}}
                        </v-tab>
                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <LineTemplate
                                :title="textApp.label00106"
                                :label="textApp.label00170"
                                linkAPI="/charts/generic/events"
                                heightStyle="200px"
                                :heightChart="1"
                                :widthChart="2"
                                :filters="JSON.parse(warning.metadata).filters"
                                colorsSet="colorsChartLineDirigente"
                              ></LineTemplate>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <DoughnutTemplate
                                :title="textApp.label00169"
                                linkAPI="/charts/generic/rid"
                                heightStyle="200px"
                                :widthChart="2"
                                :filters="JSON.parse(warning.metadata).filters"
                                colorsSet="colorsChartRID"
                              >
                              </DoughnutTemplate>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                      </v-tabs>
                    </span>
                    <span v-if="JSON.parse(warning.metadata).type == 'categ'">
                      <v-row justify="end" class="mb-3">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                              v-bind="attrs"
                              v-on="on"
                              class="mr-3"
                              color="primary" 
                              outlined  
                              @click="applyFilters(JSON.parse(warning.metadata))"> 
                                {{textApp.label00440}}
                            </v-btn>
                          </template>
                          <span>{{textApp.label00441}}</span>
                        </v-tooltip>
                      </v-row>
                      <BarTemplate
                        :title="textApp.label00103"
                        linkAPI="/charts/categ/tags"
                        :filters="JSON.parse(warning.metadata).filters"
                        colorsSet="colorsChartCategDPO"
                      ></BarTemplate>
                    </span>
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-card outlined v-if="listWarning.length > 0">
                <v-card-text>
                  <h3
                    :style="{ color: $vuetify.theme.themes.light.secondary }"
                    class="mb-0"
                  >
                    {{textApp.label00171}}
                  </h3>
                </v-card-text>
                <v-card-text v-for="(warn, i) in listWarning" :key="i">
                  <b class="mb-0">{{ warn.sender_name }}</b>
                  <p style="white-space: pre-line" v-text="warn.txt"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()"> {{textApp.label00042}} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import LineTemplate from "../components/LineTemplate";
import DoughnutTemplate from "../components/DoughnutTemplate";
import BarTemplate from "../components/BarTemplate";
export default {
  components: {
    LineTemplate,
    DoughnutTemplate,
    BarTemplate
  },
  data() {
    return {
      dialog: false,
      listWarning: [],
      listPartecipants: []
    };
  },
  props: ["warning"],
  mounted() {},
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    credentials: {
      get: function() {
        return this.$store.getters.getCredentials;
      },
      set: function(value) {
        this.$store.dispatch("setCredentials", value);
      }
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
    filterStartDate: {
      get: function() {
        return this.$store.getters.getStartDate;
      },
      set: function(value) {
        this.$store.dispatch("setStartDate", value);
      }
    },
    filterEndDate: {
      get: function() {
        return this.$store.getters.getEndDate;
      },
      set: function(value) {
        this.$store.dispatch("setEndDate", value);
      }
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    applyFilters(filters) {
      console.log(filters);
      this.filterStartDate = filters.filters.startDate;
      this.filterEndDate = filters.filters.endDate;

      if (filters.type == "generic") {
        this.$router.push("/gdpr-generic");
      } else if (filters.type == "categ") {
        this.$router.push("/gdpr-categ");
      }
    }
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.axios({
          method: "GET",
          url: "/warnings/detail",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.token}`
          },
          params: {
            id_warning: this.warning.id,
            company: this.company.id
          }
        }).then(resp => {
          console.log(resp.data);
          if (!resp.data.Error) {
            this.listWarning = resp.data.responses;
            this.listPartecipants = resp.data.participants;
          }
        });
      }
    }
  }
};
</script>
<style scoped></style>
