<template>
  <v-container class="pr-5 pl-5">
    <h3>{{ textApp.label00139 }} {{ group }}</h3>
    <v-row class="mt-2" align="start" justify="start">
      <v-col cols="12" md="5">
        <v-card outlined class="pa-2">
          <v-card-text><h4>{{textApp.label00443}}</h4></v-card-text>
          <v-row justify="start">
            <v-card-title>
              <v-text-field
                v-model="searchListAgent"
                append-icon="mdi-magnify"
                :label="textApp.label00084"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
          </v-row>
          <v-data-table
            v-model="selectedListAgent"
            :search="searchListAgent"
            :headers="headers"
            :items="listAgents"
            item-key="id"
            show-select
            :footer-props="footerProps"
          >
            <template v-slot:no-data>
              <v-card-text> {{textApp.label00089}} </v-card-text>
            </template>
          </v-data-table>
          <v-row v-if="loading">
            <v-card-text style="padding-top: 50px; padding-bottom: 50px">
              <v-row align="center" justify="center">
                <v-progress-circular
                  :size="120"
                  :width="10"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-row>
            </v-card-text>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="2">
        <v-row justify="center" align="center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ma-2"
                v-bind="attrs"
                v-on="on"
                outlined
                large
                fab
                color="primary"
                @click="assignGroup()"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </template>
            <span>{{textApp.label00140}} {{ group }}</span>
          </v-tooltip>
        </v-row>
        <v-row justify="center" align="center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ma-2"
                v-bind="attrs"
                v-on="on"
                outlined
                large
                fab
                color="primary"
                @click="removeGroup()"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span>{{textApp.label00141}} {{ group }}</span>
          </v-tooltip>
        </v-row>
        <v-row justify="center" align="center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ma-2"
                v-bind="attrs"
                v-on="on"
                outlined
                large
                fab
                color="primary"
                @click="save()"
              >
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </template>
            <span>{{textApp.label00142}}</span>
          </v-tooltip>
        </v-row>
      </v-col>
      <v-col cols="12" md="5">
        <v-card outlined class="pa-2">
          <v-card-text><h4>{{textApp.label00444}}</h4></v-card-text>
          <v-row justify="start">
            <v-card-title>
              <v-text-field
                v-model="searchListAgent"
                append-icon="mdi-magnify"
                label="Cerca"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
          </v-row>
          <v-data-table
            v-model="selectedListAgentToAssign"
            :search="searchListAgentToAssign"
            :headers="headers"
            :items="listAgentToAssign"
            item-key="id"
            show-select
            :footer-props="footerProps"
          >
            <template v-slot:no-data>
              <v-card-text> {{textApp.label00089}} </v-card-text>
            </template>
          </v-data-table>
          <v-row v-if="loading">
            <v-card-text style="padding-top: 50px; padding-bottom: 50px">
              <v-row align="center" justify="center">
                <v-progress-circular
                  :size="120"
                  :width="10"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-row>
            </v-card-text>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogConfirm" width="600">
      <v-card>
        <v-card-title>
          <h4 :style="{ color: $vuetify.theme.themes.light.secondary }" class="pb-5">{{textApp.label00143}}</h4>
        </v-card-title>

        <v-card-text v-if="!loadingSaving">
          {{textApp.label00144}}
        </v-card-text>

        <v-row v-if="loadingSaving" class="ma-0">
          <v-card-text style="padding-top: 50px; padding-bottom: 50px">
            <v-row align="center" justify="center">
              <v-progress-circular
                :size="120"
                :width="10"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-row>
          </v-card-text>
        </v-row>

        <v-divider></v-divider>

        <v-card-actions v-if="!loadingSaving">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogConfirm = false">
            {{textApp.label00145}}
          </v-btn>
          <v-btn color="primary" @click="confirmSave()"> {{textApp.label00146}} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      selectedListAgent: [],
      listAgents: [],
      searchListAgent: null,
      headers: [],
      footerProps: null,
      searchListAgentToAssign: null,
      listAgentToAssign: [],
      selectedListAgentToAssign: [],
      loading: false,
      loadingSaving: false,
      dialogConfirm: false,
    };
  },
  mounted() {
    this.loadData();
    this.headers = [
      {
        text: this.textApp.label00056,
        align: "start",
        value: "id",
      },
      { text: this.textApp.label00005, value: "name" },
      { text: this.textApp.label00090, value: "os.name" },
    ];
    this.footerProps = {
      itemsPerPageText: this.textApp.label00095,
      pageText: `{0}-{1} ${this.textApp.label00096} {2}`,
      "items-per-page-all-text": this.textApp.label00097,
    };
  },
  methods: {
    loadData() {
      this.listAgents = [];
      this.listAgentToAssign = [];
      this.loading = true;
      this.axios({
        method: "GET",
        url: "/agents/list",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        params: {
          company: this.company.id,
        },
      }).then((resp) => {
        console.log(resp.data);
        if (!resp.data.Error) {
          resp.data.forEach((item) => {
            if (item.group.includes(this.group)) {
              this.listAgentToAssign.push(item);
            } else {
              this.listAgents.push(item);
            }
          });
        }
        this.loading = false;
      });
    },
    assignGroup() {
      this.selectedListAgent.forEach((elem) => {
        this.listAgentToAssign.push(elem);
      });
      this.listAgents = this.listAgents.filter((item) => {
        let notFound = true;
        this.selectedListAgent.forEach((elem) => {
          if (elem.id == item.id) {
            notFound = false;
          }
        });
        return notFound;
      });
    },
    removeGroup() {
      this.selectedListAgentToAssign.forEach((elem) => {
        this.listAgents.push(elem);
      });
      this.listAgentToAssign = this.listAgentToAssign.filter((item) => {
        let notFound = true;
        this.selectedListAgentToAssign.forEach((elem) => {
          if (elem.id == item.id) {
            notFound = false;
          }
        });
        return notFound;
      });
    },
    save() {
      this.dialogConfirm = true;
    },
    confirmSave() {
      this.loadingSaving = true;
      let listAdd = [];
      let listRemove = [];
      this.listAgentToAssign.forEach((item) => {
        if (!item.group.includes(this.group)) {
          listAdd.push(item.id);
        }
      });
      this.listAgents.forEach((item) => {
        if (item.group.includes(this.group)) {
          listRemove.push(item.id);
        }
      });
      this.axios({
        method: "POST",
        url: "/agents/updateMultipleGroups",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          company: this.company.id,
          group: this.group,
          listAgentToAdd: listAdd,
          listAgentToRemove: listRemove,
        },
      }).then((resp) => {
        console.log(resp.data);
        this.loadingSaving = false;
        this.dialogConfirm = false;
        this.loadData();
      });
    },
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    group() {
      return this.$route.params.group;
    },
    company: {
      get: function () {
        return this.$store.getters.getCompany;
      },
      set: function (value) {
        this.$store.dispatch("setCompany", value);
      },
    },
  },
};
</script>
<style scoped></style>
