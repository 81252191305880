module.exports = {
    /**
     * 
     * @param {date} str_date is in format YYYY-MM-DDTHH:mm:ss.xxxZ local timezone
     * @returns date in string format YYYY-MM-DD timezone UTC
     */
    getDateUTC: function (str_date) {
        /*console.log(new Date(str_date));
        console.log((new Date(str_date)).getTimezoneOffset());
        console.log(new Date(str_date).toISOString());
        console.log(new Date(new Date(str_date) - 60 * 60 * 1000));
        console.log(new Date(new Date(str_date) - 60 * 60 * 1000).toISOString());*/

        console.log(new Date(new Date(str_date) - (new Date(str_date)).getTimezoneOffset() * 60 * 1000).toISOString());
        return new Date(new Date(str_date) - (new Date(str_date)).getTimezoneOffset() * 60 * 1000).toISOString().substr(0, 10);
    },
    /**
     * 
     * @param {date} str_date is in format YYYY-MM-DDTHH:mm:ss.xxxZ local timezone
     * @returns date in string format YYYY-MM-DDTHH:mm:ss timezone UTC
     */
    getDatetimeUTC: function (str_date) {
        /*console.log(new Date(str_date));
        console.log((new Date(str_date)).getTimezoneOffset());
        console.log(new Date(str_date).toISOString());
        console.log(new Date(new Date(str_date) - 60 * 60 * 1000));
        console.log(new Date(new Date(str_date) - 60 * 60 * 1000).toISOString());*/
        
        console.log(new Date(new Date(str_date) - (new Date(str_date)).getTimezoneOffset() * 60 * 1000).toISOString());
        return new Date(new Date(str_date) - (new Date(str_date)).getTimezoneOffset() * 60 * 1000).toISOString().substr(0, 19);
    },
    /**
     * 
     * @param {date} str_date is in format YYYY-MM-DDTHH:mm:ss.xxxZ local timezone
     * @returns date in string format YYYY-MM-DDTHH:mm:ss.xxxZ loca timezone
     */
    getDateLocalTimezone: function (str_date) {
        return new Date(new Date(str_date) - ((new Date(str_date)).getTimezoneOffset() * 60 * 1000)).toISOString();
    },
    /**
     * 
     * @param {date} str_date is in format YYYY-MM-DDTHH:mm:ss.xxxZ UTC timezone
     * @returns date in string format YYYY-MM-DDTHH:mm:ss local timezone
     */
    getLocalDateStr: function (str_date) {
        function leftPad(number, targetLength) {
            var output = number + '';
            while (output.length < targetLength) {
                output = '0' + output;
            }
            return output;
        }
        let date = new Date(str_date);
        return `${date.getFullYear()}-${leftPad(date.getMonth() + 1, 2)}-${leftPad(date.getDate(), 2)}T${leftPad(date.getHours(), 2)}:${leftPad(date.getMinutes(), 2)}:${leftPad(date.getSeconds(), 2)}`;
    },
    /**
     * 
     * @param {string} str_date is string in format YYYY-MM-DDTHH:mm:ss local timezone
     * @returns date in string format YYYY-MM-DDTHH:mm:ss.000Z UTC timezone
     */
    convertDateToUTC(str_date) {
        return (new Date(str_date)).toISOString();
    }
  };