<template>
  <v-container class="pr-5 pl-5">
    <v-row align="start" justify="start">
      <v-col cols="12" md="12">
        <v-card outlined>
          <v-card-text>
            <h4>{{ textApp.label00456 }}</h4>
          </v-card-text>
        </v-card>
        <v-stepper
          class="mt-2"
          v-model="stepper"
          vertical
          outlined
        >
          <v-stepper-step
            :complete="stepper > 1"
            step="1"
          >
            {{ textApp.label00457 }}
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-card
              class="mb-12 pa-3 elevation-0"
              height="150px"
            >
            <v-card-text v-if="loading_agent" style="padding-top: 50px; padding-bottom: 50px">
              <v-row align="center" justify="center">
                <v-progress-circular
                  :size="120"
                  :width="10"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-row>
            </v-card-text>
              <v-autocomplete
                v-else
                v-model="agents_selected"
                :label="textApp.label00458"
                :items="agent_list"
                item-text="str_id"
                item-value="id"
                outlined
                multiple
                clearable
              ></v-autocomplete>
            </v-card>
            <v-btn
              :disabled="agents_selected.length == 0"
              color="primary"
              @click="stepper = 2"
            >
              {{ textApp.label00304 }}
            </v-btn>
          </v-stepper-content>

          <v-stepper-step
            :complete="stepper > 2"
            step="2"
          >
            {{ textApp.label00459 }}
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-card
              class="mb-12 pa-3 elevation-0"
              height="150px"
            >
              <v-autocomplete
                v-model="code_selected"
                :label="textApp.label00460"
                :items="code_list"
                item-text="name"
                item-value="id"
                outlined
                clearable
              ></v-autocomplete>
            </v-card>
            <v-btn
              :disabled="code_selected == null"
              color="primary"
              @click="stepper = 3"
            >
              {{ textApp.label00304 }}
            </v-btn>
            <v-btn 
              text
              @click="stepper = 1"
            >
              {{ textApp.label00314 }}
            </v-btn>
          </v-stepper-content>

          <v-stepper-step
            :complete="stepper > 3"
            step="3"
          >
            {{ textApp.label00461 }}
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-card
              class="mb-12 pa-3 elevation-0"
              height="200px"
            >
              <v-row>
                <v-col
                  class="pl-1 pr-0 mt-0 mb-0"
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-menu
                    v-model="menuStartDate"
                    :close-on-content-click="false"
                    offset-x
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :label="textApp.label00070"
                        v-model="startDate"
                        v-bind="attrs"
                        v-on="on"
                        append-icon="mdi-calendar"
                        outlined
                        clearable
                      ></v-text-field>
                    </template>

                    <v-card>
                      <v-date-picker
                        v-model="startDate"
                        locale="it-IT"
                      ></v-date-picker>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          @click="
                            menuStartDate = false;
                            startDate = null;
                          "
                        >
                          {{textApp.label00012}}
                        </v-btn>
                        <v-btn
                          color="primary"
                          text
                          @click="
                            menuStartDate = false;
                          "
                        >
                          {{textApp.label00072}}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </v-col>
                <v-col
                  class="pl-1 pr-0 mt-0 mb-0"
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-menu
                    v-model="menuStartTime"
                    :close-on-content-click="false"
                    offset-x
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :label="textApp.label00462"
                        v-model="startTime"
                        v-bind="attrs"
                        v-on="on"
                        append-icon="mdi-clock-time-eight-outline"
                        outlined
                        clearable
                      ></v-text-field>
                    </template>

                    <v-card>
                      <v-time-picker
                        v-model="startTime"
                        format="24hr"
                      ></v-time-picker>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          @click="
                            menuStartTime = false;
                            startTime = null;
                          "
                        >
                          {{textApp.label00012}}
                        </v-btn>
                        <v-btn
                          color="primary"
                          text
                          @click="
                            menuStartTime = false;
                          "
                        >
                          {{textApp.label00072}}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </v-col>
                <v-col
                  class="pl-1 pr-0 mt-0 mb-0"
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-menu
                    v-model="menuEndDate"
                    :close-on-content-click="false"
                    offset-x
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :label="textApp.label00071"
                        v-model="endDate"
                        v-bind="attrs"
                        v-on="on"
                        append-icon="mdi-calendar"
                        outlined
                        clearable
                      ></v-text-field>
                    </template>

                    <v-card>
                      <v-date-picker 
                        v-model="endDate" 
                        locale="it"
                      ></v-date-picker>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          @click="
                            menuEndDate = false;
                            endDate = null;
                          "
                        >
                          {{textApp.label00012}}
                        </v-btn>
                        <v-btn
                          color="primary"
                          text
                          @click="
                            menuEndDate = false;
                          "
                        >
                          {{textApp.label00072}}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </v-col>
                <v-col
                  class="pl-1 pr-0 mt-0 mb-0"
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-menu
                    v-model="menuEndTime"
                    :close-on-content-click="false"
                    offset-x
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :label="textApp.label00463"
                        v-model="endTime"
                        v-bind="attrs"
                        v-on="on"
                        append-icon="mdi-clock-time-eight-outline"
                        outlined
                        clearable
                      ></v-text-field>
                    </template>

                    <v-card>
                      <v-time-picker 
                        v-model="endTime" 
                        format="24hr"
                      ></v-time-picker>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          @click="
                            menuEndTime = false;
                            endTime = null;
                          "
                        >
                          {{textApp.label00012}}
                        </v-btn>
                        <v-btn
                          color="primary"
                          text
                          @click="
                            menuEndTime = false;
                            endTime = endTime;
                          "
                        >
                          {{textApp.label00072}}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card>
            <v-btn
              :disabled="startDate == null || endDate == null || startTime == null || endTime == null"
              color="primary"
              :loading="loading_btn"
              @click="countResults"
            >
              {{ textApp.label00304 }}
            </v-btn>
            <v-btn 
              text
              @click="stepper = 2"
            >
              {{ textApp.label00314 }}
            </v-btn>
          </v-stepper-content>

          <v-stepper-step 
            step="4"
            :complete="stepper > 4"
          >
            {{ textApp.label00464 }}
          </v-stepper-step>
          <v-stepper-content step="4">
            <v-card
              class="mb-12 elevation-0"
              height="150px"
            >
              <v-card-text v-if="stepper == 4">
                <h3 class="mb-2">{{ textApp.label00465 }}</h3>

                <table>
                  <tr>
                    <td>
                      {{ textApp.label00466 }}
                    </td>
                    <td>
                      <b>{{ startDatetimeStr }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ textApp.label00467 }}
                    </td>
                    <td>
                      <b>{{ endDatetimeStr }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ textApp.label00468 }}
                    </td>
                    <td>
                      <b>{{ dataLabel[code_selected].name }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ textApp.label00256 }}
                    </td>
                    <td>
                      <b>{{ agents_selected.toString() }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ textApp.label00469 }}
                    </td>
                    <td>
                      <b>{{ total_data }}</b>
                    </td>
                  </tr>
                </table>
                <v-alert
                  v-if="total_data > 10000"
                  dense
                  outlined
                  type="error"
                >
                  {{ textApp.label00470 }}
                </v-alert>
              </v-card-text>
              
              <v-row v-if="loading" align="center" justify="center">
                <v-progress-circular
                  :size="120"
                  :width="10"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-row>
            </v-card>
            <v-btn
              :disabled="!ready_generation || total_data > 10000"
              class="mt-5"
              color="primary"
              :loading="loading_btn"
              @click="generateReport"
            >
              {{ textApp.label00464 }}
            </v-btn>
            <v-btn 
              class="mt-5"
              text
              @click="stepper = 3"
            >
              {{ textApp.label00314 }}
            </v-btn>
          </v-stepper-content>
          <v-btn
            v-if="stepper > 4"
            class="mt-10 ml-5"
            color="primary"
            @click="stepper = 1"
          >
            {{ textApp.label00471 }}
          </v-btn>
        </v-stepper>
        <v-card outlined class="mt-3" v-if="report_data != null" >
          <v-card-text>
            <v-btn
              class="mr-1"
              color="secondary"
              dark
              @click="dialog = true;"
            >
              {{ textApp.label00472 }}
            </v-btn>
            <v-btn 
              color="secondary"
              dark  
              @click="exportData"
            >
              {{ textApp.label00473 }}
            </v-btn>
          </v-card-text>
        </v-card>
        <v-card outlined class="mt-3" v-if="report_data != null" >
          <v-card-text>
            <v-row class="mt-3 mb-5">
              <v-col cols="12" md="9" sm="12" class="pb-0 pt-0">
                <time-bar-template
                  :title="textApp.label00181"
                  :data="report_data"
                  :widthChart="6"
                ></time-bar-template>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-data-table
                  :headers="headers"
                  :items="list_data"
                  item-key="id_event"
                  
                  class="elevation-0"
                  disable-pagination
                  hide-default-footer
                  dense
                >
                  <template v-slot:item.timestamp="{ item }">
                    {{ convertTimestamp(item.timestamp) }}
                  </template>
                  <template v-slot:no-data>
                    <v-card-text>
                      {{ textApp.label00089 }}
                    </v-card-text>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <!-- HEADER FIGO -->
                <!--<v-row no-gutters style="margin-top: 30px; margin-bottom: 50px;">
                  <v-col cols="12" md="3">
                    <v-card
                      style="padding: 10px; height: 100px; text-align: center"
                      outlined
                      tile
                      height="100%"
                    >
                      <img :src="companySetup.report_logo" height="75px">
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-row no-gutters>
                      <v-col cols="12" md="12">
                        <v-card
                          style="padding: 10px; height: 50px;"
                          outlined
                          tile
                          align="center"
                        >
                          <h3 :style="{ color: $vuetify.theme.themes.light.secondary }">{{ companySetup.service_name }}</h3>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12" md="12">
                        <v-card
                          style="padding: 10px; height: 50px;"
                          outlined
                          tile
                          align="center"
                        >
                          Report semplice
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-card
                      outlined
                      tile
                      height="100%"
                      style="text-align: center; padding: 10px; height: 100px;"
                    >
                      <h4 :style="{ color: $vuetify.theme.themes.light.primary, 'margin-top': '25px'}">{{ companySetup.company_name }}</h4>
                    </v-card>
                  </v-col>
                </v-row>-->
        <!-- Anteprima -->
        <v-dialog
          v-model="dialog"
          scrollable
          width="800"
        >
          <v-card>
            <v-card-title>
              <h4 :style="{ color: $vuetify.theme.themes.light.secondary }">{{ textApp.label00474 }}</h4>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text>
              <v-row class="mt-3 mb-5" v-if="report_data != null" id="content-pdf">
                <v-col cols="12" md="12" class="mt-2 mb-3">
                  <h2 :style="{ color: $vuetify.theme.themes.light.secondary }">{{ textApp.label00456 }}</h2>
                </v-col>
                <v-col cols="12" md="9" sm="12" class="pb-0 pt-0">
                  <time-bar-template
                    :title="textApp.label00181"
                    :data="report_data"
                    :widthChart="6"
                  ></time-bar-template>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-data-table
                    :headers="headers"
                    :items="list_data"
                    item-key="id_event"
                    
                    class="elevation-0"
                    disable-pagination
                    hide-default-footer
                    dense
                  >
                    <template v-slot:item.timestamp="{ item }">
                      {{ convertTimestamp(item.timestamp) }}
                    </template>
                    <template v-slot:no-data>
                      <v-card-text>
                        {{ textApp.label00089 }}
                      </v-card-text>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="exportToPDF"
              >
                {{ textApp.label00482 }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import timezone from "../utils/timezone.js"
import TimeBarTemplate from "../components/TimeBarTemplate";
import html2pdf from "html2pdf.js";
import exportFromJSON from "export-from-json";
export default {
  components: {
    TimeBarTemplate
  },
  data() {
    return {
      loading_agent: true,
      agent_list: [],
      agents_selected: [],
      code_list: [],
      code_selected: null,
      stepper: 1,
      menuStartDate: false,
      menuEndDate: false,
      menuStartTime: false,
      menuEndTime: false,
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      loading_btn: false,
      ready_generation: false,
      loading: false,
      total_data: 0,
      report_data: null,
      list_data: null,
      headers: [],
      //expanded: [],
      dialog: false
    };
  },
  mounted() {
    let now = new Date();
    let before = new Date(now.getTime() - 60 * 60 * 1000); // -1 ora
    this.startDate = timezone.getLocalDateStr(before).substr(0, 10);
    this.endDate = timezone.getLocalDateStr(now).substr(0, 10);
    this.startTime = timezone.getLocalDateStr(before).substr(11, 5);
    this.endTime = timezone.getLocalDateStr(now).substr(11, 5);
    this.headers = [
      {
        text: this.textApp.label00151,
        align: "left",
        sortable: true,
        value: "timestamp",
      },
      { text: this.textApp.label00182, value: "idAgent" },
      { text: this.textApp.label00184, value: "level" },
      { text: this.textApp.label00176, value: "desc_short" },
      { text: this.textApp.label00183, value: "ip" },
      { text: this.textApp.label00189, value: "mitre_tech.technique" },
      
    ];
    Object.keys(this.dataLabel).forEach(code => {
      this.code_list.push({
        id: code,
        name: this.dataLabel[code].name
      });
    });
    this.axios({
      method: "GET",
      url: "/agents/list",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      params: {
        company: this.company.id,
      },
    }).then((resp) => {
      console.log(resp.data);
      if (!resp.data.Error) {
        resp.data.forEach((a, i) => {
          resp.data[i]['str_id'] = `${a.id} - ${a.name}`;
        });
        this.agent_list = resp.data;
        this.loading_agent = false;
      }
    });
  },
  methods: {
    countResults() {
      this.ready_generation = false;
      this.loading_btn = true;
      this.axios({
        method: "GET",
        url: "/events/specific/totalCount",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        params: {
          company: this.company.id,
          filterStartDate: new Date(this.startDatetime).toISOString().substr(0, 19),
          filterEndDate: new Date(this.endDatetime).toISOString().substr(0, 19),
          filters: this.filters
        },
      }).then((resp) => {
        console.log(resp.data);
        this.loading_btn = false;
        this.stepper = 4;
        this.ready_generation = true;
        this.total_data = resp.data.count;
      });
    },
    generateReport() {
      this.loading_btn = true;
      this.axios({
        method: "POST",
        url: "/reports/simple/data",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          company: this.company.id,
          filterStartDate: new Date(this.startDatetime).toISOString().substr(0, 19),
          filterEndDate: new Date(this.endDatetime).toISOString().substr(0, 19),
          filters: this.filters
        },
      }).then((resp) => {
        console.log(resp.data);
        this.stepper = 5;
        this.report_data = resp.data.data.timeline_agents;
        //this.list_data = resp.data.data.detail_list;
        this.loading_btn = false;
        
        const LENGHT_DESC = 50;
        resp.data.data.detail_list.forEach((item, index_item) => {
            if (item.desc.length > LENGHT_DESC) {
              item.desc_short = item.desc.substring(0, LENGHT_DESC) + "...";
            } else {
              item.desc_short = item.desc;
            }
            item.id_event =
              item.timestamp + item.id + item.idAgent + item.ip + index_item;
          });
          this.list_data = resp.data.data.detail_list; 
      });
    },
    convertTimestamp(date) {
      return timezone.getLocalDateStr(date).replaceAll(/T/g, " ");
    },
    /*exportToPDF() {
      /*html2pdf(document.getElementById("element-to-convert"), {
				margin: 1,
  			filename: timezone.getLocalDateStr(new Date()).replaceAll(/T/g, "_").replaceAll(/-/g, "").replaceAll(/:/g, ""),
			});
      this.dialog = true;
    },*/
    exportToPDF() {
      let name = this.companySetup.service_name;
      //let img = this.companySetup.report_logo;
      html2pdf()
      .from(document.getElementById("content-pdf"))
        .set({
            margin:       [30, 20, 30, 20],
            filename:     timezone.getLocalDateStr(new Date()).replaceAll(/T/g, "_").replaceAll(/-/g, "").replaceAll(/:/g, ""),
            image:        { type: 'jpeg',quality: 0.98 },
            html2canvas:  { scale: 2, logging: true, dpi: 192, letterRendering: true },
            jsPDF:        { unit: 'mm', format: 'a4', orientation: 'p' },
            pagebreak: { before: '.page-break', avoid: 'table' }
        })
        .toPdf()
        .get('pdf').then(function (pdf) {
          var totalPages = pdf.internal.getNumberOfPages();

          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(14);
            pdf.setTextColor(150);
            pdf.text(15, 15, name);
            pdf.text(pdf.internal.pageSize.getWidth() - 65, 15, timezone.getLocalDateStr(new Date()).replaceAll(/T/g, " "));
            //pdf.addImage(img, 'png', 0, 0, 52, 23)
            pdf.setFontSize(10);
            pdf.text('SIDNEO ' + i + '/' + totalPages+'', pdf.internal.pageSize.getWidth() - 40, pdf.internal.pageSize.getHeight() - 8);
          } 
    }).save();
    },
    exportData() {
      if (!this.list_data) return;
      try {
        let data = this.list_data.map(item => {
          return {
            timestamp: item.timestamp,
            agents: item.idAgent,
            level: item.level,
            description: item.desc,
            ip: item.ip,
            technique: (item.mitre_tech != null) ? item.mitre_tech.technique : ""
          }
        });
        const fileName = timezone.getLocalDateStr(new Date()).replaceAll(/T/g, "_").replaceAll(/-/g, "").replaceAll(/:/g, "");
        const exportType = exportFromJSON.types["csv"];
        exportFromJSON({ data, fileName, exportType });
      } catch (e) {
        throw new Error("Parsing failed!");
      }
    },
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      }
    },
    dataLabel: {
      get: function () {
        return this.$store.getters.getDataLabel;
      },
    },
    companySetup: {
      get: function() {
        return this.$store.getters.getCompanySetup;
      }
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
    startDatetime() {
      return `${this.startDate}T${this.startTime}:00`
    },
    endDatetime() {
      return `${this.endDate}T${this.endTime}:00`
    },
    filters() {
      return {
        requirementsSelected: [this.code_selected],
        IDAgentsSelected: this.agents_selected,
        IPAddressSelected: [],
        severitySelected: [],
        descriptionSelected: [],
      }
    },
    startDatetimeStr() {
      return  timezone.getLocalDateStr(this.startDatetime).replaceAll(/T/g, " ");
    },
    endDatetimeStr() {
      return  timezone.getLocalDateStr(this.endDatetime).replaceAll(/T/g, " ");
    }
  }
};
</script>
<style scoped>
td {
  padding: 0px 5px 0px 0px;
}
</style>
