<template>
  <v-container class="pt-0">
    <h4 :style="{ color: $vuetify.theme.themes.light.secondary }" class="mb-5">{{textApp.label00445}}</h4>
    <v-row v-if="loadingFilters" class="ma-0">
      <v-card-text style="padding-top: 50px; padding-bottom: 50px">
        <v-row align="center" justify="center">
          <v-progress-circular
            :size="120"
            :width="10"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-row>
      </v-card-text>
    </v-row>
    <v-row v-else>
      <v-col class="pb-0 pt-0 pr-1" cols="12" md="3">
        <v-select
          v-model="agentSelected"
          :label="textApp.label00068"
          :items="listAgents"
          item-text="id"
          item-value="id"
          dense
          outlined
          clearable
        ></v-select>
      </v-col>
      <v-col class="pb-0 pt-0 pl-1 pr-1" cols="12" md="3">
        <v-text-field
          :label="textApp.label00442"
          v-model="textSearched"
          dense
          outlined
          clearable
        ></v-text-field>
      </v-col>
      <v-col class="pb-0 pt-0 pl-1" cols="12" md="3">
        <v-btn
          color="primary"
          outlined
          style="height: 40px"
          @click="reloadFilters()"
        >
          {{textApp.label00437}}
          <v-icon right dark> mdi-cached </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="end">
      <span class="mr-3"
        >{{ sizeResult }} {{ textApp.label00187 }}
        {{ numberWithCommas(totalEvents) }} {{ textApp.label00188 }}</span
      >
    </v-row>
    <h4 :style="{ color: $vuetify.theme.themes.light.secondary }" class="mt-5 mb-5">{{textApp.label00277}}</h4>
    
    <v-card class="elevation-0 mt-0 pl-5 pr-5">
      <v-row align="start" class="pb-10">
        <v-data-table
          class="ma-0 row-pointer"
          :headers="headers"
          :items="listSecrets"
          hide-default-footer
          @click:row="showDetail"
          item-key="id_event"
          disable-pagination
        >
          <template v-slot:no-data>
            <v-card-text> {{ textApp.label00089 }} </v-card-text>
          </template>
        </v-data-table>
        <v-card
          width="100%"
          v-if="!loading && listSecrets.length > 0 && sizeResult < totalEvents"
          class="mt-2"
          @click="loadData(false)"
        >
          <v-card-text style="text-align: center">
            {{ textApp.label00192 }}
          </v-card-text>
        </v-card>
      </v-row>
      <v-row v-if="loading">
        <v-card-text style="padding-top: 50px; padding-bottom: 50px">
          <v-row align="center" justify="center">
            <v-progress-circular
              :size="120"
              :width="10"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </v-card-text>
      </v-row>
    </v-card>
    <v-dialog v-model="dialog" width="700">
      <v-card>
        <v-card-title>
          <h4 :style="{ color: $vuetify.theme.themes.light.secondary }">
            {{ textApp.label00278 }}
          </h4>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="detailData != null">
          <v-simple-table class="mt-3">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td v-if="detailData.timestamp != null">{{ textApp.label00151 }}</td>
                  <td v-if="detailData.timestamp != null"> {{ detailData.timestamp }}</td>
                </tr>
                <tr>
                  <td v-if="detailData.agent != null">{{ textApp.label00068 }}</td>
                  <td v-if="detailData.agent != null"> {{ detailData.agent }}</td>
                </tr>
                <tr>
                  <td v-if="detailData.hostname != null">{{ textApp.label00498 }}</td>
                  <td v-if="detailData.hostname != null"> {{ detailData.hostname }}</td>
                </tr>
                <tr>
                  <td v-if="detailData.event != null">{{ textApp.label00280 }}</td>
                  <td v-if="detailData.event != null"> {{ detailData.event }} {{ detailData.event_description }}</td>
                </tr>
                <tr>
                  <td v-if="detailData.file != null">{{ textApp.label00279 }}</td>
                  <td v-if="detailData.file != null"> {{ detailData.file }}</td>
                </tr>
                <tr>
                  <td v-if="detailData.description != null">{{ textApp.label00176 }}</td>
                  <td v-if="detailData.description != null"> {{ detailData.description }}</td>
                </tr>
                <tr>
                  <td v-if="detailData.process_id != null">{{ textApp.label00281 }}</td>
                  <td v-if="detailData.process_id != null"> {{ detailData.process_id }}</td>
                </tr>
                <tr>
                  <td v-if="detailData.process != null">{{ textApp.label00282 }}</td>
                  <td v-if="detailData.process != null"> {{ detailData.process }}</td>
                </tr>
                <tr>
                  <td v-if="detailData.username != null">{{ textApp.label00283 }}</td>
                  <td v-if="detailData.username != null"> {{ detailData.username }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()">
            {{ textApp.label00042 }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ScrollToTop></ScrollToTop>
  </v-container>
</template>

<script>
import ScrollToTop from '../components/ScrollToTop';
import timezone from "../utils/timezone.js"
export default {
  components: {
    ScrollToTop
  },
  data() {
    return {
      listSecrets: [],
      totalEvents: 0,
      sizeResult: 0,
      loading: false,
      headers: [],
      dialog: false,
      loadingDetail: false,
      detailData: null,
      listAgents: [],
      agentSelected: null,
      textSearched: null,
      loadingFilters: false,
      codes: {}
    };
  },
  mounted() {
    this.loadData(true);
    this.loadingFilters = true;
    this.axios({
      method: "GET",
      url: "/agents/list",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`
      },
      params: {
        company: this.company.id
      }
    }).then(resp => {
      console.log(resp.data);
      this.listAgents = resp.data;
      this.loadingFilters = false;
    });
    this.headers = [
      { text: this.textApp.label00151, value: "timestamp", width: "15%" },
      { text: this.textApp.label00068, value: "agent", width: "5%" },
      { text: this.textApp.label00498, value: "hostname", width: "5%" },
      { text: this.textApp.label00279, value: "file", width: "40%" },
      { text: this.textApp.label00280, value: "event_description", width: "20%" },
      { text: this.textApp.label00499, value: "username", width: "10%" },
      { text: this.textApp.label00511, value: "process", width: "5%" },
    ];
    this.codes = {
      '4656': "A handle to an object was requested",
      '4658': "The handle to an object was closed",
      '4660': "An object was deleted",
      '4663': "An attempt was made to access an object",
      '4985': "The state of a transaction has changed"
    };
  },
  methods: {
    loadData(refresh) {
      if (refresh) {
        this.sizeResult = 0;
        this.listSecrets = [];
      }
      this.loading = true;
      this.axios({
        method: "GET",
        url: "/events/tradeSecret/listwin",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        params: {
          filterStartDate: this.filterStartDate.substring(
            0,
            this.filterStartDate.length - 5
          ),
          filterEndDate: this.filterEndDate.substring(
            0,
            this.filterStartDate.length - 5
          ),
          filter_name_file: this.textSearched,
          filter_id_agent: this.agentSelected,
          company: this.company.id,
          from: this.sizeResult,
        },
      }).then((resp) => {
        console.log(resp.data);
        if(!resp.data.Error) {
          resp.data.forEach((event, i) => {
            resp.data[i].timestamp = timezone.getLocalDateStr(event.timestamp).replaceAll(/T/g, " ");
            resp.data[i].event_description = this.codes[resp.data[i].event];
          });
          this.listSecrets = this.listSecrets.concat(resp.data);
          this.sizeResult = this.listSecrets.length;
        }
        
        this.loading = false;
      });
      this.axios({
        method: "GET",
        url: "/events/tradeSecret/totalCountWin",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        params: {
          company: this.company.id,
          filterStartDate: this.filterStartDate.substring(
            0,
            this.filterStartDate.length - 5
          ),
          filterEndDate: this.filterEndDate.substring(
            0,
            this.filterStartDate.length - 5
          ),
          filter_name_file: this.textSearched,
          filter_id_agent: this.agentSelected,
        },
      }).then((resp) => {
        console.log(resp.data);
        this.totalEvents = resp.data.count;
      });
    },
    showDetail(event) {
      this.dialog = true;
      this.detailData = event;
    },
    closeDialog() {
      this.dialog = false;
    },
    numberWithCommas(x) {
      if (x < 1000) {
        return x;
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    reloadFilters () {
      this.reloadButton ++;
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    filterStartDate: {
      get: function () {
        return this.$store.getters.getStartDate;
      },
      set: function (value) {
        this.$store.dispatch("setStartDate", value);
      },
    },
    filterEndDate: {
      get: function () {
        return this.$store.getters.getEndDate;
      },
      set: function (value) {
        this.$store.dispatch("setEndDate", value);
      },
    },
    filterAgents: {
      get: function () {
        return this.$store.getters.getAgents;
      },
      set: function (value) {
        this.$store.dispatch("setAgents", value);
      },
    },
    company: {
      get: function () {
        return this.$store.getters.getCompany;
      },
      set: function (value) {
        this.$store.dispatch("setCompany", value);
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    role: {
      get: function () {
        return this.$store.getters.getRole;
      },
      set: function (value) {
        this.$store.dispatch("setRole", value);
      },
    },
    reloadButton: {
      get: function() {
        return this.$store.getters.getReloadButton;
      },
      set: function(value) {
        this.$store.dispatch("setReloadButton", value);
      }
    },
  },
  watch: {
    filterStartDate() {
      this.loadData(true);
    },
    filterEndDate() {
      this.loadData(true);
    },
    filterAgents() {
      this.loadData(true);
    },
    token() {
      this.loadData(true);
    },
    dialog() {
      if (!this.dialog) {
        this.detailData = null;
      }
    },
    reloadButton() {
      this.loadData(true);
    }
  },
};
</script>
<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
