<template>
  <v-container>
    <v-dialog v-model="dialog" width="700">
      <template #activator="{ on: dialog }">
        <v-tooltip bottom>
          <template #activator="{ on: tooltip }">
            <v-btn
              color="secondary"
              small
              dark
              v-on="{ ...tooltip, ...dialog }"
            >
              {{textApp.label00128}}
            </v-btn>
          </template>
          <span>{{textApp.label00129}}</span>
        </v-tooltip>
      </template>
      <v-card>
        <v-card-title>
          <h4 :style="{ color: $vuetify.theme.themes.light.secondary }" class="mb-5">{{textApp.label00128}}</h4>
        </v-card-title>
        

        <v-card-text v-if="!loading && !error">
          <v-row>
            <v-col cols="12" sm="12" md="12" class="pb-0">
              <v-text-field
                :label="textApp.label00069"
                v-model="group"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-row v-if="loading" class="ma-0">
          <v-card-text style="padding-top: 50px; padding-bottom: 50px">
            <v-row align="center" justify="center">
              <v-progress-circular
                :size="120"
                :width="10"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-row>
          </v-card-text>
        </v-row>
        <v-card-text v-if="error">
          <v-alert outlined type="error">
            {{textApp.label00130}}
          </v-alert>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions v-if="!error">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()"> {{textApp.label00013}} </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> {{textApp.label00042}} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      group: null,
      loading: false,
      error: false
    };
  },
  mounted() {},
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    credentials: {
      get: function() {
        return this.$store.getters.getCredentials;
      },
      set: function(value) {
        this.$store.dispatch("setCredentials", value);
      }
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    }
  },
  methods: {
    closeDialog() {
      this.loading = true;
      this.axios({
        method: "POST",
        url: "/agents/groups/new",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        data: {
          company: this.company.id,
          group_id: this.group
        }
      }).then(resp => {
        console.log(resp.data);
        this.loading = false;

        if (!resp.data.Error) {
          this.dialog = false;
          this.$emit("refreshPage");
        } else {
          this.error = true;
        }
      });
    }
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.error = false;
        this.group = null;
        this.loading = false;
      }
    }
  }
};
</script>
<style scoped></style>
