<template>
  <span class="mr-5">
    <v-btn
      class="mb-5"
      color="primary"
      style="height: 40px;"
      @click="openNewCompany()"
    >
      {{textApp.label00025}}
      <v-icon right dark>
        mdi-domain
      </v-icon>
    </v-btn>
    <v-dialog v-model="dialogNewCompany" width="500">
      <v-card>
        <v-card-title>
          <h4 :style="{ color: $vuetify.theme.themes.light.secondary }" class="mb-5">
            {{textApp.label00025}}
          </h4>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
              <v-text-field
                :label="textApp.label00005"
                v-model="newCompany.name_company"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
              <v-text-field
                :label="textApp.label00026"
                v-model="newCompany.url_db"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
              <v-text-field
                :label="textApp.label00027"
                v-model="newCompany.url_elk"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
              <v-text-field
                :label="textApp.label00028"
                v-model="newCompany.usr_db"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
              <v-text-field
                :label="textApp.label00029"
                v-model="newCompany.psw_db"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
              <v-text-field
                :label="textApp.label00030"
                v-model="newCompany.usr_elk"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
              <v-text-field
                :label="textApp.label00031"
                v-model="newCompany.psw_elk"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
              <v-text-field
                :label="textApp.label00032"
                v-model="newCompany.index_elk"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
              <v-text-field
                :label="textApp.label00033"
                v-model="newCompany.db_name"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
              <v-text-field
                :label="textApp.label00034"
                v-model="newCompany.url_wazuh"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
              <v-text-field
                :label="textApp.label00035"
                v-model="newCompany.usr_wazuh"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
              <v-text-field
                :label="textApp.label00036"
                v-model="newCompany.psw_wazuh"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
              <v-text-field
                :label="textApp.label00037"
                v-model="newCompany.reg_port"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
              <v-text-field
                :label="textApp.label00038"
                v-model="newCompany.data_port"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogNewCompany = false">
            {{textApp.label00012}}
          </v-btn>
          <v-btn color="primary" text @click="confirmNewCompany()">
            {{textApp.label00013}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      dialogNewCompany: false,
      newCompany: {
        name_company: null,
        url_db: null,
        url_elk: null,
        usr_db: null,
        psw_db: null,
        usr_elk: null,
        psw_elk: null,
        index_elk: null,
        db_name: null,
        url_wazuh: null,
        usr_wazuh: null,
        psw_wazuh: null,
        reg_port: null,
        data_port: null
      }
    };
  },
  mounted() {},
  methods: {
    openNewCompany() {
      this.dialogNewCompany = true;
    },
    confirmNewCompany() {
      this.axios({
        method: "POST",
        url: `/admin/L1/company/create`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        data: this.newCompany
      }).then(resp => {
        console.log(resp.data);
        if (!resp.data.Error) {
          this.dialogNewCompany = false;
        } else {
          console.log("ERRORE CREAZIONE COMPANY");
          this.dialogNewCompany = false;
        }
      });
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      }
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
    token() {
      return this.$store.getters.getToken;
    }
  },
  watch: {}
};
</script>
<style scoped></style>
