<template>
  <v-container class="pa-0">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-hover v-slot="{ hover }">
          <v-card
            v-bind="attrs"
            v-on="on"
            outlined
            :elevation="hover ? 3 : 0"
            @click="dialogListLevels = true"
          >
            <v-card-text v-if="!loading" :style="{ height: heightStyle }">
              <h4
                class="mt-2"
                :style="{ color: $vuetify.theme.themes.light.secondary }"
              >
                {{textApp.label00199}}
              </h4>
              <v-divider class="mt-3 mb-3"></v-divider>
              <div style="color: #2E7D32">
                {{textApp.label00201}} {{ levelLow }} {{textApp.label00200}}
              </div>
              <div style="color: #FF8F00">
                {{textApp.label00202}} {{ levelMedium }} {{textApp.label00200}}
              </div>
              <div style="color: #E53935">
                {{textApp.label00203}} {{ levelHigh }} {{textApp.label00200}}
              </div>
            </v-card-text>

            <v-card-text v-else :style="{ height: heightStyle }">
              <v-row class="mt-5" align="center" justify="center">
                <v-progress-circular
                  :size="120"
                  :width="10"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-row>
            </v-card-text>
          </v-card>
        </v-hover>
      </template>
      <span>{{textApp.label00204}}</span>
    </v-tooltip>
    <v-dialog v-model="dialogListLevels" width="500">
      <v-card>
        <v-card-title :style="{ color: $vuetify.theme.themes.light.secondary }">
          <h4>{{textApp.label00205}}</h4>
        </v-card-title>

        <v-card-text>
          <SeverityLevelList
            :severity_levels="listLevels.severity_levels"
          ></SeverityLevelList>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogListLevels = false">
            {{textApp.label00042}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import SeverityLevelList from "./SeverityLevelList";
export default {
  components: {
    SeverityLevelList
  },
  data() {
    return {
      listLevels: [],
      levelLow: null,
      levelMedium: null,
      levelHigh: null,
      dialogListLevels: false,
      loading: false
    };
  },
  props: {
    heightStyle: {
      type: String,
      default: "270px"
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      this.axios({
        method: "GET",
        url: "/charts/events/severityLevel",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        params: {
          company: this.company.id,
          filterStartDate: this.filterStartDate.substring(
            0,
            this.filterStartDate.length - 5
          ),
          filterEndDate: this.filterEndDate.substring(
            0,
            this.filterStartDate.length - 5
          )
        }
      }).then(resp => {
        console.log(resp.data);
        if (!resp.data.Error) {
          this.listLevels = resp.data;
          this.levelLow = 0;
          this.levelMedium = 0;
          this.levelHigh = 0;
          resp.data.severity_levels.forEach(item => {
            if (item.severity_level <= 8) {
              this.levelLow += item.count;
            } else if (item.severity_level <= 12) {
              this.levelMedium += item.count;
            } else {
              this.levelHigh += item.count;
            }
          });
          this.loading = false;
        }
      });
    }
  },
  watch: {
    filterStartDate() {
      this.loadData();
    },
    filterEndDate() {
      this.loadData();
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
    filterStartDate: {
      get: function() {
        if (this.filters == null) {
          return this.$store.getters.getStartDate;
        } else {
          return this.filters.startDate;
        }
      },
      set: function(value) {
        if (this.filters == null) {
          this.$store.dispatch("setStartDate", value);
        }
      }
    },
    filterEndDate: {
      get: function() {
        if (this.filters == null) {
          return this.$store.getters.getEndDate;
        } else {
          return this.filters.endDate;
        }
      },
      set: function(value) {
        this.$store.dispatch("setEndDate", value);
      }
    },
    warningTotalCount() {
      return this.listWarning.length;
    },
    warningOpenCount() {
      return this.listWarning.filter(obj => {
        if (obj.id_status == 1) {
          return true;
        }
        return false;
      }).length;
    }
  }
};
</script>
<style scoped></style>
