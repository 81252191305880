<template>
  <v-container>
    <v-row align="center" justify="center">
      <AgentOS></AgentOS>
      <AgentHardware></AgentHardware>
      <AgentInventoryData></AgentInventoryData>
    </v-row>
  </v-container>
</template>

<script>
import AgentInventoryData from "../components/AgentInventoryData";
import AgentHardware from "../components/AgentHardware";
import AgentOS from "../components/AgentOS";

export default {
  components: {
    AgentInventoryData,
    AgentHardware,
    AgentOS
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {}
};
</script>
<style scoped></style>
