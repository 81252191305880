var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-5 ml-3",staticStyle:{"height":"40px"},attrs:{"color":"primary"}},Object.assign({}, tooltip, dialog)),[_vm._v(" "+_vm._s(_vm.textApp.label00502)+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-format-list-bulleted ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.textApp.label00503))])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('h4',{style:({ color: _vm.$vuetify.theme.themes.light.secondary })},[_vm._v(_vm._s(_vm.textApp.label00502))])]),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-tabs',{staticClass:"mb-4 mt-4",attrs:{"align-with-title":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider'),_c('v-tab',{key:"win"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-microsoft-windows")]),_vm._v(" "+_vm._s(_vm.textApp.label00504)+" ")],1),_c('v-tab',{key:"deb"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-linux")]),_vm._v(" "+_vm._s(_vm.textApp.label00505)+" ")],1)],1)],1),_c('v-tabs-items',{staticClass:"mt-2",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.textApp.label00506)+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.textApp.label00507)+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.textApp.label00508)+" ")])])]),_c('tbody',_vm._l((_vm.listInstallerWin),function(item){return _c('tr',{key:item.name},[_c('td',[_vm._v(_vm._s(item.tag))]),_c('td',[_vm._v(_vm._s(_vm.timezone.getLocalDateStr(item.tstp).replace("T", ' ')))]),_c('td',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":"","dark":"","fab":"","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.downloadMsi(item)}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.textApp.label00509))])])],1)])}),0)]},proxy:true}])})],1)],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.textApp.label00506)+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.textApp.label00507)+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.textApp.label00508)+" ")])])]),_c('tbody',_vm._l((_vm.listInstallerDeb),function(item){return _c('tr',{key:item.name},[_c('td',[_vm._v(_vm._s(item.tag))]),_c('td',[_vm._v(_vm._s(_vm.timezone.getLocalDateStr(item.tstp).replace("T", ' ')))]),_c('td',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":"","dark":"","fab":"","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.downloadDeb(item)}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.textApp.label00510))])])],1)])}),0)]},proxy:true}])})],1)],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" "+_vm._s(_vm.textApp.label00164)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }