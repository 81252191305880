var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"align":"start","justify":"start"}},[_c('Warning',{on:{"refreshPage":function($event){return _vm.loadData()}}}),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',{staticClass:"pl-5 pr-5",attrs:{"justify":"start"}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.textApp.label00084,"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',{attrs:{"justify":"start"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.listWarning,"search":_vm.search,"footer-props":_vm.footerProps,"dense":""},scopedSlots:_vm._u([{key:"item",fn:function(row){return [_c('tr',[_c('td',[_vm._v(_vm._s(row.item.id))]),_c('td',[_vm._v(" "+_vm._s(((row.item.tstp.substring( 0, 10 )) + " " + (row.item.tstp.substring(11, 16))))+" ")]),_c('td',[_vm._v(_vm._s(row.item.title))]),_c('td',[_vm._v(" "+_vm._s(row.item.sender_name)+" "+_vm._s(row.item.sender_surname)+" ")]),(row.item.id_status == 1)?_c('td',[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red"}},Object.assign({}, onMenu, onTooltip)),[_vm._v(" mdi-checkbox-blank-circle ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(row.item.status_name))])])]}}],null,true)},[_c('v-card',[_c('v-card-text',[_c('h4',{style:({ color: _vm.$vuetify.theme.themes.light.secondary })},[_vm._v(_vm._s(_vm.textApp.label00157)+" "+_vm._s(row.item.id))]),_c('p',[_c('b',[_vm._v(_vm._s(row.item.object))])]),_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.setWarningStatus(row.item.id, 2)}}},[_vm._v(" "+_vm._s(_vm.textApp.label00158)+" ")]),_c('v-btn',{attrs:{"small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.setWarningStatus(row.item.id, 3)}}},[_vm._v(" "+_vm._s(_vm.textApp.label00159)+" ")])],1)],1)],1)],1):_vm._e(),(row.item.id_status == 2)?_c('td',[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"orange"}},Object.assign({}, onMenu, onTooltip)),[_vm._v(" mdi-checkbox-blank-circle ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(row.item.status_name))])])]}}],null,true)},[_c('v-card',[_c('v-card-text',[_c('h4',[_vm._v(_vm._s(_vm.textApp.label00157)+" "+_vm._s(row.item.ID))]),_c('p',[_c('b',[_vm._v(_vm._s(row.item.object))])]),_c('v-btn',{attrs:{"small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.setWarningStatus(row.item.id, 3)}}},[_vm._v(" "+_vm._s(_vm.textApp.label00159)+" ")])],1)],1)],1)],1):_vm._e(),(row.item.id_status == 3)?_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green"}},'v-icon',attrs,false),on),[_vm._v(" mdi-checkbox-blank-circle ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(row.item.status_name))])])],1):_vm._e(),_c('td',[_c('WarningComplete',{attrs:{"warning":row.item}})],1),(row.item.id_status <= 2)?_c('td',[_c('ResponseWarning',{attrs:{"warning":row.item}})],1):_c('td'),(row.item.metadata != '{}')?_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"text":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.generateReport(row.item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.textApp.label00160))])])],1):_c('td')])]}},{key:"no-data",fn:function(){return [_c('v-card-text',[_vm._v(" "+_vm._s(_vm.textApp.label00089)+" ")])]},proxy:true}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }