import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { auth } from "./auth.js";
import { reg } from "./registration.js";
import { filters } from "./filters.js";
import { text } from "./text.js";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ],
  state: {},
  mutations: {},
  actions: {},
  modules: { auth, reg, filters, text }
});
