<template>
  <v-dialog v-model="dialog" width="800">
    <template #activator="{ on: dialog }">
      <v-tooltip bottom>
        <template #activator="{ on: tooltip }">
          <v-btn
            color="primary"
            
            dark
            v-on="{ ...tooltip, ...dialog }"
          >
            {{textApp.label00446}}
          </v-btn>
        </template>
        <span>{{textApp.label00447}}</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-card-title>
        <h4 :style="{ color: $vuetify.theme.themes.light.secondary }">{{textApp.label00448}}</h4>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-row class="pt-5" justify="center">
          <h4 :style="{ color: $vuetify.theme.themes.light.secondary }" class="mt-3">{{textApp.label00449}}</h4>
          <v-expansion-panels accordion class="mt-5">
            <v-expansion-panel
              v-for="(item, i) in listConf" :key="i"
            >
              <v-expansion-panel-header>{{item.name}}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row v-for="(rule, j) in item.data_conf" :key="j">
                  <v-col class="pb-3 mb-3" cols="12" sm="12" md="12" v-if="rule == true">
                    {{textApp.label00450}}
                  </v-col>
                  <v-row v-else>
                    <v-col class="pb-0 mb-0 mt-4" cols="12" sm="12" md="12">
                      <v-text-field
                        class="mb-0"
                        :label="textApp.label00306"
                        v-model="rule.path"
                        outlined
                        dense
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0 pt-0" cols="12" sm="6" md="3">
                      <v-switch
                        v-model="rule.check_all"
                        flat
                        disabled
                        :label="textApp.label00307"
                      ></v-switch>
                    </v-col>
                    <v-col class="pb-0 pt-0" cols="12" sm="6" md="3">
                      <v-switch
                        v-model="rule.realtime"
                        flat
                        disabled
                        :label="textApp.label00308"
                      ></v-switch>
                    </v-col>
                    <v-col class="pb-0 pt-0" cols="12" sm="6" md="3">
                      <v-switch
                        v-model="rule.report_changes"
                        flat
                        disabled
                        :label="textApp.label00309"
                      ></v-switch>
                    </v-col>
                    <v-col class="pb-0 pt-0" cols="12" sm="6" md="3">
                      <v-switch
                        v-model="rule.whodata"
                        flat
                        disabled
                        :label="textApp.label00310"
                      ></v-switch>
                    </v-col>
                    <v-col class="pb-0 pt-0" cols="12" sm="6" md="3">
                      <v-text-field
                        class="mb-0"
                        :label="textApp.label00311"
                        v-model="rule.recursion_level"
                        outlined
                        dense
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog()"> {{textApp.label00042}} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      listConf: []
    };
  },
  mounted() {
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    }
  },
  watch: {
    dialog() {
      if(this.dialog) {
        this.axios({
          method: "GET",
          url: "/events/tradeSecret/configurations",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.token}`
          },
          params: {
            company: this.company.id
          }
        }).then(resp => {
          console.log(resp.data);
          this.listConf = resp.data;     
        });
      }
    }
  }
};
</script>
<style scoped></style>
