var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"12"}},_vm._l((_vm.severity_levels),function(alert,q){return _c('div',{key:alert + q},[(alert.severity_level == 1)?_c('div',{staticStyle:{"height":"8px","width":"8px","background-color":"#2E7D32","border-radius":"50%","display":"inline-block"}}):_vm._e(),_c('div',{staticStyle:{"border-left":"6px solid #2E7D32"}},[(alert.severity_level <= 8 && alert.count > 0)?_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.textApp.label00211)+" "+_vm._s(alert.severity_level)+": "),_c('b',[_vm._v(_vm._s(alert.count))]),_vm._v(" "+_vm._s(_vm.textApp.label00200))]):_vm._e(),(alert.severity_level <= 8 && alert.count == 0)?_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.textApp.label00211)+" "+_vm._s(alert.severity_level)+": "+_vm._s(alert.count)+" "+_vm._s(_vm.textApp.label00200))]):_vm._e()]),(alert.severity_level == 8)?_c('div',{staticStyle:{"height":"8px","width":"8px","background-color":"#FF8F00","border-radius":"50%","display":"inline-block"}}):_vm._e(),_c('div',{staticStyle:{"border-left":"6px solid #FF8F00"}},[(
              alert.severity_level > 8 &&
                alert.severity_level <= 12 &&
                alert.count > 0
            )?_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.textApp.label00211)+" "+_vm._s(alert.severity_level)+": "),_c('b',[_vm._v(_vm._s(alert.count))]),_vm._v(" "+_vm._s(_vm.textApp.label00200))]):_vm._e(),(
              alert.severity_level > 8 &&
                alert.severity_level <= 12 &&
                alert.count == 0
            )?_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.textApp.label00211)+" "+_vm._s(alert.severity_level)+": "+_vm._s(alert.count)+" "+_vm._s(_vm.textApp.label00200))]):_vm._e()]),(alert.severity_level == 12)?_c('div',{staticStyle:{"height":"8px","width":"8px","background-color":"#E53935","border-radius":"50%","display":"inline-block"}}):_vm._e(),_c('div',{staticStyle:{"border-left":"6px solid #E53935"}},[(alert.severity_level > 12 && alert.count > 0)?_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.textApp.label00211)+" "+_vm._s(alert.severity_level)+": "),_c('b',[_vm._v(_vm._s(alert.count))]),_vm._v(" "+_vm._s(_vm.textApp.label00200))]):_vm._e(),(alert.severity_level > 12 && alert.count == 0)?_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.textApp.label00211)+" "+_vm._s(alert.severity_level)+": "+_vm._s(alert.count)+" "+_vm._s(_vm.textApp.label00200))]):_vm._e()])])}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }