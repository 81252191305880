<template>
  <v-container>
    <v-card outlined>
      <v-card-text>
        <h3 :style="{ color: $vuetify.theme.themes.light.secondary }">{{textApp.label00068}} {{ agent }}</h3>
      </v-card-text>
      <v-row v-if="!loading">
        <v-col cols="12" md="3">
          <v-card-text>
            <h4>{{textApp.label00114}}</h4>
            <span>{{ hostname }}</span>
          </v-card-text>
        </v-col>
        <v-col cols="12" md="3">
          <v-card-text>
            <h4>{{textApp.label00115}}</h4>
            <div>{{ os }}</div>
          </v-card-text>
        </v-col>
        <v-col cols="12" md="3">
          <v-card-text>
            <h4>{{textApp.label00116}}</h4>
            <div>{{ osVersion }}</div>
          </v-card-text>
        </v-col>
        <v-col cols="12" md="3">
          <v-card-text>
            <h4>{{textApp.label00117}}</h4>
            <div>{{ architecture }}</div>
          </v-card-text>
        </v-col>
      </v-row>
      <v-row v-if="loading">
        <v-card-text style="padding-top: 50px; padding-bottom: 50px">
          <v-row align="center" justify="center">
            <v-progress-circular
              :size="120"
              :width="10"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </v-card-text>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loading: false,
      os: null,
      osVersion: null,
      hostname: null,
      architecture: null
    };
  },
  props: ['data', 'id_agent'],
  mounted() {
    if(this.data != null) {
      this.os = this.data[0].os.name;
      this.osVersion = this.data[0].os.version;
      this.hostname = this.data[0].hostname;
      this.architecture = this.data[0].architecture;
    } else {
      this.loadData();
    }
  },
  methods: {
    loadData() {
      this.loading = true;
      this.axios({
        method: "GET",
        url: "/agents/os",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        params: {
          agent: this.agent,
          company: this.company.id
        }
      }).then(resp => {
        console.log(resp.data);
        if (!resp.data.Error && resp.data.length > 0) {
          this.os = resp.data[0].os.name;
          this.osVersion = resp.data[0].os.version;
          this.hostname = resp.data[0].hostname;
          this.architecture = resp.data[0].architecture;
        }
        this.loading = false;
      });
    },
    KBtoGB(data) {
      return (data / 1024 / 1024).toFixed(2);
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      }
    },
    agent() {
      if(this.$route.params.idAgent == undefined || this.$route.params.idAgent == null) {
        return this.id_agent;
      }
      return this.$route.params.idAgent;
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    }
  }
};
</script>
<style scoped></style>
