<template>
  <v-container class="pt-0">
    <v-card outlined class="pl-5 pr-5 pb-5">
      <v-card-text><h4 :style="{ color: $vuetify.theme.themes.light.secondary }">{{textApp.label00131}}</h4></v-card-text>
      <v-row justify="start">
        <v-data-table
          class="ma-0"
          :headers="headers"
          :items="listGroups"
          :search="search"
          :footer-props="footerProps"
          @click:row="viewGroupDetail"
          style="cursor: pointer;"
        >
          <template v-slot:no-data>
            <v-card-text>
              {{textApp.label00089}}
            </v-card-text>
          </template>
          <template v-slot:item="row">
            <tr @click="viewGroupDetail(row.item)">
              <td>{{ row.item.name }}</td>
              <td>{{ row.item.count }}</td>
              <td>
                <DeleteGroupAgent
                  :group="row.item.name"
                  v-on:refreshPage="loadData()"
                ></DeleteGroupAgent>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-row>
      <v-row v-if="loading">
        <v-card-text style="padding-top: 50px; padding-bottom: 50px">
          <v-row align="center" justify="center">
            <v-progress-circular
              :size="120"
              :width="10"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </v-card-text>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import DeleteGroupAgent from "./DeleteGroupAgent";
export default {
  components: {
    DeleteGroupAgent
  },
  data() {
    return {
      listGroups: [],
      loading: false,
      search: "",
      headers: [],
      footerProps: null,
    };
  },
  props: ["refresh"],
  mounted() {
    this.loadData();
    this.headers = [
      { text: this.textApp.label00005, value: "name" },
      { text: this.textApp.label00132, value: "count" },
      { text: this.textApp.label00133, value: "" }
    ];
    this.footerProps = {
      itemsPerPageText: this.textApp.label00095,
      pageText: `{0}-{1} ${this.textApp.label00096} {2}`,
      "items-per-page-all-text": this.textApp.label00097,
    };
  },
  methods: {
    loadData() {
      this.loading = true;
      this.axios({
        method: "GET",
        url: "/agents/groups",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        params: {
          company: this.company.id
        }
      }).then(resp => {
        console.log(resp.data);
        this.listGroups = resp.data;
        this.loading = false;
      });
    },
    viewGroupDetail(group) {
      this.$router.push("/groupdetail/" + group.name);
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
    token() {
      return this.$store.getters.getToken;
    }
  },
  watch: {
    token() {
      this.loadData();
    },
    refresh() {
      this.loadData();
    }
  }
};
</script>
<style scoped></style>
