<template>
  <v-container class="pt-0">
    <v-card outlined class="pl-5 pr-5 pb-5">
      <v-card-text
        :style="{ color: $vuetify.theme.themes.light.secondary }"
        class="mt-5"
      >
        <h4>{{ textApp.label00083 }}</h4>
      </v-card-text>
      <v-row justify="start">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="textApp.label00084"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
      </v-row>
      <v-row justify="start">
        <v-data-table
          class="ma-0"
          :headers="headers"
          :items="listAgents"
          :search="search"
          :footer-props="footerProps"
          @click:row="viewAgentDetail"
          style="cursor: pointer"
        >
          <template v-slot:item.status="{ item }">
            <span v-if="item.status == 'active'" style="color: green">
              {{ textApp.label00085 }}
            </span>
            <span v-if="item.status == 'disconnected'" style="color: red">
              {{ textApp.label00086 }}
            </span>
            <span v-if="item.status == 'never_connected'" style="color: brown">
              {{ textApp.label00087 }}
            </span>
            <span v-if="item.status == 'pending'" style="color: darkgray">
              {{ textApp.label00088 }}
            </span>
          </template>
          <template v-slot:item.vulnerability="item">
            <v-row v-if="loadingVulnStats" align="center" justify="center">
              <v-progress-circular
                :size="20"
                :width="2"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-row>
            <VulnerabilityAgentStats v-else :agent="item.item.id" :vulnstats="item.item.id in vulnStats? vulnStats[item.item.id] : null"></VulnerabilityAgentStats>
          </template>
          <template v-slot:item.menu="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="secondary"
                  small
                  dark
                  fab
                  outlined
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title>
                    <OpenRDP
                      :agent="item"
                    ></OpenRDP>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <OpenRemoteFileSystem
                      :agent="item"
                    ></OpenRemoteFileSystem>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <OpenRemoteShell
                      :agent="item"
                    ></OpenRemoteShell>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <DismissAgent
                      :agent="item"
                      @reloadListAgents="loadData()"
                    >
                    </DismissAgent>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            
          </template>
          <template v-slot:no-data>
            <v-card-text>
              {{ textApp.label00089 }}
            </v-card-text>
          </template>
        </v-data-table>
      </v-row>
      <v-row v-if="loading">
        <v-card-text style="padding-top: 50px; padding-bottom: 50px">
          <v-row align="center" justify="center">
            <v-progress-circular
              :size="120"
              :width="10"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </v-card-text>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import DismissAgent from "../components/DismissAgent"
import OpenRDP from "../components/OpenRDP"
import OpenRemoteFileSystem from "../components/OpenRemoteFileSystem"
import OpenRemoteShell from "../components/OpenRemoteShell"
import VulnerabilityAgentStats from "./VulnerabilityAgentStats.vue"
export default {
  components: {
    DismissAgent,
    OpenRDP,
    OpenRemoteFileSystem,
    OpenRemoteShell,
    VulnerabilityAgentStats
  },
  data() {
    return {
      search: "",
      headers: [],
      listAgents: [],
      listGroups: [],
      loading: false,
      footerProps: null,
      dialogConfirmAgent: false,
      vulnStats: null,
      loadingVulnStats: false
    };
  },
  props: ["group"],
  mounted() {
    this.loadData();
    this.headers = [
      {
        text: this.textApp.label00056,
        align: "start",
        value: "id",
      },
      { text: this.textApp.label00005, value: "name" },
      { text: this.textApp.label00090, value: "os.name" },
      { text: this.textApp.label00091, value: "os.version" },
      { text: this.textApp.label00092, value: "lastKeepAlive" },
      { text: this.textApp.label00093, value: "version" },
      { text: this.textApp.label00094, value: "status" },
      { text: this.textApp.label00402, value: "vulnerability" },
      { text: "", value: "menu" },
    ];
    this.footerProps = {
      itemsPerPageText: this.textApp.label00095,
      pageText: `{0}-{1} ${this.textApp.label00096} {2}`,
      "items-per-page-all-text": this.textApp.label00097,
    };
  },
  methods: {
    loadData() {
      this.loading = true;
      this.axios({
        method: "GET",
        url: "/agents/list",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        params: {
          group: this.group,
          company: this.company.id,
        },
      }).then((resp) => {
        console.log(resp.data);
        if (!resp.data.Error) {
          this.listAgents = resp.data;
          this.loadVulnerability();
        }
        this.loading = false;
      });
      this.axios({
        method: "GET",
        url: "/agents/groups",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        params: {
          company: this.company.id,
        },
      }).then((resp) => {
        console.log(resp.data);
        this.listGroups = resp.data;
      });
    },
    loadVulnerability () {
      this.loadingVulnStats = true;
      this.axios({
        method: "GET",
        url: "/charts/vulnerability/programs/agents",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        params: {
          filterStartDate: this.filterStartDate.substring(
            0,
            this.filterStartDate.length - 5
          ),
          filterEndDate: this.filterEndDate.substring(
            0,
            this.filterStartDate.length - 5
          ),
          company: this.company.id
        }
      }).then(resp => {
        console.log(resp.data);
        this.vulnStats = resp.data;
        this.loadingVulnStats = false;
      });
    },
    viewAgentDetail(agent) {
      if (this.group == undefined) {
        this.$router.push("/agentdetail/" + agent.id);
      } else {
        this.$router.push(`/groupdetail/${this.group}/agentdetail/${agent.id}`);
      }
    },
    dismissAgent() {
      this.dialogConfirmAgent = true;
    },
    confirmDismissAgent(agent) {
      this.axios({
        method: "POST",
        url: "/agents/dismiss",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          company: this.company.id,
          agent: agent.id,
        },
      }).then((resp) => {
        console.log(resp.data);
        this.closeDialog();
      });
    },
    closeDialog() {
      this.dialogConfirmAgent = false;
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    company: {
      get: function () {
        return this.$store.getters.getCompany;
      },
      set: function (value) {
        this.$store.dispatch("setCompany", value);
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    filterStartDate: {
      get: function() {
        return this.$store.getters.getStartDate;
      },
      set: function(value) {
        this.$store.dispatch("setStartDate", value);
      }
    },
    filterEndDate: {
      get: function() {
        return this.$store.getters.getEndDate;
      },
      set: function(value) {
        this.$store.dispatch("setEndDate", value);
      }
    },
    filterAgents: {
      get: function() {
        return this.$store.getters.getAgents;
      },
      set: function(value) {
        this.$store.dispatch("setAgents", value);
      }
    },
  },
  watch: {
    token() {
      this.loadData();
    },
  },
};
</script>
<style scoped></style>
