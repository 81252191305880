<template>
  <v-container class="pr-5 pl-5">
    <v-row v-if="!loading">
      <v-col class="pb-0 pt-0">
        <v-select
          v-model="baseCompanySelected"
          :label="textApp.label00014"
          :items="listCompany"
          item-text="name"
          item-value="id"
          outlined
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="baseCompanySelected != null" class="ml-5 mr-5">
      <v-btn
        class="mb-5"
        color="primary"
        style="height: 40px;"
        @click="newUser()"
      >
        {{textApp.label00004}}
        <v-icon right dark>
          mdi-plus
        </v-icon>
      </v-btn>
      <ListInstaller :target_company="baseCompanySelected" adminLevel="L2"></ListInstaller>
    </v-row>
    <v-dialog v-model="dialogNewUser" width="500">
      <v-card>
        <v-card-title>
          <h4
            :style="{ color: $vuetify.theme.themes.light.secondary }"
            class="mb-5"
          >
            {{textApp.label00004}}
          </h4>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" sm="12" class="pb-0">
              <v-text-field
                :label="textApp.label00005"
                v-model="userToCreate.name"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="pb-0">
              <v-text-field
                :label="textApp.label00006"
                v-model="userToCreate.surname"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
              <v-text-field
                :label="textApp.label00007"
                v-model="userToCreate.email"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
              <v-text-field
                :label="textApp.label00008"
                v-model="userToCreate.password"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
              <v-select
                v-model="userToCreate.role"
                :label="textApp.label00010"
                :items="listRoles"
                item-text="name"
                item-value="id"
                outlined
                clearable
              ></v-select>
            </v-col>
            <v-col
              v-if="adminLevel == 'L2'"
              cols="12"
              md="12"
              sm="12"
              class="pb-0 pt-0"
            >
              <v-switch
                v-model="userToCreateSetToAdmin"
                :label="textApp.label00064"
              ></v-switch>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
              <v-switch
                v-model="sendMailAfterCreation"
                :label="textApp.label00011"
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogNewUser = false">
            {{ textApp.label00012 }}
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="confirmNewUser()"
            :disabled="disableBtnConfirm"
          >
            {{ textApp.label00013 }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AdminUserList
      :refreshPage="counterUpdate"
      :adminLevel="adminLevel"
      :baseCompanySelected="baseCompanySelected"
    ></AdminUserList>
    <v-row v-if="loading" align="center" justify="center">
      <v-progress-circular
        :size="120"
        :width="10"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-row>
  </v-container>
</template>

<script>
import AdminUserList from "../components/AdminUserList";
import ListInstaller from "../components/ListInstaller";
export default {
  components: {
    AdminUserList,
    ListInstaller
  },
  data() {
    return {
      listCompany: [],
      baseCompanySelected: null,
      adminLevel: "L2",
      loading: true,
      dialogNewUser: false,
      userToCreate: {
        name: null,
        surname: null,
        email: null,
        password: null,
        role: null
      },
      listRoles: [
        {
          name: "Dirigente",
          id: 1
        },
        {
          name: "Privacy Manager",
          id: 2
        },
        {
          name: "IT",
          id: 3
        }
      ],
      companySelected: null,
      //companyAdminSelected: null,
      userToCreateSetToAdmin: null,
      listCompanies: [
        {
          name: "DEMO",
          id: 12
        },
        {
          name: "Khronos",
          id: 1
        },
        {
          name: "Chimera",
          id: 7
        }
      ],
      sendMailAfterCreation: true,
      counterUpdate: 0
    };
  },
  mounted() {
    this.axios({
      method: "GET",
      url: "/admin/L2/company/list",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`
      },
      params: {
        company: this.company.id
      }
    }).then(resp => {
      console.log(resp.data);
      if (!resp.data.Error) {
        let temp = [];
        resp.data.forEach(company => {
          let obj = {};
          obj["name"] = company.name_company;
          obj["id"] = company.id_company;
          temp.push(obj);
        });
        this.listCompany = temp;
        this.loading = false;
      }
    });
  },
  methods: {
    newUser() {
      this.dialogNewUser = true;
    },
    confirmNewUser() {
      this.createUser();
    },
    createUser() {
      console.log("NEW USER");
      this.axios({
        method: "POST",
        url: `/admin/${this.adminLevel}/user/create`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        data: {
          company: this.company.id,
          target_company: this.baseCompanySelected,
          name_usr: this.userToCreate.name,
          surname_usr: this.userToCreate.surname,
          email_usr: this.userToCreate.email,
          psw_usr: this.userToCreate.password
        }
      }).then(resp => {
        console.log(resp.data);
        if (!resp.data.Error) {
          this.setCompany(resp.data.insertId);
        } else {
          console.log("ERRORE CREAZIONE UTENTE");
          this.dialogNewUser = false;
        }
      });
    },
    setCompany(idUser) {
      console.log("SET COMPANY");
      this.axios({
        method: "POST",
        url: `/admin/${this.adminLevel}/user/setCompany`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        data: {
          company: this.company.id,
          target_company: this.baseCompanySelected,
          target_usr_id: idUser,
          target_usr_company: this.baseCompanySelected
        }
      }).then(resp => {
        console.log(resp.data);
        if (!resp.data.Error) {
          this.setRole(idUser);
        } else {
          console.log("ERRORE ASSEGNAZIONE COMPANY");
          this.dialogNewUser = false;
        }
      });
    },
    setRole(idUser) {
      console.log("SET ROLE");
      this.axios({
        method: "POST",
        url: `/admin/${this.adminLevel}/user/setRoles`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        data: {
          company: this.company.id,
          target_company: this.baseCompanySelected,
          target_usr_id: idUser,
          target_usr_company: this.baseCompanySelected,
          target_roles_id: [this.userToCreate.role]
        }
      }).then(resp => {
        console.log(resp.data);
        if (!resp.data.Error) {
          this.setFunctionalities(idUser);
        } else {
          console.log("ERRORE ASSEGNAMENTO RUOLO");
          this.dialogNewUser = false;
        }
      });
    },
    setFunctionalities(idUser) {
      console.log("SET FUNCT");
      this.axios({
        method: "POST",
        url: `/admin/${
          this.adminLevel
        }/user/${this.chooseFunctionalitiesFromRole(this.userToCreate.role)}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        data: {
          company: this.company.id,
          target_company: this.baseCompanySelected,
          target_usr_id: idUser,
          target_usr_company: this.baseCompanySelected
        }
      }).then(resp => {
        console.log(resp.data);
        if (!resp.data.Error) {
          if (this.userToCreateSetToAdmin) {
            this.setAdminL3(idUser);
          } else {
            this.dialogNewUser = false;
            this.refreshPage();
            if (this.sendMailAfterCreation) {
              this.sendMailPassword(idUser);
            }
          }
        } else {
          console.log("ERRORE ASSEGNAMENTO FUNZIONALITA'");
          this.dialogNewUser = false;
        }
      });
    },
    setAdminL3(idUser) {
      console.log("SET ADMIN L3");
      this.axios({
        method: "POST",
        url: `/admin/${this.adminLevel}/user/setL3Permission`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        data: {
          company: this.company.id,
          target_usr_id: idUser,
          target_usr_company: this.baseCompanySelected
        }
      }).then(resp => {
        console.log(resp.data);
        if (!resp.data.Error) {
          this.dialogNewUser = false;
          this.refreshPage();
          if (this.sendMailAfterCreation) {
            this.sendMailPassword(idUser);
          }
        } else {
          console.log("ERRORE ASSEGNAMENTO FUNZIONALITA'");
          this.dialogNewUser = false;
        }
      });
    },
    sendMailPassword(idUser) {
      console.log("SEND MAIL");
      this.axios({
        method: "POST",
        url: `/admin/${this.adminLevel}/user/sendMailPassword`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        data: {
          company: this.baseCompanySelected,
          id_usr: idUser
        }
      }).then(resp => {
        console.log(resp.data);
      });
    },
    chooseFunctionalitiesFromRole(role) {
      switch (role) {
        case 1:
          return "setFunctionalitiesDirigente";
        case 2:
          return "setFunctionalitiesDPO";
        case 3:
          return "setFunctionalitiesIT";
      }
    },
    refreshPage() {
      this.counterUpdate++;
      this.userToCreate = {
        name: null,
        surname: null,
        email: null,
        password: null,
        role: null
      };
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      }
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
    disableBtnConfirm() {
      return (
        this.userToCreate.name == null ||
        this.userToCreate.surname == null ||
        this.userToCreate.email == null ||
        this.userToCreate.password == null
      );
    }
  },
  watch: {}
};
</script>
<style scoped></style>
