<template>
  <v-container class="pa-0">
    <v-card outlined>
      <v-card-text v-if="!loading" class="pl-5">
        <v-row align="center" justify="space-between">
          <h4
            class="ml-5 mt-5"
            :style="{ color: $vuetify.theme.themes.light.secondary }"
          >
            {{ title }}
          </h4>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                v-if="dataChart.labels.length > 0"
                class="mr-5 mt-1"
                color="secondary"
                icon
                @click="dialog = true"
              >
                <v-icon>mdi-arrow-expand</v-icon>
              </v-btn>
            </template>
            <span>{{ textApp.label00075 }}</span>
          </v-tooltip>
        </v-row>

        <v-row>
          <v-divider class="mt-3 ml-2 mr-2"></v-divider>
        </v-row>

        <v-card
          v-if="this.dataChart.labels.length == 0"
          class="elevation-0 ma-3"
        >
          <v-card-text>
            <v-row style="height: 100px" align="center" justify="center">
              <p class="ma-5">
                <v-icon class="mr-5">mdi-alert</v-icon>{{ textApp.label00076 }}
              </p>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row v-else align="center" justify="space-between">
          <v-col cols="12" md="12" lg="9">
            <bar-chart
              class="mt-3"
              :chart-data="dataChart"
              :options="options"
              :width="5"
              :height="2"
            ></bar-chart>
          </v-col>
          <v-col
            cols="12"
            md="12"
            lg="3"
            :style="{ 'overflow-y': 'auto', height: heightStyle }"
          >
            <v-list dense disabled>
              <v-subheader>{{ textApp.label00077 }}</v-subheader>
              <v-list-item-group
                ><!-- v-model="selectedItem" -->
                <v-list-item
                  v-for="(item, i) in dataChart.labels"
                  :key="i"
                  style="height: 5px"
                >
                  <v-list-item-icon>
                    <v-icon :color="dataChart.datasets[0].backgroundColor[i]"
                      >mdi-checkbox-blank-circle</v-icon
                    >
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      dataChart.datasets[0].data[i]
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else style="padding-top: 50px; padding-bottom: 50px">
        <v-row align="center" justify="center">
          <v-progress-circular
            :size="120"
            :width="10"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-row>
      </v-card-text>
      <v-dialog v-model="dialog" width="800">
        <v-card>
          <v-card-title>
            <h4
              :style="{ color: $vuetify.theme.themes.light.secondary }"
              class="mb-5"
            >
              {{ title }}
            </h4>
          </v-card-title>

          <v-card-text>
            <v-row align="center" justify="space-between">
              <v-col cols="12" md="12" lg="5">
                <bar-chart
                  :chart-data="dataChart"
                  :options="options"
                  :width="1"
                  :height="1"
                ></bar-chart>
              </v-col>
              <v-col cols="12" md="12" lg="7">
                <v-list dense>
                  <v-subheader>{{ textApp.label00077 }}</v-subheader>
                  <v-list-item
                    three-line
                    v-for="(item, i) in dataChart.labels"
                    :key="i"
                    style="height: 5px"
                  >
                    <v-list-item-icon>
                      <v-icon :color="dataChart.datasets[0].backgroundColor[i]"
                        >mdi-checkbox-blank-circle</v-icon
                      >
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        dataChart.descriptions[i]
                      }}</v-list-item-subtitle>
                      <v-list-item-subtitle>{{
                        dataChart.datasets[0].data[i]
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action
                      v-if="type_code != null && type_label != null"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :disabled="false"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="exploreFilter(dataChart, i)"
                          >
                            <v-icon color="grey lighten-1"
                              >mdi-eye-outline</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>{{ textApp.label00436 }}</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                  
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false"> Chiudi </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import BarChart from "../components/BarChart.js";
import colors from "../config/color-charts";
export default {
  components: {
    BarChart,
  },
  data() {
    return {
      loading: false,
      dataChart: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      },
      options: {
        legend: {
          display: false,
          position: "right",
          responsive: false,
        },
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
      dialog: false,
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    linkAPI: {
      type: String,
      required: false,
    },
    agent: {
      type: String,
      required: false,
    },
    widthChart: {
      type: Number,
      default: 4,
    },
    heightChart: {
      type: Number,
      default: 3,
    },
    heightStyle: {
      type: String,
      default: "350px",
    },
    filters: {
      type: Object,
      default: null,
    },
    colorsSet: {
      type: String,
      default: "default",
    },
    type_code: {
      type: String,
      default: null,
    },
    type_label: {
      type: String,
      default: null,
    },
    data: {
      type: Object,
      default: null
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    setDataChart(l, d, desc, codes) {
      this.dataChart = {
        labels: l,
        descriptions: desc,
        codes: codes,
        datasets: [
          {
            backgroundColor: this.colorsChart,
            data: d,
          },
        ],
      };
    },
    loadData() {
      this.setDataChart([], [], [], []);
      if(this.linkAPI != null) {
        this.loading = true;
        this.axios({
          method: "GET",
          url: this.linkAPI,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.token}`,
          },
          params: {
            filterStartDate: this.filterStartDate.substring(
              0,
              this.filterStartDate.length - 5
            ),
            filterEndDate: this.filterEndDate.substring(
              0,
              this.filterStartDate.length - 5
            ),
            filterAgentSelected: this.filterAgents,
            filterGroupSelected: this.filterGroup,
            agent: this.agent,
            company: this.company.id,
          },
        }).then((resp) => {
          console.log(resp.data);
          if (!resp.data.Error) {
            resp.data["descriptions"] = [];
            resp.data["codes"] = [];
            resp.data.labels.forEach((tag, i) => {
              resp.data.codes[i] = tag;
              resp.data.labels[i] = this.dataLabel[tag].name;
              resp.data.descriptions[i] = this.dataLabel[tag].description;
            });
            this.setDataChart(
              resp.data.labels,
              resp.data.data,
              resp.data.descriptions,
              resp.data.codes
            );
          }
          this.loading = false;
        });
      } else {
        if (this.data != null && !this.data.Error) {
          this.setDataChart(
            this.data.labels,
            this.data.data,
            this.data.descriptions,
            this.data.codes
          );
        }
      }
    },
    exploreFilter(data, index) {
      let obj = {
        label: data.labels[index],
        value: data.codes[index],
        type_code: this.type_code,
        type_label: this.type_label
      };
      if (this.filtersApplication.filter(e => e.value == obj.value && e.type_code == obj.type_code).length == 0) {
        this.filtersApplication.push(obj);
        this.$router.push("/gdpr-specific");
      }
    },
    shuffle(array) {
      let currentIndex = array.length,
        temporaryValue,
        randomIndex;
      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }
      return array;
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    dataLabel: {
      get: function () {
        return this.$store.getters.getDataLabel;
      },
    },
    filterStartDate: {
      get: function () {
        if (this.filters == null) {
          return this.$store.getters.getStartDate;
        } else {
          return this.filters.startDate;
        }
      },
      set: function (value) {
        if (this.filters == null) {
          this.$store.dispatch("setStartDate", value);
        }
      },
    },
    filterEndDate: {
      get: function () {
        if (this.filters == null) {
          return this.$store.getters.getEndDate;
        } else {
          return this.filters.endDate;
        }
      },
      set: function (value) {
        this.$store.dispatch("setEndDate", value);
      },
    },
    filterAgents: {
      get: function () {
        return this.$store.getters.getAgents;
      },
      set: function (value) {
        this.$store.dispatch("setAgents", value);
      },
    },
    filterGroup: {
      get: function () {
        return this.$store.getters.getGroup;
      },
      set: function (value) {
        this.$store.dispatch("setGroup", value);
      },
    },
    company: {
      get: function () {
        return this.$store.getters.getCompany;
      },
      set: function (value) {
        this.$store.dispatch("setCompany", value);
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    filtersApplication: {
      get: function () {
        return this.$store.getters.getFiltersApplication;
      },
      set: function (value) {
        this.$store.dispatch("setFiltersApplication", value);
      },
    },
    colorsChart() {
      if (colors[this.colorsSet].length > 0) {
        return colors[this.colorsSet];
      }
      return colors.default;
    }
  },
  watch: {
    filterStartDate() {
      this.loadData();
    },
    filterEndDate() {
      this.loadData();
    },
    filterAgents() {
      this.loadData();
    },
    filterGroup() {
      this.loadData();
    },
    token() {
      this.loadData();
    },
    data() {
      this.loadData();
    }
  },
};
</script>
<style scoped></style>
