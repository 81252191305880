<template>
  <v-container class="pa-5">
    <v-alert
      v-if="alert_success"
      dense
      text
      type="success"
      dismissible
    >
      {{textApp.label00411}}
    </v-alert>
    <v-alert
      v-if="alert_error"
      dense
      outlined
      type="error"
      dismissible
    >
      {{textApp.label00412}}
    </v-alert>
    <v-alert
      v-if="alert_running"
      dense
      outlined
      type="warning"
      dismissible
    >
      Lo script è ancora in esecuzione.
    </v-alert>
    <v-row align="start" justify="start">
      <v-col cols="12" sm="12" md="12" lg="8" class="pa-5">
        <CodeEditor
          v-model="text_command"
          width="100%"
          border_radius="4px"
          :hide_header="true"
          :display_language="false"
        ></CodeEditor>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="4" class="pa-5">
        <v-text-field
          class="mb-0"
          :label="textApp.label00414"
          v-model="timeout"
          :rules="rules_timeout"
          outlined
          dense
          type="number"
        ></v-text-field>
        <v-btn
          :disabled="
            text_command == '' ||
            text_command.trim() == '' ||
            timeout <= 0
          "
          color="primary"
          @click="run()"
        >
          {{textApp.label00415}}
        </v-btn>
        <!--<v-btn
          :disabled="
            cwd == null ||
            cwd == '' ||
            text_command == '' ||
            text_command.trim() == '' ||
            timeout <= 0
          "
          color="primary"
          class="ml-5"
          @click="get_output()"
        >
          {{textApp.label00416}}
        </v-btn>-->
      </v-col>
    </v-row>
    <v-row align="start" justify="start">
      <v-col cols="12" sm="12" md="12" lg="12" class="pa-5">
        <!--<CodeEditor
          v-model="output"
          width="100%"
          border_radius="4px"
          :display_language="false"
          :read_only="true"
          :hide_header="true"
          theme="light"
        ></CodeEditor>-->
        <v-textarea
          name="input-7-1"
          label="Output"
          :value="output"
          ref="textarea"
          readonly
          outlined
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CodeEditor from "simple-code-editor";
export default {
  components: {
    CodeEditor,
  },
  data() {
    return {
      text_command: "",
      timeout: 30,
      rules: [],
      rules_timeout: [],
      output: null,
      alert_success: false,
      alert_error: false,
      alert_running: false
    };
  },
  mounted() {
    this.rules = [
      (v) => {
        if (v == null || v == "") {
          return this.textApp.label00417;
        }
        return true;
      },
    ];
    this.rules_timeout = [
      (v) => {
        if (v <= 0) {
          return this.textApp.label00418;
        }
        return true;
      },
    ];
  },
  methods: {
    run() {
      this.alert_success = false;
      this.alert_error = false;
      this.alert_running = false;
      this.axios({
        method: "POST",
        url: "/agents/command/script",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          company: this.company.id,
          agent: this.agent,
          text_script: this.text_command,
          timeout: parseInt(this.timeout)
        },
      }).then((resp) => {
        console.log(resp.data);
        if(!resp.data.Error){
          this.output = resp.data.output;
          this.alert_success = true;
        } else {
          this.alert_error = true;
        }
      });
    },
    /*get_output() {
      this.alert_running = false;
      this.axios({
        method: "GET",
        url: "/agents/command/output",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        params: {
          company: this.company.id,
          agent: this.agent,
          id_command: this.id_command
        },
      }).then((resp) => {
        console.log(resp.data);
        if(resp.data.data.status == "successful"){
          this.output = resp.data.data.result.stdout;
        } else if(resp.data.data.status == "running") {
          this.alert_running = true;
        } else {
          this.output = resp.data.data.result.stderr;
        }
        const { textarea } = this.$refs;
        textarea.style.height = textarea.scrollHeight - 4 + 'px';
      });
    },*/
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    agent() {
      return this.$route.params.idAgent;
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function () {
        return this.$store.getters.getCompany;
      },
    },
  },
};
</script>