<template>
  <v-container>
    <v-row align="start" justify="start">
      <Warning v-on:refreshPage="loadData()"></Warning>
      <v-col cols="12" md="12" sm="12">
        <v-card outlined>
          <v-card-text>
            <v-row justify="start" class="pl-5 pr-5">
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="textApp.label00084"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
            </v-row>
            <v-row justify="start">
              <v-data-table
                :headers="headers"
                :items="listWarning"
                :search="search"
                :footer-props="footerProps"
                dense
              >
                <template v-slot:item="row">
                  <tr>
                    <td>{{ row.item.id }}</td>
                    <td>
                      {{
                        `${row.item.tstp.substring(
                          0,
                          10
                        )} ${row.item.tstp.substring(11, 16)}`
                      }}
                    </td>
                    <td>{{ row.item.title }}</td>
                    <td>
                      {{ row.item.sender_name }} {{ row.item.sender_surname }}
                    </td>
                    <td v-if="row.item.id_status == 1">
                      <v-menu>
                        <template #activator="{ on: onMenu }">
                          <v-tooltip bottom>
                            <template #activator="{ on: onTooltip }">
                              <v-icon
                                color="red"
                                v-on="{ ...onMenu, ...onTooltip }"
                              >
                                mdi-checkbox-blank-circle
                              </v-icon>
                            </template>
                            <span>{{ row.item.status_name }}</span>
                          </v-tooltip>
                        </template>
                        <v-card>
                          <v-card-text>
                            <h4 :style="{ color: $vuetify.theme.themes.light.secondary }">{{textApp.label00157}} {{ row.item.id }}</h4>
                            <p>
                              <b>{{ row.item.object }}</b>
                            </p>
                            <v-btn
                              small
                              color="primary"
                              dark
                              class="mr-1"
                              @click="setWarningStatus(row.item.id, 2)"
                            >
                              {{textApp.label00158}}
                            </v-btn>
                            <v-btn
                              small
                              color="primary"
                              dark
                              @click="setWarningStatus(row.item.id, 3)"
                            >
                              {{textApp.label00159}}
                            </v-btn>
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </td>
                    <td v-if="row.item.id_status == 2">
                      <v-menu>
                        <template #activator="{ on: onMenu }">
                          <v-tooltip bottom>
                            <template #activator="{ on: onTooltip }">
                              <v-icon
                                color="orange"
                                v-on="{ ...onMenu, ...onTooltip }"
                              >
                                mdi-checkbox-blank-circle
                              </v-icon>
                            </template>
                            <span>{{ row.item.status_name }}</span>
                          </v-tooltip>
                        </template>
                        <v-card>
                          <v-card-text>
                            <h4>{{textApp.label00157}} {{ row.item.ID }}</h4>
                            <p>
                              <b>{{ row.item.object }}</b>
                            </p>
                            <v-btn
                              small
                              color="primary"
                              dark
                              @click="setWarningStatus(row.item.id, 3)"
                            >
                              {{textApp.label00159}}
                            </v-btn>
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </td>
                    <td v-if="row.item.id_status == 3">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="green" v-bind="attrs" v-on="on">
                            mdi-checkbox-blank-circle
                          </v-icon>
                        </template>
                        <span>{{ row.item.status_name }}</span>
                      </v-tooltip>
                    </td>
                    <td>
                      <WarningComplete :warning="row.item"></WarningComplete>
                    </td>
                    <td v-if="row.item.id_status <= 2">
                      <ResponseWarning :warning="row.item"></ResponseWarning>
                    </td>
                    <td v-else></td>
                    <td v-if="row.item.metadata != '{}'">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="ma-2"
                            text
                            icon
                            color="primary"
                            @click="generateReport(row.item)"
                          >
                            <v-icon>mdi-download</v-icon>
                          </v-btn>
                        </template>
                        <span>{{textApp.label00160}}</span>
                      </v-tooltip>
                    </td>
                    <td v-else></td>
                  </tr>
                </template>
                <template v-slot:no-data>
                  <v-card-text> {{textApp.label00089}} </v-card-text>
                </template>
              </v-data-table>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const FileDownload = require("js-file-download");
import Warning from "../components/Warning";
import ResponseWarning from "../components/ResponseWarning";
import WarningComplete from "../components/WarningComplete";
export default {
  components: {
    Warning,
    ResponseWarning,
    WarningComplete,
  },
  data() {
    return {
      search: "",
      headers: [],
      footerProps: null,
      listWarning: [],
      offset: true,
    };
  },
  mounted() {
    this.loadData();
    this.headers = [
      { text: this.textApp.label00056, value: "id" },
      { text: this.textApp.label00151, value: "tstp" },
      { text: this.textApp.label00152, value: "title" },
      { text: this.textApp.label00153, value: "sender_name" },
      { text: this.textApp.label00094, value: "close" },
      { text: this.textApp.label00154, value: "visualize" },
      { text: this.textApp.label00155, value: "action" },
      { text: this.textApp.label00156, value: "report" },
    ];
    this.footerProps = {
      itemsPerPageText: this.textApp.label00095,
      pageText: `{0}-{1} ${this.textApp.label00096} {2}`,
      "items-per-page-all-text": this.textApp.label00097,
    };
  },
  methods: {
    loadData() {
      this.axios({
        method: "GET",
        url: "/warnings/list",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        params: {
          company: this.company.id,
        },
      }).then((resp) => {
        console.log(resp.data);
        this.listWarning = resp.data;
      });
    },
    setWarningStatus(idWarning, idStatus) {
      this.axios({
        method: "POST",
        url: "/warnings/setStatus",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          company: this.company.id,
          id_status: idStatus,
          id_warning: idWarning,
        },
      }).then((resp) => {
        console.log(resp.data);
        this.loadData();
      });
    },
    generateReport(warning) {
      let filters = JSON.parse(warning.metadata);
      this.axios({
        method: "POST",
        url: "/reports/warning/generate",
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/pdf",
        },
        data: {
          filterStartDate: filters.filters.startDate.substring(
            0,
            filters.filters.startDate.length - 5
          ),
          filterEndDate: filters.filters.endDate.substring(
            0,
            filters.filters.endDate.length - 5
          ),
          type: filters.type,
          id_warning: warning.id,
          company: this.company.id,
          host: window.location.host
        },
      }).then((resp) => {
        console.log(resp);
        if (resp.headers["content-type"] == "application/pdf") {
          FileDownload(resp.data, "Report segnalazione.pdf");
        }
      });
    },
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function () {
        return this.$store.getters.getCompany;
      },
      set: function (value) {
        this.$store.dispatch("setCompany", value);
      },
    },
    credentials: {
      get: function () {
        return this.$store.getters.getCredentials;
      },
      set: function (value) {
        this.$store.dispatch("setCredentials", value);
      },
    },
  },
};
</script>
<style scoped></style>
