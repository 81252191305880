<template>
  <v-app-bar app color="#fff">
    <v-app-bar-nav-icon @click="toggleNavbar()" class="mr-3" />
    <div class="d-flex align-center">
      <router-link to="/">
        <v-img
          class="mr-2"
          contain
          :src="companySetup.icon"
          transition="scale-transition"
          width="45"
        />
      </router-link>
      <h1
        class="ml-3 title-bold"
        :style="{ color: $vuetify.theme.themes.light.secondary }"
      >
        {{ companySetup.service_name }}
      </h1>
    </div>
    <v-spacer></v-spacer>
    <span class="mt-5 mr-5" v-if="company != null && !isAdminPage && !isMobile && $route.fullPath != '/'">
      <v-select
        class="mt-2"
        :label="textApp.label00233"
        :items="listCompany"
        item-text="name"
        item-value="id"
        outlined
        dense
        v-model="companySelected"
      ></v-select>
    </span>
    <v-tooltip v-if="L1Permission && !isMobile" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon class="mr-1" x-large to="/adminL1">
          <v-icon color="secondary">mdi-account-tie</v-icon>
        </v-btn>
      </template>
      <span>{{ textApp.label00234 }}</span>
    </v-tooltip>
    <v-menu
      v-model="menuNotification"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
      max-height="350"
    >
      <template #activator="{ on: menu }">
        <v-tooltip bottom>
          <template #activator="{ on: tooltip }">
            <v-btn icon v-on="{ ...tooltip, ...menu }" class="mr-1" x-large>
              <v-badge
                bordered
                overlap
                :content="contentNotification"
                :value="contentNotification > 0"
              >
                <v-icon color="secondary">mdi-bell-circle</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span>{{ textApp.label00235 }}</span>
        </v-tooltip>
      </template>

      <v-card v-if="isAuthenticated">
        <v-card-text
          ><h3>{{ textApp.label00236 }}</h3></v-card-text
        >
        <v-list v-if="listNotification.length > 0">
          <v-list-item v-for="(item, it) in listNotification" :key="it">
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <v-list-item-subtitle
                >{{ item.timestamp }} -
                {{ item.description }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-row><v-btn
                v-if="item.id_notification_category != 1"
                depressed
                small
                color="secondary"
                class="mr-1"
                @click="goToData(item)"
              >
                Visualizza
              </v-btn>
              <v-btn
                depressed
                small
                color="secondary"
                @click="setNotificationShown(item)"
              >
                {{ textApp.label00237 }}
              </v-btn>
            </v-row>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-if="listNotification.length == 0">
            <v-list-item-content>
              <v-list-item-title>{{ textApp.label00238 }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="menuNotification = false">
            {{ textApp.label00042 }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <v-menu
      v-model="menuUser"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
      <template #activator="{ on: menu }">
        <v-tooltip bottom>
          <template #activator="{ on: tooltip }">
            <v-btn icon v-on="{ ...tooltip, ...menu }" class="mr-1" x-large>
              <v-badge
                bordered
                overlap
                :content="contentNotification"
                :value="false"
              >
                <v-icon color="secondary">mdi-account-circle</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span>{{ textApp.label00239 }}</span>
        </v-tooltip>
      </template>

      <v-card v-if="isAuthenticated">
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <img :src="companySetup.icon" />
            </v-list-item-avatar>
            <h4
              class="title-bold"
              :style="{ color: $vuetify.theme.themes.light.secondary }"
            >
              {{ companySetup.service_name }}
            </h4>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>
        <v-card-text>
          <h4>{{ credentials.name + " " + credentials.surname }}</h4>
          <p>{{ credentials.user }}</p>
          <v-row>
            <v-col cols="12" md="12" class="pt-5 pb-0">
              <v-btn
                color="secondary"
                small
                dark
                width="100%"
                to="/change-lang"
              >
                {{ textApp.label00334 }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" class="pb-0">
              <v-btn
                color="secondary"
                small
                width="100%"
                to="/password-manager"
              >
                {{ textApp.label00240 }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-btn color="primary" small width="100%" @click="logout()">
                {{ textApp.label00241 }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="menuUser = false">
            {{ textApp.label00042 }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      menuUser: false,
      menuNotification: false,
      listNotification: [],
      isAdminPage: false,
      companySelected: null,
    };
  },
  mounted() {
    this.companySelected = this.company;
    this.getNotifications();
  },
  methods: {
    getNotifications() {
      this.axios({
        method: "GET",
        url: "/notification/list",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
      }).then((resp) => {
        console.log(resp.data);
        if(!resp.data.Error) {
          this.listNotification = resp.data;
        }
      });
    },
    setNotificationShown(notification) {
      console.log(notification);
      this.axios({
        method: "POST",
        url: "/notification/shown",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          id_notification: notification.id,
        },
      }).then((resp) => {
        console.log(resp.data);
        this.menuNotification = false;
        this.getNotifications();
      });
    },
    toggleNavbar(){
      this.$emit("toggleNavbar");
    },
    logout() {
      this.$cookies.remove("permanent");
      this.$store.dispatch("resetState");
      this.$store.dispatch("resetFilterState");
      location.reload(true);
    },
    goToData(notification) {
      let data = JSON.parse(notification.info_json);

      if(notification.id_notification_category == 4) {
        let obj = {
          label: data.label,
          value: data.value,
          type_code: data.type_code,
          type_label: data.type_label
        };
        if (this.filtersApplication.filter(e => e.value == obj.value && e.type_code == obj.type_code).length == 0) {
          this.filtersApplication.push(obj);
        }
        this.changeCompanySelected(notification.id_company, () => this.$router.push("/gdpr-specific"));
      } else if (notification.id_notification_category == 2 || notification.id_notification_category == 3) {
        this.changeCompanySelected(notification.id_company, () => this.$router.push("/warning"));
      }
      
      this.menuNotification = false;
    },
    changeCompanySelected(company, next) {
      this.listCompany.forEach(comp => {
        if(comp.id == company) {
          this.company = {
            id: comp.id,
            name: comp.name
          };
          this.role = comp.role;
          this.L2Permission = comp.L2_permissions;
          this.L3Permission = comp.L3_permissions;
          this.module_rmm = comp.module_rmm;
          this.module_soc = comp.module_soc;

          this.$store.dispatch("resetFilterState");
          
          next();
        }
      });
    }
  },
  computed: {
    companySetup: {
      get: function () {
        return this.$store.getters.getCompanySetup;
      },
    },
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
    role: {
      get: function() {
        return this.$store.getters.getRole;
      },
      set: function(value) {
        this.$store.dispatch("setRole", value);
      }
    },
    L2Permission: {
      get: function() {
        return this.$store.getters.getL2Permission;
      },
      set: function(value) {
        this.$store.dispatch("setL2Permission", value);
      }
    },
    L3Permission: {
      get: function() {
        return this.$store.getters.getL3Permission;
      },
      set: function(value) {
        this.$store.dispatch("setL3Permission", value);
      }
    },
    module_rmm: {
      get: function() {
        return this.$store.getters.getModuleRMM;
      },
      set: function(value) {
        this.$store.dispatch("setModuleRMM", value);
      }
    },
    module_soc: {
      get: function() {
        return this.$store.getters.getModuleSOC;
      },
      set: function(value) {
        this.$store.dispatch("setModuleSOC", value);
      }
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ) &&
        window.innerWidth < 600
      ) {
        return true;
      } else {
        return false;
      }
    },
    L1Permission: {
      get: function () {
        return this.$store.getters.getL1Permission;
      },
      set: function (value) {
        this.$store.dispatch("setL1Permission", value);
      },
    },
    contentNotification() {
      let content = 0;
      this.listNotification.forEach((n) => {
        if (n.shown == 0) {
          content++;
        }
      });
      return content;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    credentials: {
      get: function () {
        return this.$store.getters.getCredentials;
      },
      set: function (value) {
        this.$store.dispatch("setCredentials", value);
      },
    },
    filtersApplication: {
      get: function () {
        return this.$store.getters.getFiltersApplication;
      },
      set: function (value) {
        this.$store.dispatch("setFiltersApplication", value);
      },
    },
    listCompany: {
      get: function() {
        return this.$store.getters.getListCompany;
      },
      set: function(value) {
        this.$store.dispatch("setListCompany", value);
      }
    },
  },
  watch: {
    $route() {
      this.getNotifications();
    },
    companySelected() {
      this.changeCompanySelected(this.companySelected, () => {
        location.reload(true);
      });
    },
    company() {
      this.companySelected = this.company;
    }
  }
};
</script>
<style scoped></style>
