<template>
  <v-container>
    <v-dialog v-model="dialog" width="80%">
      <template #activator="{ on: dialog }">
        <v-tooltip left>
          <template #activator="{ on: tooltip }">
            <v-btn
              color="secondary"
              small
              dark
              fab
              outlined
              v-on="{ ...tooltip, ...dialog }"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>{{textApp.label00423}}</span>
        </v-tooltip>
      </template>

      <v-card class="pl-5 pr-5">
        <v-card-title class="pl-3">
          <h4 :style="{ color: $vuetify.theme.themes.light.secondary }">{{textApp.label00423}}</h4>
        </v-card-title>
        <v-divider class="mb-1"></v-divider>
        <v-card-text class="mt-3">
          <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
            <v-text-field
              :label="textApp.label00005"
              v-model="edited_script.name"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
            <v-text-field
              :label="textApp.label00176"
              v-model="edited_script.description"
              outlined
            ></v-text-field>
          </v-col>
        </v-card-text>

        <v-row align="center" justify="center" class="pb-5">
          <CodeEditor
            class="mb-5"
            v-model="edited_script.text_script"
            width="90%"
            border_radius="4px"
            :hide_header="true"
            :display_language="false"
          ></CodeEditor>
        </v-row>
        

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()"> {{textApp.label00042}} </v-btn>
          <v-btn color="primary" @click="closeDialog()"> {{textApp.label00422}} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import CodeEditor from "simple-code-editor";
export default {
  components: {
    CodeEditor,
  },
  data() {
    return {
      dialog: false,
      edited_script: null,
    };
  },
  props: ['script'],
  mounted() {
    this.edited_script = JSON.parse(JSON.stringify(this.script));
    this.axios({
      method: "GET",
      url: "/commands/list",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      params: {
        company: this.company.id,
      },
    }).then((resp) => {
      console.log(resp.data);
      this.listCommands = resp.data;
    });
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    }
  },
  computed: {
    companySetup: {
      get: function() {
        return this.$store.getters.getCompanySetup;
      }
    },
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      }
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
  }
};
</script>
<style scoped></style>
