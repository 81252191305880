<template>
  <v-container class="pl-3">
    <v-row align="start" justify="start">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="ml-0"
            :loading="btnGenerateGenericReportLoading"
            :disabled="btnGenerateGenericReportLoading"
            color="secondary"
            small
            @click="generateGenericReport()"
          >
            {{textApp.label00193}}
          </v-btn>
        </template>
        <span>{{textApp.label00195}}</span>
      </v-tooltip>
    </v-row>
  </v-container>
</template>

<script>
const FileDownload = require("js-file-download");
export default {
  data() {
    return {
      listCompany: [],
      btnGenerateGenericReportLoading: false
    };
  },
  mounted() {},
  methods: {
    generateGenericReport() {
      this.btnGenerateGenericReportLoading = true;
      this.axios({
        method: "POST",
        url: "/reports/generic/generate", ///generateGenericGDPRReport",
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/pdf"
        },
        data: {
          filterStartDate: this.filterStartDate.substring(
            0,
            this.filterStartDate.length - 5
          ),
          filterEndDate: this.filterEndDate.substring(
            0,
            this.filterStartDate.length - 5
          ),
          agents: this.filterAgents,
          filters: this.filters,
          company: this.company.id,
          host: window.location.host
        }
      }).then(resp => {
        console.log(resp);
        this.btnGenerateGenericReportLoading = false;
        if (resp.headers["content-type"] == "application/pdf") {
          FileDownload(resp.data, "Report generico.pdf");
        }
      });
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    filterStartDate: {
      get: function() {
        return this.$store.getters.getStartDate;
      },
      set: function(value) {
        this.$store.dispatch("setStartDate", value);
      }
    },
    filterEndDate: {
      get: function() {
        return this.$store.getters.getEndDate;
      },
      set: function(value) {
        this.$store.dispatch("setEndDate", value);
      }
    },
    filterAgents: {
      get: function() {
        return this.$store.getters.getAgents;
      },
      set: function(value) {
        this.$store.dispatch("setAgents", value);
      }
    },
    credentials: {
      get: function() {
        return this.$store.getters.getCredentials;
      },
      set: function(value) {
        this.$store.dispatch("setCredentials", value);
      }
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
    role: {
      get: function() {
        return this.$store.getters.getRole;
      },
      set: function(value) {
        this.$store.dispatch("setRole", value);
      }
    }
  }
};
</script>
<style scoped></style>
