<template>
  <v-container>
    <v-dialog v-model="dialog" width="700">
      <template #activator="{ on: dialog }">
        <v-tooltip bottom>
          <template #activator="{ on: tooltip }">
            <v-btn v-on="{ ...tooltip, ...dialog }" icon color="secondary">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>{{textApp.label00134}} {{ group }}</span>
        </v-tooltip>
      </template>

      <v-card>
        <v-card-title>
          <h4 :style="{ color: $vuetify.theme.themes.light.secondary }">
            {{textApp.label00135}}
          </h4>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-card-text v-if="!loading">
                {{textApp.label00136}} <b>{{ group }}</b
                >{{textApp.label00046}}
              </v-card-text>
              <v-row v-if="loading">
                <v-card-text style="padding-top: 50px; padding-bottom: 50px">
                  <v-row align="center" justify="center">
                    <v-progress-circular
                      :size="120"
                      :width="10"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                  </v-row>
                </v-card-text>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()"> {{textApp.label00012}} </v-btn>
          <v-btn color="primary" @click="confirm()"> {{textApp.label00013}} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      dialog: false,
      loading: false
    };
  },
  props: ["group"],
  mounted() {},
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    credentials: {
      get: function() {
        return this.$store.getters.getCredentials;
      },
      set: function(value) {
        this.$store.dispatch("setCredentials", value);
      }
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    confirm() {
      this.loading = true;
      this.axios({
        method: "POST",
        url: "/agents/groups/delete",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        data: {
          company: this.company.id,
          group_id: this.group
        }
      }).then(resp => {
        console.log(resp.data);
        this.loading = false;
        this.closeDialog();
        this.$emit("refreshPage");
      });
    }
  }
};
</script>
<style scoped></style>
