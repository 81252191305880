<template>
  <span class="ml-5 mr-5">
    <v-menu offset-y v-if="listCustomers.length > 0">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          style="height: 40px;"
        >
          {{textApp.label00019}}
          <v-icon right dark>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in listCustomers" :key="index">
          <v-list-item-title>{{ item.name_company }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu offset-y v-if="listProviders.length > 0">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          style="height: 40px;"
        >
          {{textApp.label00020}}
          <v-icon right dark>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in listProviders" :key="index">
          <v-list-item-title>{{ item.name_company }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </span>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      listProviders: [],
      listCustomers: []
    };
  },
  props: ["baseCompanySelected"],
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.axios({
        method: "GET",
        url: `/admin/L1/company/customerCompanyList`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        params: {
          target_provider_company: this.baseCompanySelected
        }
      }).then(resp => {
        console.log(resp.data);
        if (!resp.data.Error) {
          this.listCustomers = resp.data;
        } else {
          console.log("ERRORE COMPANY PROVIDER");
        }
      });
      this.axios({
        method: "GET",
        url: `/admin/L1/company/providerCompanyList`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        params: {
          target_customer_company: this.baseCompanySelected
        }
      }).then(resp => {
        console.log(resp.data);
        if (!resp.data.Error) {
          this.listProviders = resp.data;
        } else {
          console.log("ERRORE COMPANY CUSTOMER");
        }
      });
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      }
    },
    token() {
      return this.$store.getters.getToken;
    }
  },
  watch: {
    baseCompanySelected() {
      this.loadData();
    }
  }
};
</script>
<style scoped></style>
