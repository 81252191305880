const getDefaultState = () => {
  return {
    authenticated: false,
    token: null,
    credentials: {
      id: null,
      user: null,
      password: null,
      name: null,
      surname: null,
      lang: null
    },
    module: {
      admin: 0,
      gdpr: 0,
      va: 0,
      hn: 0,
      sb: 0
    },
    listCompany: [],
    company: null,
    role: null,
    L1_permisssion: false,
    L2_permisssion: false,
    L3_permisssion: false,
    moduleRMM: 0,
    moduleSOC: 0
  };
};

export const auth = {
  state: getDefaultState(),
  getters: {
    isAuthenticated: state => state.authenticated,
    getToken: state => state.token,
    getCredentials: state => state.credentials,
    getModule: state => state.module,
    getListCompany: state => state.listCompany,
    getCompany: state => state.company,
    getRole: state => state.role,
    getL1Permission: state => state.L1_permisssion,
    getL2Permission: state => state.L2_permisssion,
    getL3Permission: state => state.L3_permisssion,
    getModuleRMM: state => state.moduleRMM,
    getModuleSOC: state => state.moduleSOC
  },
  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState());
      console.log(state);
    },
    setAuthenticated(state) {
      state.authenticated = true;
    },
    setNotAuthenticated(state) {
      state.authenticated = false;
    },
    setToken(state, value) {
      state.token = value;
    },
    setCredentials(state, value) {
      state.credentials = value;
    },
    setModule(state, value) {
      state.module = value;
    },
    setListCompany(state, value) {
      state.listCompany = value;
    },
    setCompany(state, value) {
      state.company = value;
    },
    setRole(state, value) {
      state.role = value;
    },
    setL1Permission(state, value) {
      state.L1_permisssion = value;
    },
    setL2Permission(state, value) {
      state.L2_permisssion = value;
    },
    setL3Permission(state, value) {
      state.L3_permisssion = value;
    },
    setModuleRMM(state, value) {
      state.moduleRMM = value;
    },
    setModuleSOC(state, value) {
      state.moduleSOC = value;
    }
  },
  actions: {
    resetState(context) {
      context.commit("resetState");
    },
    setAuthenticated(context) {
      context.commit("setAuthenticated");
    },
    setNotAuthenticated(context) {
      context.commit("setNotAuthenticated");
    },
    setToken(context, value) {
      context.commit("setToken", value);
    },
    setCredentials(context, value) {
      context.commit("setCredentials", value);
    },
    setModule(context, value) {
      context.commit("setModule", value);
    },
    setListCompany(context, value) {
      context.commit("setListCompany", value);
    },
    setCompany(context, value) {
      context.commit("setCompany", value);
    },
    setRole(context, value) {
      context.commit("setRole", value);
    },
    setL1Permission(context, value) {
      context.commit("setL1Permission", value);
    },
    setL2Permission(context, value) {
      context.commit("setL2Permission", value);
    },
    setL3Permission(context, value) {
      context.commit("setL3Permission", value);
    },
    setModuleRMM(context, value) {
      context.commit("setModuleRMM", value);
    },
    setModuleSOC(context, value) {
      context.commit("setModuleSOC", value);
    },
  }
};
