<template>
  <v-container class="pa-1">
    <v-dialog v-model="dialogConfirmAgent" width="700px">
      <template #activator="{ on: dialogConfirmAgent }">
        <v-tooltip left>
          <template #activator="{ on: tooltip }">
            <v-btn
              color="secondary"
              small
              dark
              tile
              text
              v-on="{ ...tooltip, ...dialogConfirmAgent }"
              @click.stop="dismissAgent()"
            >
              <v-icon left>mdi-autorenew-off</v-icon>
              {{ textApp.label00427 }}
            </v-btn>
          </template>
          <span>{{ textApp.label00427 }}</span>
        </v-tooltip>
      </template>
      <v-card class="pl-5 pl-5">
        <v-card-title class="pl-3">
          <h4 :style="{ color: $vuetify.theme.themes.light.secondary }">
            {{ textApp.label00428 }}
          </h4>
        </v-card-title>
        <v-divider class="mb-1"></v-divider>

        <v-card-text class="mt-3 pt-3">
          {{ textApp.label00429 }} <b>{{ agent.id }}</b> {{ textApp.label00046 }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()">
            {{ textApp.label00145 }}
          </v-btn>
          <v-btn color="primary" @click="confirmDismissAgent()">
            {{ textApp.label00146 }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      dialogConfirmAgent: false,
    };
  },
  props: ["agent"],
  mounted() {},
  methods: {
    dismissAgent() {
      this.dialogConfirmAgent = true;
    },
    confirmDismissAgent() {
      this.axios({
        method: "POST",
        url: "/agents/dismiss",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          company: this.company.id,
          agent: this.agent.id,
        },
      }).then((resp) => {
        console.log(resp.data);
        this.closeDialog();
        this.$emit("reloadListAgents");
      });
    },
    closeDialog() {
      this.dialogConfirmAgent = false;
    },
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    company: {
      get: function () {
        return this.$store.getters.getCompany;
      },
      set: function (value) {
        this.$store.dispatch("setCompany", value);
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
  },
  watch: {},
};
</script>
<style scoped></style>
