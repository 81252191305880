import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import VueCookies from 'vue-cookies'
import VueCryptojs from 'vue-cryptojs'

Vue.use(VueAxios, axios);
Vue.use(VueCookies);
Vue.use(VueCryptojs)


axios.interceptors.request.use(
  function(config) {
    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  function(resp) {
    if (resp.data.code == 800 && resp.data.Message == "Error token expired") {
      store.dispatch("setToken", null);
    }
    return resp;
  },
  function(err) {
    return Promise.reject(err);
  }
);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
