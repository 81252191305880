<template>
  <v-container class="pr-5 pl-5">
    <v-row align="start" justify="start">
      <v-col cols="12" md="12">
        <v-card outlined>
          <v-card-text>
            <h4>{{textApp.label00065}}</h4>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="12">
        <doughnut-template
          :title="textApp.label00066"
          linkAPI="/charts/agents/actions"
        ></doughnut-template>
        <!--:type_code="constants.REQUIREMENTS.type_code"
          :type_label="textApp[constants.REQUIREMENTS.type_label]"-->
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="12">
        <WarningStats></WarningStats>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="12">
        <SeverityLevelsStats></SeverityLevelsStats>
      </v-col>
      <v-col cols="12" lg="12" md="12" sm="12">
        <multiple-bar-template
          :title="textApp.label00067"
          linkAPI="/charts/tradesecret/timeline/generic"
          params="rid"
          :widthChart="4"
          :heightChart="1"
          calendarIntervals="day"
          colorsSet="colorsChartRID"
        ></multiple-bar-template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DoughnutTemplate from "../components/DoughnutTemplate";
import WarningStats from "../components/WarningStats";
import SeverityLevelsStats from "../components/SeverityLevelsStats";
import MultipleBarTemplate from "../components/MultipleBarTemplate";
import constants from "../config/constants";
export default {
  components: {
    DoughnutTemplate,
    MultipleBarTemplate,
    WarningStats,
    SeverityLevelsStats
  },
  data() {
    return {
      constants: constants
    };
  },
  mounted() {},
  methods: {},
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      }
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
  }
};
</script>
<style scoped></style>
