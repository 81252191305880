import { render, staticRenderFns } from "./AdminAssignCompany.vue?vue&type=template&id=3429d7b1&scoped=true&"
import script from "./AdminAssignCompany.vue?vue&type=script&lang=js&"
export * from "./AdminAssignCompany.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3429d7b1",
  null
  
)

export default component.exports