module.exports = {
  default: [
    "#00b294",
    "#D6DBB2",
    "#FADF7F",
    "#ACB0BD",
    "#EED881",
    "#68217a",
    "#D5C2FF",
    "#6A99A0",
    "#0D5359",
    "#00bcf2"
  ],
  colorsChartRID: [
    "#0D5359",
    "#00bcf2",
    "#00b294",
    "#D6DBB2",
    "#FADF7F",
    "#ACB0BD",
    "#EED881",
    "#68217a",
    "#D5C2FF",
    "#6A99A0"
  ],
  colorsChartCategDirigente: [
    "#FADF7F",
    "#ACB0BD",
    "#EED881",
    "#68217a",
    "#D5C2FF",
    "#6A99A0",
    "#0D5359",
    "#00bcf2",
    "#00b294",
    "#D6DBB2"
  ],
  colorsChartLineDirigente: ["#6A99A0"],
  colorsChartCategDPO: [
    "#FADF7F",
    "#ACB0BD",
    "#EED881",
    "#68217a",
    "#D5C2FF",
    "#6A99A0",
    "#0D5359",
    "#00bcf2",
    "#00b294",
    "#D6DBB2",
    "#FADF7F",
    "#ACB0BD",
    "#EED881",
    "#68217a",
    "#D5C2FF",
    "#6A99A0",
    "#0D5359",
    "#00bcf2",
    "#00b294",
    "#D6DBB2"
  ],
  colorsChartTimelineAgents: [
    "#82e0aa",
    "#f39c12",
    "#f9ebea",
    "#f4f6f7",
    "#7d6608",
    "#196f3d",
    "#af7ac5",
    "#873600",
    "#f4f6f6",
    "#e8f8f5",
    "#935116",
    "#148f77",
    "#566573",
    "#2980b9",
    "#f2f3f4",
    "#2471a3",
    "#1abc9c",
    "#0e6251",
    "#e67e22",
    "#d6eaf8",
    "#d35400",
    "#af601a",
    "#e59866",
    "#78281f",
    "#6e2c00",
    "#2e86c1",
    "#ebdef0",
    "#a9cce3",
    "#145a32",
    "#76d7c4",
    "#eafaf1",
    "#b7950b",
    "#424949",
    "#7d3c98",
    "#1e8449",
    "#17202a",
    "#cacfd2",
    "#784212",
    "#f8c471",
    "#76448a",
    "#cb4335",
    "#7f8c8d",
    "#a3e4d7",
    "#45b39d",
    "#e74c3c",
    "#5f6a6a",
    "#eb984e",
    "#abb2b9",
    "#9a7d0a",
    "#7fb3d5",
    "#943126",
    "#a569bd",
    "#f6ddcc",
    "#f7f9f9",
    "#e8f6f3",
    "#f2f4f4",
    "#212f3c",
    "#fdf2e9",
    "#aab7b8",
    "#e5e7e9",
    "#e9f7ef",
    "#f1c40f",
    "#a6acaf",
    "#99a3a4",
    "#b03a2e",
    "#7e5109",
    "#707b7c",
    "#48c9b0",
    "#52be80",
    "#922b21",
    "#515a5a",
    "#95a5a6",
    "#cd6155",
    "#e5e8e8",
    "#3498db",
    "#e8daef",
    "#b9770e",
    "#f5eef8",
    "#fae5d3",
    "#7b241c",
    "#ec7063",
    "#f0b27a",
    "#8e44ad",
    "#1a5276",
    "#fbfcfc",
    "#2ecc71",
    "#17a589",
    "#f2d7d5",
    "#abebc6",
    "#d2b4de",
    "#1c2833",
    "#4a235a",
    "#884ea0",
    "#7dcea0",
    "#85929e",
    "#bdc3c7",
    "#d0d3d4",
    "#16a085",
    "#273746",
    "#d98880",
    "#9c640c",
    "#2874a6",
    "#21618c",
    "#1d8348",
    "#fdedec",
    "#bb8fce",
    "#ca6f1e",
    "#ba4a00",
    "#73c6b6",
    "#eaf2f8",
    "#641e16",
    "#1b2631",
    "#fdebd0",
    "#1b4f72",
    "#f5b7b1",
    "#5dade2",
    "#fadbd8",
    "#bfc9ca",
    "#9b59b6",
    "#229954",
    "#f1948a",
    "#a04000",
    "#117864",
    "#b2babb",
    "#fbeee6",
    "#d1f2eb",
    "#58d68d",
    "#d7bde2",
    "#0e6655",
    "#212f3d",
    "#aeb6bf",
    "#ebedef",
    "#eaecee",
    "#d4ac0d",
    "#5499c7",
    "#154360",
    "#fef9e7",
    "#fef5e7",
    "#c0392b",
    "#85c1e9",
    "#edbb99",
    "#d5dbdb",
    "#138d75",
    "#f8f9f9",
    "#633974",
    "#6c3483",
    "#28b463",
    "#2e4053",
    "#797d7f",
    "#f7dc6f",
    "#117a65",
    "#f4d03f",
    "#fcf3cf",
    "#4d5656",
    "#d0ece7",
    "#f5b041",
    "#808b96",
    "#b3b6b7",
    "#979a9a",
    "#a2d9ce",
    "#d4efdf",
    "#1f618d",
    "#717d7e",
    "#f9e79f",
    "#dc7633",
    "#239b56",
    "#7b7d7d",
    "#a93226",
    "#616a6b",
    "#fdfefe",
    "#34495e",
    "#2c3e50",
    "#5b2c6f",
    "#5d6d7e",
    "#f4ecf7",
    "#ebf5fb",
    "#839192",
    "#0b5345",
    "#d4e6f1",
    "#f0f3f4",
    "#e6b0aa",
    "#d5d8dc",
    "#909497",
    "#d68910",
    "#186a3b",
    "#ecf0f1",
    "#d5f5e3",
    "#d7dbdd",
    "#ccd1d1",
    "#aed6f1",
    "#eaeded",
    "#626567",
    "#512e5f",
    "#283747",
    "#d6dbdf",
    "#c39bd3",
    "#a9dfbf",
    "#fad7a0",
    "#f5cba7",
    "#27ae60"
  ]
};
