var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","color":"#fff"}},[_c('v-app-bar-nav-icon',{staticClass:"mr-3",on:{"click":function($event){return _vm.toggleNavbar()}}}),_c('div',{staticClass:"d-flex align-center"},[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{staticClass:"mr-2",attrs:{"contain":"","src":_vm.companySetup.icon,"transition":"scale-transition","width":"45"}})],1),_c('h1',{staticClass:"ml-3 title-bold",style:({ color: _vm.$vuetify.theme.themes.light.secondary })},[_vm._v(" "+_vm._s(_vm.companySetup.service_name)+" ")])],1),_c('v-spacer'),(_vm.company != null && !_vm.isAdminPage && !_vm.isMobile && _vm.$route.fullPath != '/')?_c('span',{staticClass:"mt-5 mr-5"},[_c('v-select',{staticClass:"mt-2",attrs:{"label":_vm.textApp.label00233,"items":_vm.listCompany,"item-text":"name","item-value":"id","outlined":"","dense":""},model:{value:(_vm.companySelected),callback:function ($$v) {_vm.companySelected=$$v},expression:"companySelected"}})],1):_vm._e(),(_vm.L1Permission && !_vm.isMobile)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"icon":"","x-large":"","to":"/adminL1"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-account-tie")])],1)]}}],null,false,3716363287)},[_c('span',[_vm._v(_vm._s(_vm.textApp.label00234))])]):_vm._e(),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":"","max-height":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"icon":"","x-large":""}},Object.assign({}, tooltip, menu)),[_c('v-badge',{attrs:{"bordered":"","overlap":"","content":_vm.contentNotification,"value":_vm.contentNotification > 0}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-bell-circle")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.textApp.label00235))])])]}}]),model:{value:(_vm.menuNotification),callback:function ($$v) {_vm.menuNotification=$$v},expression:"menuNotification"}},[(_vm.isAuthenticated)?_c('v-card',[_c('v-card-text',[_c('h3',[_vm._v(_vm._s(_vm.textApp.label00236))])]),(_vm.listNotification.length > 0)?_c('v-list',[_vm._l((_vm.listNotification),function(item,it){return _c('v-list-item',{key:it},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.timestamp)+" - "+_vm._s(item.description)+" ")])],1),_c('v-list-item-action',[_c('v-row',[(item.id_notification_category != 1)?_c('v-btn',{staticClass:"mr-1",attrs:{"depressed":"","small":"","color":"secondary"},on:{"click":function($event){return _vm.goToData(item)}}},[_vm._v(" Visualizza ")]):_vm._e(),_c('v-btn',{attrs:{"depressed":"","small":"","color":"secondary"},on:{"click":function($event){return _vm.setNotificationShown(item)}}},[_vm._v(" "+_vm._s(_vm.textApp.label00237)+" ")])],1)],1)],1)}),(_vm.listNotification.length == 0)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.textApp.label00238))])],1)],1):_vm._e()],2):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.menuNotification = false}}},[_vm._v(" "+_vm._s(_vm.textApp.label00042)+" ")])],1)],1):_vm._e()],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"icon":"","x-large":""}},Object.assign({}, tooltip, menu)),[_c('v-badge',{attrs:{"bordered":"","overlap":"","content":_vm.contentNotification,"value":false}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-account-circle")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.textApp.label00239))])])]}}]),model:{value:(_vm.menuUser),callback:function ($$v) {_vm.menuUser=$$v},expression:"menuUser"}},[(_vm.isAuthenticated)?_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',[_c('img',{attrs:{"src":_vm.companySetup.icon}})]),_c('h4',{staticClass:"title-bold",style:({ color: _vm.$vuetify.theme.themes.light.secondary })},[_vm._v(" "+_vm._s(_vm.companySetup.service_name)+" ")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('h4',[_vm._v(_vm._s(_vm.credentials.name + " " + _vm.credentials.surname))]),_c('p',[_vm._v(_vm._s(_vm.credentials.user))]),_c('v-row',[_c('v-col',{staticClass:"pt-5 pb-0",attrs:{"cols":"12","md":"12"}},[_c('v-btn',{attrs:{"color":"secondary","small":"","dark":"","width":"100%","to":"/change-lang"}},[_vm._v(" "+_vm._s(_vm.textApp.label00334)+" ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"12"}},[_c('v-btn',{attrs:{"color":"secondary","small":"","width":"100%","to":"/password-manager"}},[_vm._v(" "+_vm._s(_vm.textApp.label00240)+" ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-btn',{attrs:{"color":"primary","small":"","width":"100%"},on:{"click":function($event){return _vm.logout()}}},[_vm._v(" "+_vm._s(_vm.textApp.label00241)+" ")])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.menuUser = false}}},[_vm._v(" "+_vm._s(_vm.textApp.label00042)+" ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }