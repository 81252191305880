module.exports = {
    REQUIREMENTS: {
        type_code: "requirements",
        type_label: "label00186"
    },
    IDAGENTS: {
        type_code: "IDAgents",
        type_label: "label00182"
    },
    NAMEAGENTS: {
        type_code: "nameAgents",
        type_label: "label00498"
    },
    APADDRESS: {
        type_code: "IPAddress",
        type_label: "label00183"
    },
    SEVERITY: {
        type_code: "severity",
        type_label: "label00184"
    },
    DESCRIPTION: {
        type_code: "descriptions",
        type_label: "label00176"
    }
}