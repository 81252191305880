<template>
  <v-app>
    <div v-if="loading">
      <!--{{textApp.label00232}}-->
      Caricamento in corso...
    </div>
    <div v-else>

      <AppBar @toggleNavbar="toggleNavbar"></AppBar>

      <NavigationDrawer :toggleDrawer="drawer"></NavigationDrawer>

      <v-main>
        <Breadcrumbs></Breadcrumbs>
        <v-divider></v-divider>
        <date-selectors></date-selectors>
        <div v-if="isMobile && filterDatesEnable" style="height: 180px"></div>
        <transition mode="out-in" name="slide">
          <router-view id="nav"></router-view>
        </transition>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import DateSelectors from "./components/DateSelectors";
import NavigationDrawer from "./components/NavigationDrawer";
import AppBar from "./components/AppBar";
import { cookie_key } from "./config/config";
import { baseUrlSetup } from "./config/config";
import Breadcrumbs from "./components/Breadcrumbs";

export default {
  name: "App",
  components: {
    DateSelectors,
    NavigationDrawer,
    AppBar,
    Breadcrumbs
},
  mounted() {
    let cookieData = null;
    if (this.$cookies.get("permanent") != null) {
      cookieData = JSON.parse(
        this.$CryptoJS.AES.decrypt(
          this.$cookies.get("permanent"),
          cookie_key
        ).toString(this.CryptoJS.enc.Utf8)
      );
      if (cookieData.authenticated) {
        this.$store.dispatch("setAuthenticated");
        this.credentials = cookieData.credentials;
        this.token = cookieData.token;
        this.module = cookieData.module;
        this.L1Permission = cookieData.L1Permission;
        this.updateLang(cookieData.lang);
      }
    }

    if (this.credential != null && this.company == null) {
      this.$router.push("/");
    }
    if (
      !this.$store.getters.isAuthenticated &&
      !this.$route.fullPath.includes("/password/")
    ) {
      this.$router.push("/login").catch((err) => {
        // Ignore the vuex err regarding  navigating to the page they are already on.
        if (
          err.name !== "NavigationDuplicated" &&
          !err.message.includes(
            "Avoided redundant navigation to current location"
          )
        ) {
          // But print any other errors to the console
          console.log();
          err;
        }
      });
    } else {
      //this.setNavbarItems();
      //this.getNotifications();
    }
  },
  data: () => ({
    drawer: true,
    loading: true
  }),
  watch: {
    $route(to) {
      this.filterDatesEnable = to.meta.filterDates;
      this.filterAgentEnable = to.meta.filterAgent;
      this.filterGroupEnable = to.meta.filterGroup;
      //this.isAdminPage = to.meta.adminL1;
      if (this.token == null && !this.$route.fullPath.includes("/password/")) {
        this.$router.push("/login");
        this.logout();
      }
      //this.getNotifications();
    },
    token() {
      if (this.token == null && !this.$route.fullPath.includes("/password/")) {
        this.$router.push("/login");
        this.logout();
      }
    },
  },
  created() {
    //this.interval1 = setInterval(() => this.getNotifications(), 60 * 1000);
    this.axios({
      method: "GET",
      url: `${baseUrlSetup}/style`,
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        host: window.location.host
      }
    }).then((resp) => {
      console.log(resp.data);
      this.companySetup = resp.data;
      this.loading = false;
      this.$vuetify.theme.themes.light.primary = this.companySetup.colors.primary;
      this.$vuetify.theme.themes.light.secondary = this.companySetup.colors.secondary;
      this.$vuetify.theme.themes.light.accent = this.companySetup.colors.accent;
      let favicon = document.getElementById("idfavicon");
      favicon.href = this.companySetup.icon;
      let title = document.getElementById("labeltitle");
      title.text = this.companySetup.service_name;
      this.axios.defaults.baseURL = this.companySetup.base_url;
    });
    this.getDefaultLang();
  },
  methods: {
    logout() {
      this.$cookies.remove("permanent");
      this.$store.dispatch("resetState");
      this.$store.dispatch("resetFilterState");
      location.reload(true);
    },
    toggleNavbar(){
      this.drawer = !this.drawer;
    },
    getDefaultLang() {
      this.axios({
        method: "GET",
        url: `${baseUrlSetup}/text`,
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          host: window.location.host
        }
      }).then((resp) => {
        console.log(resp.data);
        this.textApp = resp.data;
      });
    },
    updateLang(newLang) {
      this.axios({
        method: "GET",
        url: `${baseUrlSetup}/text`,
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          lang: newLang
        }
      }).then((resp) => {
        console.log(resp.data);
        this.textApp = resp.data;
      });
    }
  },
  computed: {
    companySetup: {
      get: function() {
        return this.$store.getters.getCompanySetup;
      },
      set: function (value) {
        this.$store.dispatch("setCompanySetup", value);
      }
    },
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
      set: function (value) {
        this.$store.dispatch("setTextApplication", value);
      },
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    credentials: {
      get: function () {
        return this.$store.getters.getCredentials;
      },
      set: function (value) {
        this.$store.dispatch("setCredentials", value);
      },
    },
    token: {
      get: function () {
        return this.$store.getters.getToken;
      },
      set: function (value) {
        this.$store.dispatch("setToken", value);
      },
    },
    module: {
      get: function () {
        return this.$store.getters.getModule;
      },
      set: function (value) {
        this.$store.dispatch("setModule", value);
      },
    },
    company: {
      get: function () {
        return this.$store.getters.getCompany;
      },
      set: function (value) {
        this.$store.dispatch("setCompany", value);
      },
    },
    role: {
      get: function () {
        return this.$store.getters.getRole;
      },
      set: function (value) {
        this.$store.dispatch("setRole", value);
      },
    },
    L1Permission: {
      get: function () {
        return this.$store.getters.getL1Permission;
      },
      set: function (value) {
        this.$store.dispatch("setL1Permission", value);
      },
    },
    L2Permission: {
      get: function () {
        return this.$store.getters.getL2Permission;
      },
      set: function (value) {
        this.$store.dispatch("setL2Permission", value);
      },
    },
    L3Permission: {
      get: function () {
        return this.$store.getters.getL3Permission;
      },
      set: function (value) {
        this.$store.dispatch("setL3Permission", value);
      },
    },
    filterDatesEnable: {
      get: function () {
        return this.$store.getters.getFilterDatesEnable;
      },
      set: function (value) {
        this.$store.dispatch("setFilterDatesEnable", value);
      },
    },
    filterAgentEnable: {
      get: function () {
        return this.$store.getters.getFilterAgentEnable;
      },
      set: function (value) {
        this.$store.dispatch("setFilterAgentEnable", value);
      },
    },
    filterGroupEnable: {
      get: function () {
        return this.$store.getters.getFilterGroupEnable;
      },
      set: function (value) {
        this.$store.dispatch("setFilterGroupEnable", value);
      },
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ) &&
        window.innerWidth < 600
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style>
@font-face {
  font-family: "Gilroy";
  src: local("Gilroy"), url("./fonts/Gilroy-Regular.ttf");
}

@font-face {
  font-family: "GilroyB";
  src: local("GilroyB"), url("./fonts/Gilroy-Bold.ttf");
}

h1,
h2,
h3,
h4,
p,
b,
tr,
td,
div,
span,
canvas,
v-row,
v-card,
list-item,
v-button,
v-autocomplete,
v-breadcrumb {
  font-family: "Gilroy";
}

.title-bold {
  font-family: "GilroyB";
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}
</style>
