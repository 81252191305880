<template>
  <v-container class="pa-0 ma-0">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-card
          v-bind="attrs"
          v-on="on"
          outlined
          class="ma-0 pa-1 elevation-0"
          :style="{ color: colorSeverity }"
          @click="openListCVE()"
        >
          {{ labelSeverity }}
        </v-card>
      </template>
      <span>{{textApp.label00215}}</span>
    </v-tooltip>
    <v-dialog v-model="dialogDetailCVE" width="700">
      <v-card>
        <v-card-title>
          <h4 :style="{ color: $vuetify.theme.themes.light.secondary }" class="mb-5">
            {{textApp.label00216}}
          </h4>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="5">
              <h3>{{textApp.label00217}}</h3>
              <v-card
                v-for="(cve, i) in cves"
                :key="i"
                outlined
                class="ma-1 pa-1"
                @click="openDetailCVE(cve)"
              >
                {{ cve.cve }}
              </v-card>
            </v-col>
            <v-col cols="12" md="7">
              <v-card>
                <v-card-text><h3>{{textApp.label00216}}</h3></v-card-text>
                <v-card-text v-if="detailCVE != null && !loadingCVE">
                  <h3>{{ detailCVE.name }}</h3>
                  <div><b>{{textApp.label00218}} </b>{{ detailCVE.cve }}</div>
                  <div><b>{{textApp.label00219}} </b>{{ detailCVE.severity }}</div>
                  <div><b>{{textApp.label00220}} </b>{{ detailCVE.published }}</div>
                  <div><b>{{textApp.label00221}} </b>{{ detailCVE.cve_version }}</div>
                  <div><b>{{textApp.label00222}} </b>{{ detailCVE.cwe_reference }}</div>
                  <div><b>{{textApp.label00223}} </b>{{ detailCVE.condition }}</div>
                  <div><b>{{textApp.label00224}}</b> {{ detailCVE.version }}</div>
                  <div><b>{{textApp.label00225}}</b> {{ detailCVE.assigner }}</div>
                  <div><b>{{textApp.label00176}}</b></div>
                  <div>{{ detailCVE.title }}</div>
                  <div><b>{{textApp.label00226}}</b></div>
                  <div v-for="(item, i) in detailCVE.references" :key="i">
                    <a :href="item" target="_blank">{{ item }}</a>
                  </div>
                </v-card-text>
                <v-card-text
                  v-if="loadingCVE"
                  style="padding-top: 50px; padding-bottom: 50px;"
                >
                  <v-row align="center" justify="center">
                    <v-progress-circular
                      :size="120"
                      :width="10"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDetailCVE = false">
            {{textApp.label00042}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      detailCVE: null,
      loadingCVE: false,
      dialogDetailCVE: false
    };
  },
  props: ["severity", "cves"],
  mounted() {},
  methods: {
    openListCVE() {
      this.dialogDetailCVE = true;
    },
    openDetailCVE(cve) {
      this.detailCVE = cve.data;
      /*this.loadingCVE = true;
      this.axios({
        method: "GET",
        url: "/charts/vulnerability/cveDetail",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        params: {
          cve: cve,
          company: this.company.id
        }
      }).then(resp => {
        console.log(resp.data);
        this.detailCVE = resp.data;
        this.loadingCVE = false;
      });*/
    }
  },
  watch: {},
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
    token() {
      return this.$store.getters.getToken;
    },
    labelSeverity() {
      switch (this.severity) {
        case "Critical":
          return `${this.textApp.label00227}: ${this.cves.length}`;
        case "High":
          return `${this.textApp.label00228}: ${this.cves.length}`;
        case "Medium":
          return `${this.textApp.label00229}: ${this.cves.length}`;
        case "Low":
          return `${this.textApp.label00230}: ${this.cves.length}`;
        default:
          return this.cves.length;
      }
    },
    colorSeverity() {
      switch (this.severity) {
        case "Critical":
          return this.$vuetify.theme.themes.light.levelCritical;
        case "High":
          return this.$vuetify.theme.themes.light.levelHigh;
        case "Medium":
          return this.$vuetify.theme.themes.light.levelMedium;
        case "Low":
          return this.$vuetify.theme.themes.light.levelLow;
        default:
          return "#000";
      }
    }
  }
};
</script>
<style scoped></style>
