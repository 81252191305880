export const text = {
  state: {
    text: {},
    setup_company: {},
    dataLabel: {}
  },
  getters: {
    getTextApplication: state => state.text,
    getCompanySetup: state => state.setup_company,
    getDataLabel: state => state.dataLabel
  },
  mutations: {
    setTextApplication(state, value) {
      state.text = value;
    },
    setCompanySetup(state, value) {
      state.setup_company = value;
    },
    setDataLabel(state, value) {
      state.dataLabel = value;
    }
  },
  actions: {
    setTextApplication(context, value) {
      context.commit("setTextApplication", value);
    },
    setCompanySetup(context, value) {
      context.commit("setCompanySetup", value);
    },
    setDataLabel(context, value) {
      context.commit("setDataLabel", value);
    }
  }
};
