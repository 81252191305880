export const sections = [
  {
    title: "label00243",
    description: "label00244",
    icon: "mdi-domain",
    destination: "/",
    visible: true
  },
  {
    title: "label00245",
    description: "label00246",
    icon: "mdi-home",
    destination: "/home",
    code: "home",
    visible: true
  },
  {
    title: "label00248",
    description: "label00249",
    icon: "mdi-layers",
    destination: "/gdpr-generic",
    code: "gdpr-generic",
    visible: true
  },
  {
    title: "label00250",
    description: "label00251",
    icon: "mdi-book-check",
    destination: "/gdpr-categ",
    code: "gdpr-categ",
    visible: false
  },
  {
    title: "label00252",
    description: "label00253",
    icon: "mdi-file-document-multiple-outline",
    destination: "/gdpr-specific",
    code: "gdpr-specific",
    visible: false
  },
  {
    title: "label00254",
    description: "label00255",
    icon: "mdi-lock",
    destination: "/trade-secret",
    code: "trade-secret",
    visible: false
  },
  {
    title: "label00500",
    description: "label00501",
    icon: "mdi-microsoft-windows",
    destination: "/trade-secret-win",
    code: "trade-secret",
    visible: false
  },
  {
    title: "label00256",
    description: "label00257",
    icon: "mdi-laptop",
    destination: "/agents",
    code: "agents",
    visible: false
  },
  {
    title: "label00258",
    description: "label00259",
    icon: "mdi-account-details",
    destination: "/agentgroups",
    code: "agentgroups",
    visible: false
  },
  {
    title: "label00260",
    description: "label00261",
    icon: "mdi-message-arrow-right-outline",
    destination: "/warning",
    code: "warning",
    visible: true
  },
  {
    title: "label00518",
    description: "label00519",
    icon: "mdi-alert-box-outline",
    destination: "/alerts-soc",
    code: "alert-soc",
    visible: false
  },
  {
    title: "label00264",
    description: "label00265",
    icon: "mdi-clipboard-text-search-outline",
    destination: "/vulnerability",
    code: "vulnerability",
    visible: false
  },
  {
    title: "label00455",
    description: "label00455",
    icon: "mdi-chart-bar",
    destination: "/report",
    code: "report",
    visible: true
  },
  {
    title: "label00270",
    description: "label00271",
    icon: "mdi-account-cog-outline",
    destination: "/adminL2",
    code: "admin-l2",
    visible: false
  },
  {
    title: "label00272",
    description: "label00273",
    icon: "mdi-hammer-wrench",
    destination: "/adminL3",
    code: "admin-l3",
    visible: false
  },
  {
    title: "label00274",
    description: "label00275",
    icon: "mdi-information",
    destination: "/about",
    visible: true
  },
];
