<template>
  <v-container>

    <v-dialog v-model="dialog" width="700">
      <template #activator="{ on: dialog }">
        <v-tooltip bottom>
          <template #activator="{ on: tooltip }">
            <v-btn
              icon
              v-on="{ ...tooltip, ...dialog }"
            >
              <v-icon v-if="vulnstats == null" color="green">mdi-check-circle-outline</v-icon>
              <v-icon v-else :color="colorIcon">mdi-alert-outline</v-icon>
            </v-btn>
          </template>
          <span>
            <span v-if="vulnstats == null">{{ textApp.label00533 }}</span>
            <span v-else>
              <div v-if="'Critical' in vulnstats">{{ textApp.label00534 }} {{vulnstats.Critical}}</div>
              <div v-if="vulnstats != null && 'High' in vulnstats">{{ textApp.label00535 }} {{vulnstats.High}}</div>
              <div v-if="vulnstats != null && 'Medium' in vulnstats">{{ textApp.label00536 }} {{vulnstats.Medium}}</div>
              <div v-if="vulnstats != null && 'Low' in vulnstats">{{ textApp.label00537 }} {{vulnstats.Low}}</div>
            </span>
          </span>
        </v-tooltip>
      </template>

      <v-card>
        <v-card-title>
          <h4 :style="{ color: $vuetify.theme.themes.light.secondary }">{{ agent }} - {{ textApp.label00538 }}</h4>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-row>
            <v-col v-if="vulnstats == null" class="pa-3" cols="12" md="12" sm="12">
              <h3 class="mt-4">{{ textApp.label00539 }}</h3>
            </v-col>
            <v-col v-else class="pa-3" cols="12" md="12" sm="12">
              <VulnerabilityList :id_agent="agent" :reload="reloadVuln"></VulnerabilityList>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> {{ textApp.label00042 }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    
  </v-container>
</template>

<script>
import VulnerabilityList from "./VulnerabilityList";
export default {
  components: {
    VulnerabilityList
  },
  data() {
    return {
      dialog: false,
      reloadVuln: false
    };
  },
  props: ["agent", "vulnstats"],
  mounted() {
    //this.reloadVuln = !this.reloadVuln;
  },
  methods: {
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    company: {
      get: function () {
        return this.$store.getters.getCompany;
      },
      set: function (value) {
        this.$store.dispatch("setCompany", value);
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    colorIcon () {
      if ("Critical" in this.vulnstats)
        return "levelCritical";
      if ("High" in this.vulnstats)
         return "levelHigh";
      if ("Medium" in this.vulnstats)
        return "levelMedium";
      if ("Low" in this.vulnstats)
        return "levelLow";
      return "#000";
    }
  },
};
</script>
<style scoped></style>
