<template>
  <v-container class="pa-0 mb-3">
    <v-card class="elevation-0">
      <v-card-text v-if="!loading" class="pl-5">
        <v-card
          v-if="list == null || list.labels.length == 0"
          class="elevation-0 ma-3"
        >
          <v-card-text>
            <v-row style="height: 100px;" align="center" justify="center">
              <p class="ma-5">
                <v-icon class="mr-5">mdi-alert</v-icon>{{textApp.label00076}}
              </p>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row v-else align="center" justify="space-between">
          <h4>{{ title }}</h4>
          <v-col cols="12" md="12">
            <v-tooltip right v-for="(item, i) in list.labels" :key="i">
              <template v-slot:activator="{ on, attrs }">
                <v-row v-bind="attrs" v-on="on">
                  <v-list-item-subtitle class="pr-2 pt-2 mt-1"
                    style="cursor: pointer;"
                    @click="setFilter(list, i)"
                    ><b class="mr-1">{{ list.labels[i] }}</b
                    >({{ list.data[i] }})</v-list-item-subtitle
                  >
                  <v-progress-linear
                    :value="list.percentage[i]"
                  ></v-progress-linear>
                </v-row>
              </template>
              <span>{{ list.labels[i] }} - {{ list.data[i] }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text
        v-if="loading"
        style="padding-top: 50px; padding-bottom: 50px;"
      >
        <v-row align="center" justify="center">
          <v-progress-circular
            :size="120"
            :width="10"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["title", "type", "list", "loading"],
  mounted() {},
  methods: {
    setFilter(list, index) {
      let obj = {
        label: list.labels[index],
        value: (list.key == undefined || list.key == null) ? list.labels[index] : list.key[index],
        type_code: this.type,
        type_label: this.title
      };
      if (this.filtersApplication.filter(e => e.value == obj.value && e.type_code == obj.type_code).length == 0) {
        this.filtersApplication.push(obj);
      }
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    filtersApplication: {
      get: function () {
        return this.$store.getters.getFiltersApplication;
      },
      set: function (value) {
        this.$store.dispatch("setFiltersApplication", value);
      },
    },
  },
  watch: {}
};
</script>
<style scoped></style>
