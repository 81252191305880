<template>
  <v-container>
    <v-row align="start" justify="start">
      <v-col cols="12" md="12" sm="12">
        <v-card outlined>
          <v-card-text>
            <v-row justify="start" class="pl-5 pr-5">
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="textApp.label00084"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
            </v-row>
            <v-row justify="start">
              <v-data-table
                :headers="headers"
                :items="listAlerts"
                :search="search"
                :footer-props="footerProps"
                dense
              >
                <template v-slot:item="row">
                  <tr>
                    <td>{{ row.item.id }}</td>
                    <td>
                      {{
                        `${row.item.tstp.substring(
                          0,
                          10
                        )} ${row.item.tstp.substring(11, 16)}`
                      }}
                    </td>
                    <td>{{ row.item.title }}</td>
                    <td>
                      <v-tooltip bottom>
                        <template #activator="{ on: onTooltip }">
                          <v-icon
                            :color="row.item.shown ? 'green' : 'red'"
                            v-on="{ ...onTooltip }"
                          >
                            mdi-checkbox-blank-circle
                          </v-icon>
                        </template>
                        <span>{{ row.item.shown ? textApp.label00523 : textApp.label00524 }}</span>
                      </v-tooltip>
                    </td>
                    <td>
                      <DetailAlertSOC :alert="row.item" @updateAlerts="loadData"></DetailAlertSOC>
                    </td>
                  </tr>
                </template>
                <template v-slot:no-data>
                  <v-card-text> {{textApp.label00089}} </v-card-text>
                </template>
              </v-data-table>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DetailAlertSOC from '../components/DetailAlertSOC.vue';
export default {
  components: {
    DetailAlertSOC
  },
  data() {
    return {
      search: "",
      headers: [],
      footerProps: null,
      listAlerts: [],
      offset: true,
    };
  },
  mounted() {
    this.loadData();
    this.headers = [
      { text: this.textApp.label00056, value: "id" },
      { text: this.textApp.label00151, value: "tstp" },
      { text: this.textApp.label00176, value: "description" },
      { text: this.textApp.label00094, value: "close" },
      { text: this.textApp.label00154, value: "visualize" },
    ];
    this.footerProps = {
      itemsPerPageText: this.textApp.label00095,
      pageText: `{0}-{1} ${this.textApp.label00096} {2}`,
      "items-per-page-all-text": this.textApp.label00097,
    };
  },
  methods: {
    loadData() {
      this.axios({
        method: "GET",
        url: "/alerts/list",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        params: {
          company: this.company.id,
        },
      }).then((resp) => {
        console.log(resp.data);
        if(resp.data.success) {
          this.listAlerts = resp.data.data;
        }
      });
    },
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function () {
        return this.$store.getters.getCompany;
      },
      set: function (value) {
        this.$store.dispatch("setCompany", value);
      },
    },
    credentials: {
      get: function () {
        return this.$store.getters.getCredentials;
      },
      set: function (value) {
        this.$store.dispatch("setCredentials", value);
      },
    },
  },
};
</script>
<style scoped></style>
