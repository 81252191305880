<template>
  <v-container class="pa-0 ma-0">
    <v-card outlined v-if="data != null" >
      <v-card-text>
        <v-row class="mt-3 mb-5">
          <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
            <v-row style="padding: 10px;">
              <v-col 
                cols="12" 
                md="6"
                v-for="(d, i) in data"
                :key="i"
              >
                  <h3>{{ d.label }}</h3>
                  <v-progress-circular
                    :rotate="180"
                    :size="100"
                    :width="15"
                    :value="Math.abs(d.perc)"
                    :color="d.perc > 0 ? 'red' : 'green'"
                  >
                    {{ d.perc.toFixed(2) }} %
                  </v-progress-circular>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: ['data'],
  data() {
    return {};
  },
  mounted() {
  },
  methods: {
  },
  computed: {
  },
  watch: {
  }
};
</script>
<style scoped></style>
