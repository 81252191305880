<template>
  <v-container class="pr-5 pl-5">
    <v-row align="center" justify="center">
      <v-col cols="12" lg="12" md="12" sm="12">
        <v-card outlined class="pa-5">
          <v-row v-if="stats != null && !loadingStats">
            <v-col cols="12" lg="3" md="3" sm="12">
              <VulnerabilityStat
                :stat="stats.Critical"
                :level="textApp.label00206"
                level_key="Critical"
                :color="$vuetify.theme.themes.light.levelCritical"
                :label="textApp.label00405"
                @openDetail="openStatsDetail"
              >
              </VulnerabilityStat>
            </v-col>
            <v-col cols="12" lg="3" md="3" sm="12">
              <VulnerabilityStat
                :stat="stats.High"
                :level="textApp.label00207"
                level_key="High"
                :color="$vuetify.theme.themes.light.levelHigh"
                :label="textApp.label00406"
                @openDetail="openStatsDetail"
              ></VulnerabilityStat>
            </v-col>
            <v-col cols="12" lg="3" md="3" sm="12">
              <VulnerabilityStat
                :stat="stats.Medium"
                :level="textApp.label00208"
                level_key="Medium"
                :color="$vuetify.theme.themes.light.levelMedium"
                :label="textApp.label00407"
                @openDetail="openStatsDetail"
              ></VulnerabilityStat>
            </v-col>
            <v-col cols="12" lg="3" md="3" sm="12">
              <VulnerabilityStat
                :stat="stats.Low"
                :level="textApp.label00209"
                level_key="Low"
                :color="$vuetify.theme.themes.light.levelLow"
                :label="textApp.label00408"
                @openDetail="openStatsDetail"
              ></VulnerabilityStat>
            </v-col>
          </v-row>
          <v-row v-if="loadingStats" align="center" justify="center">
            <v-progress-circular
              :size="120"
              :width="10"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </v-card>
      </v-col>
      <v-dialog v-model="dialogDetailStats" width="600">
        <v-card>
          <v-card-title>
            <h4 :style="{ color: $vuetify.theme.themes.light.secondary }">
              {{textApp.label00210}}
            </h4>
          </v-card-title>

          <v-card-text v-if="statDisplayDetail != null">
            <h3>{{textApp.label00211}} {{ statDisplayDetail.level }}</h3>
            <v-row v-for="(prog, i) in statDisplayDetail.programs" :key="i">
              <v-col cols="12" md="7">
                <b>{{ prog.name }}</b>
                <div v-for="(vers, j) in prog.versions" :key="j">
                  {{ vers }}
                </div>
              </v-col>
              <v-col cols="12" md="5">
                <VulnerabilitySeverity
                  :severity="statDisplayDetail.level_key"
                  :cves="prog.cve"
                ></VulnerabilitySeverity>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialogDetailStats = false">
              {{textApp.label00042}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <VulnerabilityList></VulnerabilityList>
  </v-container>
</template>

<script>
import VulnerabilitySeverity from "../components/VulnerabilitySeverity";
import VulnerabilityStat from "../components/VulnerabilityStat";
import VulnerabilityList from "../components/VulnerabilityList";
export default {
  components: {
    VulnerabilitySeverity,
    VulnerabilityStat,
    VulnerabilityList
  },
  data() {
    return {
      stats: null,
      listVulnerabilities: [],
      loadingStats: false,
      loadingVulnList: false,
      dialogDetailStats: false,
      statDisplayDetail: null
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loadingStats = true;
      this.axios({
        method: "GET",
        url: "/charts/vulnerability/severityCount",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        params: {
          filterStartDate: this.filterStartDate.substring(
            0,
            this.filterStartDate.length - 5
          ),
          filterEndDate: this.filterEndDate.substring(
            0,
            this.filterStartDate.length - 5
          ),
          filterAgentSelected: this.filterAgents,
          company: this.company.id,
          agent: this.agent
        }
      }).then(resp => {
        console.log(resp.data);
        this.stats = resp.data;
        this.loadingStats = false;
      });
    },
    openStatsDetail(stat, level, level_key) {
      this.dialogDetailStats = true;
      this.statDisplayDetail = {
        level: level,
        level_key: level_key,
        programs: stat
      };
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    filterStartDate: {
      get: function() {
        return this.$store.getters.getStartDate;
      },
      set: function(value) {
        this.$store.dispatch("setStartDate", value);
      }
    },
    filterEndDate: {
      get: function() {
        return this.$store.getters.getEndDate;
      },
      set: function(value) {
        this.$store.dispatch("setEndDate", value);
      }
    },
    filterAgents: {
      get: function() {
        return this.$store.getters.getAgents;
      },
      set: function(value) {
        this.$store.dispatch("setAgents", value);
      }
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
    token() {
      return this.$store.getters.getToken;
    },
    agent() {
      if(this.$route.params.idAgent == undefined || this.$route.params.idAgent == null) {
        return this.id_agent;
      }
      return this.$route.params.idAgent;
    },
  },
  watch: {
    agent() {
      this.loadData();
    }
  }
};
</script>
<style scoped></style>
