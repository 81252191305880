<template>
  <v-container>
    <v-row>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="ml-3"
            color="secondary"
            small
            @click="goToCommand()"
          >
            {{textApp.label00421}}
          </v-btn>
        </template>
        <span>{{textApp.label00420}}</span>
      </v-tooltip>
    </v-row>
    <v-row class="mt-5">
      <v-simple-table
        fixed-header
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Name
              </th>
              <th class="text-left">
                Description
              </th>
              <th class="text-left">
              </th>
              <th class="text-left">
              </th>
              <th class="text-left">
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in listCommands"
              :key="item.name"
            >
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td>
                <EditCommand :script="item"></EditCommand>
              </td>
              <td>
                <DetailCommand :script="item"></DetailCommand>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-row>
  </v-container>
</template>

<script>
import DetailCommand from "../components/DetailCommand"
import EditCommand from "../components/EditCommand"
export default {
  components: {
    DetailCommand,
    EditCommand
  },
  data() {
    return {
      listCommands: [],
      dialog: false
    };
  },
  mounted() {
    this.axios({
      method: "GET",
      url: "/commands/list",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      params: {
        company: this.company.id,
      },
    }).then((resp) => {
      console.log(resp.data);
      this.listCommands = resp.data;
    });
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    goToCommand() {
      this.$router.push(`/agentdetail/${this.agent}/commands`);
    }
  },
  computed: {
    companySetup: {
      get: function() {
        return this.$store.getters.getCompanySetup;
      }
    },
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      }
    },
    token() {
      return this.$store.getters.getToken;
    },
    agent() {
      return this.$route.params.idAgent;
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
  }
};
</script>
<style scoped></style>
