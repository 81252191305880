<template>
  <span class="">
    <v-btn
      class="mb-5"
      color="primary"
      style="height: 40px;"
      @click="newUserCompanyAssignment()"
    >
      {{textApp.label00015}}
      <v-icon right dark>
        mdi-target-account
      </v-icon>
    </v-btn>
    <v-dialog v-model="dialogNewCompanyAssignment" width="500">
      <v-card>
        <v-card-title>
          <h4 :style="{ color: $vuetify.theme.themes.light.secondary }" class="mb-5">
            {{textApp.label00016}}
          </h4>
        </v-card-title>

        <v-card-text >
          <v-row>
            <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
              <v-select
                v-model="userSelected"
                :label="textApp.label00017"
                :items="listUser"
                item-text="name"
                item-value="id"
                outlined
              ></v-select>
            </v-col>

            <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
              <v-select
                v-model="companySelected"
                :label="textApp.label00018"
                :items="listCompany"
                item-text="name"
                item-value="id"
                outlined
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogNewCompanyAssignment = false">
            {{textApp.label00012}}
          </v-btn>
          <v-btn color="primary" text @click="confirmNewCompanyAssignment()">
            {{textApp.label00013}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      dialogNewCompanyAssignment: false,
      userSelected: null,
      companySelected: null
    };
  },
  props: ["listUser", "listCompany"],
  mounted() {},
  methods: {
    newUserCompanyAssignment() {
      this.dialogNewCompanyAssignment = true;
    },
    confirmNewCompanyAssignment() {
      this.axios({
        method: "POST",
        url: `/admin/L1/user/setCompany`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        data: {
          target_usr_id: this.userSelected,
          target_usr_company: this.companySelected
        }
      }).then(resp => {
        console.log(resp.data);
        if (!resp.data.Error) {
          this.dialogNewCompanyAssignment = false;
        } else {
          console.log("ERRORE SET COMPANY");
          this.dialogNewCompanyAssignment = false;
        }
      });
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      }
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
    token() {
      return this.$store.getters.getToken;
    }
  },
  watch: {}
};
</script>
<style scoped></style>
