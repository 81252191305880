<template>
  <v-container class="pr-5 pl-5">
    <v-row>
      <v-col cols="12" md="8">
        <time-bar-template
          :title="textApp.label00181"
          linkAPI="/charts/specific/timelineAgents"
          :widthChart="6"
          :outlined="true"
        ></time-bar-template>
        <module-template
          class="mt-3"
        ></module-template>
      </v-col>
      <v-col cols="12" md="4">
        <list-filters-log style="position: sticky; top: 100px;"></list-filters-log>
      </v-col>
    </v-row>
    
    <!--<v-divider class="mt-5 mb-3"></v-divider>-->
    
  </v-container>
</template>

<script>
import TimeBarTemplate from "../components/TimeBarTemplate";
import ModuleTemplate from "../components/ModuleTemplate";
import ListFiltersLog from '../components/ListFiltersLog.vue';

export default {
  components: {
    ModuleTemplate,
    TimeBarTemplate,
    ListFiltersLog,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    dataLabel: {
      get: function () {
        return this.$store.getters.getDataLabel;
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function () {
        return this.$store.getters.getCompany;
      },
      set: function (value) {
        this.$store.dispatch("setCompany", value);
      },
    },
  },
  watch: {},
};
</script>
<style scoped></style>
