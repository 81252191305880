<template>
  <v-container>
    <v-card outlined>
      <v-row v-if="!loading && cpu != null && ram != null">
        <v-col cols="12" md="4">
          <v-card-text>
            <h4>{{textApp.label00122}}</h4>
            <span>{{ serial }}</span>
          </v-card-text>
        </v-col>
        <v-col cols="12" md="4">
          <v-card-text>
            <h4>{{textApp.label00123}}</h4>
            <div>{{ cpu.name }}</div>
            <div>{{ cpu.cores }} core</div>
            <div>{{ cpu.mhz }} mhz</div>
          </v-card-text>
        </v-col>
        <v-col cols="12" md="4">
          <v-card-text>
            <h4>{{textApp.label00124}}</h4>
            <div>{{textApp.label00125}} {{ ram.total }}KB ({{ KBtoGB(ram.total) }}GB)</div>
            <div>{{textApp.label00126}} {{ ram.free }}KB ({{ KBtoGB(ram.free) }}GB)</div>
            <div>{{textApp.label00127}} {{ ram.usage }}%</div>
          </v-card-text>
        </v-col>
      </v-row>
      <v-row v-if="loading">
        <v-card-text style="padding-top: 50px; padding-bottom: 50px">
          <v-row align="center" justify="center">
            <v-progress-circular
              :size="120"
              :width="10"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </v-card-text>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loading: false,
      serial: null,
      cpu: null,
      ram: null
    };
  },
  props: ['data'],
  mounted() {
    if(this.data != null) {
      this.serial = this.data[0].board_serial;
      this.cpu = this.data[0].cpu;
      this.ram = this.data[0].ram;
    } else {
      this.loadData();
    }
  },
  methods: {
    loadData() {
      this.loading = true;
      this.axios({
        method: "GET",
        url: "/agents/hardware",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        params: {
          agent: this.agent,
          company: this.company.id
        }
      }).then(resp => {
        console.log(resp.data);
        if (!resp.data.Error && resp.data.length > 0) {
          this.serial = resp.data[0].board_serial;
          this.cpu = resp.data[0].cpu;
          this.ram = resp.data[0].ram;
        }
        this.loading = false;
      });
    },
    KBtoGB(data) {
      return (data / 1024 / 1024).toFixed(2);
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      }
    },
    agent() {
      return this.$route.params.idAgent;
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    }
  }
};
</script>
<style scoped></style>
