<template>
  <v-container>
    <v-row v-if="loadingUsers" align="center" justify="center">
      <v-progress-circular
        :size="120"
        :width="10"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-row>
    <v-row v-if="listUsers.length > 0">
      <h4 :style="{ color: $vuetify.theme.themes.light.secondary }" class="mt-5 mb-5" >Utenti presenti nell'azienda</h4>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                {{textApp.label00056}}
              </th>
              <th class="text-left">
                {{textApp.label00005}}
              </th>
              <th class="text-left">
                {{textApp.label00006}}
              </th>
              <th class="text-left">
                {{textApp.label00007}}
              </th>
              <th 
                class="text-left" 
                v-for="(item, i) in listFunctionalities" :key="i"
              >
                {{dataLabel[item.code].name}}
              </th>
              <th class="text-left">{{ textApp.label00528 }}</th>
              <th v-if="adminLevel == 'L1'" class="text-left">{{textApp.label00060}}</th>
              <th v-if="adminLevel == 'L1'" class="text-left">{{textApp.label00061}}</th>
              <th
                v-if="adminLevel == 'L1' || adminLevel == 'L2'"
                class="text-left"
              >
                {{textApp.label00062}}
              </th>
              <th class="text-left">{{textApp.label00063}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, i) in listUsers" :key="i">
              <td>{{ user.id }}</td>
              <td>{{ user.name }}</td>
              <td>{{ user.surname }}</td>
              <td>{{ user.email }}</td>
              <td v-for="(fun, j) in listFunctionalities" :key="j">
                <v-checkbox
                  v-model="user.permissions[fun.code]"
                ></v-checkbox>
              </td>
              <td>
                <Enable2FA :target_company="baseCompanySelected" :base_company_l2="company" :user="user" :adminLevel="adminLevel"></Enable2FA>
              </td>
              <td v-if="adminLevel == 'L1'">
                <v-btn icon @click="openDialogSetAdminL1(user)">
                  <v-icon color="primary">mdi-account-tie</v-icon>
                </v-btn>
              </td>
              <td v-if="adminLevel == 'L1'">
                <v-btn icon @click="openDialogSetAdminL2(user)">
                  <v-icon color="primary">mdi-account-cog-outline</v-icon>
                </v-btn>
              </td>
              <td v-if="adminLevel == 'L1' || adminLevel == 'L2'">
                <v-btn icon @click="openDialogSetAdminL3(user)">
                  <v-icon color="primary">mdi-hammer-wrench</v-icon>
                </v-btn>
              </td>
              <td>
                <v-btn icon @click="sendMail(user)">
                  <v-icon color="primary">mdi-email-arrow-right-outline</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-tooltip bottom>
        <template #activator="{ on: tooltip }">
          <v-btn
          class="mt-5"
            color="primary"
            small
            dark
            v-on="{ ...tooltip }"
            @click="updateUsers()"
          >
            SALVA
          </v-btn>
        </template>
        <span>Aggiorna</span>
      </v-tooltip>
      <v-dialog v-model="dialogAdminL1" width="500">
        <v-card>
          <v-card-title>
            <h4 :style="{ color: $vuetify.theme.themes.light.secondary }" class="mb-5">
              {{textApp.label00047}} {{ userSelected.id }} {{textApp.label00048}}
            </h4>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
                <div>
                  {{textApp.label00049}} {{ userSelected.name }}
                  {{ userSelected.surname }} {{textApp.label00050}}
                </div>
                {{userToSetL1}}
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogAdminL1 = false">
              {{textApp.label00012}}
            </v-btn>
            <v-btn color="primary" text @click="confirmSetAdminL1(userToSetL1)">
              {{textApp.label00013}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogAdminL2" width="500">
        <v-card>
          <v-card-title>
            <h4 :style="{ color: $vuetify.theme.themes.light.secondary }" class="mb-5">
              {{textApp.label00047}} {{ userSelected.id }} {{textApp.label00051}}
            </h4>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
                <!--<div>
                  Sei sicuro di voler impostare {{userSelected.name}} {{userSelected.surname}} come amministratore globale?
                </div>-->
                <v-row>
                  <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
                    <v-select
                      v-model="companySelectedToAssignL2"
                      :label="textApp.label00052"
                      :items="listCompanyProviderL2"
                      item-text="name_company"
                      item-value="id_company"
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogAdminL2 = false">
              {{textApp.label00012}}
            </v-btn>
            <v-btn color="primary" text @click="confirmSetAdminL2(userSelected)">
              {{textApp.label00013}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogAdminL3" width="500">
        <v-card>
          <v-card-title>
            <h4 :style="{ color: $vuetify.theme.themes.light.secondary }" class="mb-5">
              {{textApp.label00047}} {{ userSelected.id }} {{textApp.label00053}}
            </h4>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
                <div>
                  {{textApp.label00054}} {{ userSelected.name }}
                  {{ userSelected.surname }} {{textApp.label00055}}
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogAdminL3 = false">
              {{textApp.label00012}}
            </v-btn>
            <v-btn color="primary" text @click="confirmSetAdminL3(userSelected)">
              {{textApp.label00013}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import Enable2FA from './Enable2FA';
export default {
  components: { 
    Enable2FA
  },
  data() {
    return {
      listFunctionalities: [],
      listUsers: [],
      loadingUsers: false,
      dialogAdminL1: false,
      dialogAdminL2: false,
      dialogAdminL3: false,
      userSelected: {
        name: null,
        surname: null,
        email: null,
        password: null
      },
      userToSetL1: null,
      userToSetL2: null,
      userToSetL3: null,
      companySelectedToAssignL2: null,
    };
  },
  mounted() {
    this.loadData();
  },
  props: [
    "refreshPage",
    "adminLevel",
    "baseCompanySelected",
    "listCompanyProviderL2"
  ],
  methods: {
    loadData() {
      this.listUsers = [];
      this.loadingUsers = true;
      let data = {};
      switch (this.adminLevel) {
        case "L1":
          data = {
            target_company: this.baseCompanySelected
          };
          break;
        case "L2":
          data = {
            company: this.company.id,
            target_company: this.baseCompanySelected
          };
          break;
        case "L3":
          data = {
            company: this.baseCompanySelected
          };
          break;
      }
      this.axios({
        method: "GET",
        url: `/admin/${this.adminLevel}/user/listComplete`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        params: data
      }).then(resp => {
        if(!resp.data.Error) {
          this.listFunctionalities = resp.data.list_functionalities;
          this.listUsers = resp.data.list_users;
        }
        this.loadingUsers = false;
      });
    },
    updateUsers() {
      let data = {};
      switch (this.adminLevel) {
        case "L1":
          data = {
            target_company: this.baseCompanySelected,
            list_users: this.listUsers
          };
          break;
        case "L2":
          data = {
            company: this.company.id,
            target_company: this.baseCompanySelected,
            list_users: this.listUsers
          };
          break;
        case "L3":
          data = {
            company: this.baseCompanySelected,
            list_users: this.listUsers
          };
          break;
      }
      this.axios({
        method: "POST",
        url: `/admin/${this.adminLevel}/user/updateUsers`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        data: data
      }).then(resp => {
        console.log(resp.data);
      });
    },
    openDialogSetAdminL1(user) {
      this.dialogAdminL1 = true;
      this.userToSetL1 = user;
    },
    confirmSetAdminL1() {
      this.dialogAdminL1 = true;
      this.axios({
        method: "POST",
        url: `/admin/${this.adminLevel}/user/setL1Permission`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        data: {
          target_usr_id: this.userToSetL1.id
        }
      }).then(resp => {
        console.log(resp.data);
        this.dialogAdminL1 = false;
      });
    },
    openDialogSetAdminL2(user) {
      this.dialogAdminL2 = true;
      this.userToSetL2 = user;
    },
    confirmSetAdminL2() {
      this.axios({
        method: "POST",
        url: `/admin/${this.adminLevel}/user/setFunctionalitiesAdminL2`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        data: {
          target_usr_company: this.baseCompanySelected,
          target_usr_id: this.userToSetL2.id
        }
      }).then(resp => {
        console.log(resp.data);
        this.setAdminL2CompanyAssignment();
      });
    },
    setAdminL2CompanyAssignment() {
      this.axios({
        method: "POST",
        url: `/admin/${this.adminLevel}/user/companyAssignedToL2`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        data: {
          target_provider_company: this.baseCompanySelected,
          target_client_company: this.companySelectedToAssignL2,
          target_usr_id: this.userToSetL2.id
        }
      }).then(resp => {
        console.log(resp.data);
        this.dialogAdminL2 = false;
      });
    },
    openDialogSetAdminL3(user) {
      this.dialogAdminL3 = true;
      this.userToSetL3 = user;
    },
    confirmSetAdminL3() {
      this.axios({
        method: "POST",
        url: `/admin/${this.adminLevel}/user/setL3Permission`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        data: {
          target_usr_id: this.userToSetL3.id,
          target_usr_company: this.baseCompanySelected
        }
      }).then(resp => {
        console.log(resp.data);
        this.dialogAdminL3 = false;
      });
    },
    sendMail(user) {
      this.axios({
        method: "POST",
        url: `/admin/${this.adminLevel}/user/sendMailPassword`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        data: {
          company: this.baseCompanySelected,
          id_usr: user.id
        }
      }).then(resp => {
        console.log(resp.data);
      });
    },
  },
  computed: {
    companySetup: {
      get: function() {
        return this.$store.getters.getCompanySetup;
      }
    },
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      }
    },
    dataLabel: {
      get: function () {
        return this.$store.getters.getDataLabel;
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
  },
  watch: {
    refreshPage() {
      this.loadData();
    },
    baseCompanySelected() {
      this.loadData();
    }
  }
};
</script>
<style scoped></style>
