<template>
  <v-container class="pr-5 pl-5">
    <v-row align="start" justify="start">
      <v-col cols="12" md="12">
        <new-group v-on:refreshPage="updateRefresh()"></new-group>
      </v-col>
    </v-row>
    <v-row align="start" justify="start">
      <v-col cols="12" md="8">
        <list-groups :refresh="refresh"></list-groups>
      </v-col>
      <v-col cols="12" md="4">
        <doughnut-template
          :title="textApp.label00082"
          linkAPI="/charts/agents/top5"
          :type_code="constants.IDAGENTS.type_code"
          :type_label="textApp[constants.IDAGENTS.type_label]"
          :rawLabel="true"
        ></doughnut-template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DoughnutTemplate from "../components/DoughnutTemplate";
import ListGroups from "../components/ListGroups";
import NewGroup from "../components/NewGroup";
import constants from "../config/constants";
export default {
  components: {
    DoughnutTemplate,
    ListGroups,
    NewGroup
  },
  data() {
    return {
      refresh: 0,
      constants: constants
    };
  },
  mounted() {},
  methods: {
    updateRefresh() {
      this.refresh++;
    }
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    token() {
      return this.$store.getters.getToken;
    }
  }
};
</script>
<style scoped></style>
