<template>
  <v-navigation-drawer v-model="drawer" app>
    <v-list dense>
      <v-tooltip right v-for="(tile, j) in sections" :key="j">
        <template v-slot:activator="{ on, attrs }">
          <v-list-item
            v-bind="attrs"
            v-on="on"
            link
            :to="tile.destination"
            exact-active-class="primary--text"
          >
            <v-list-item-action>
              <v-icon>{{ tile.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ tile.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <span>{{ tile.description }}</span>
      </v-tooltip>
    </v-list>

    <v-footer absolute color="primary">
      <v-spacer></v-spacer>
      <div style="color: white">&copy; {{ companySetup.company_name }} 
        <div v-if="companySetup.powered_by">{{textApp.label00242}}</div>
        <div>{{textApp.label00001}} {{version}}</div>
      </div>
      
    </v-footer>
  </v-navigation-drawer>
</template>

<script>
import { sections } from "../config/dashboard-items.js";
import { version } from "../config/config.js";
export default {
  data() {
    return {
      sections: [],
      drawer: true,
      version: null
    };
  },
  props: ['toggleDrawer'],
  mounted() {
    this.version = version;
    this.setNavbarItems();
  },
  methods: {
    setNavbarItems() {
      if (this.role != null) {
        this.sections = [];
        sections.forEach((sect) => {
          if (sect.visible) {
            this.sections.push(this.convertTextSections(sect));
          } else if (
            sect.code == "gdpr-categ" &&
            (this.checkRoleID(2) || this.checkRoleID(3))
          ) {
            this.sections.push(this.convertTextSections(sect));
          } else if (
            sect.code == "trade-secret" &&
            (this.checkRoleID(2) || this.checkRoleID(3))
          ) {
            this.sections.push(this.convertTextSections(sect));
          } else if (sect.code == "gdpr-specific" && this.checkRoleID(3)) {
            this.sections.push(this.convertTextSections(sect));
          } else if (sect.code == "agents" && this.checkRoleID(3)) {
            this.sections.push(this.convertTextSections(sect));
          } else if (sect.code == "agentgroups" && this.checkRoleID(3)) {
            this.sections.push(this.convertTextSections(sect));
          } else if (sect.code == "vulnerability" && this.checkRoleID(3)) {
            this.sections.push(this.convertTextSections(sect));
          } else if (sect.code == "admin-l2" && this.L2Permission) {
            this.sections.push(this.convertTextSections(sect));
          } else if (sect.code == "admin-l3" && this.L3Permission) {
            this.sections.push(this.convertTextSections(sect));
          } else if (sect.code == "alert-soc" && this.module_soc == 1) {
            this.sections.push(this.convertTextSections(sect));
          }
        });
      }
    },
    convertTextSections(sect) {
      let section = JSON.parse(JSON.stringify(sect));
      section.title = this.textApp[section.title];
      section.description = this.textApp[section.description];
      return section;
    },
    checkRoleID(id) {
      let found = false;
      if (this.role != null) {
        for (let i = 0; i < this.role.length; i++) {
          if (this.role[i].id == id) {
            found = true;
          }
        }
      }
      return found;
    },
  },
  watch: {
    credentials() {
      this.setNavbarItems();
    },
    role() {
      this.setNavbarItems();
    },
    dataLabel() {
      this.setNavbarItems();
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    }
  },
  computed: {
    companySetup: {
      get: function() {
        return this.$store.getters.getCompanySetup;
      }
    },
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      }
    },
    dataLabel: {
      get: function () {
        return this.$store.getters.getDataLabel;
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
    role: {
      get: function () {
        return this.$store.getters.getRole;
      },
      set: function (value) {
        this.$store.dispatch("setRole", value);
      },
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    credentials: {
      get: function () {
        return this.$store.getters.getCredentials;
      },
      set: function (value) {
        this.$store.dispatch("setCredentials", value);
      },
    },
    L2Permission: {
      get: function () {
        return this.$store.getters.getL2Permission;
      },
      set: function (value) {
        this.$store.dispatch("setL2Permission", value);
      },
    },
    L3Permission: {
      get: function () {
        return this.$store.getters.getL3Permission;
      },
      set: function (value) {
        this.$store.dispatch("setL3Permission", value);
      },
    },
    module_soc: {
      get: function() {
        return this.$store.getters.getModuleSOC;
      },
    },
  }
};
</script>
<style scoped></style>
