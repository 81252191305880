const getDefaultFilterState = () => {
  let today = new Date().toISOString(0, 23);
  let weekAgo = new Date();
  weekAgo = new Date(
    weekAgo.getFullYear(),
    weekAgo.getMonth(),
    weekAgo.getDate() - 6
  ).toISOString(0, 23);
  return {
    requirementSelectedGDPR: null,
    startDate: weekAgo,
    endDate: today,
    agents: [],
    group: [],
    filterDatesEnable: false,
    filterAgentEnable: false,
    filterGroupEnable: false,
    reloadButton: 0,
    filtersApplication: [],
    adminArea: false,
    granularityCompanySelected: "alert"
  };
};

export const filters = {
  state: getDefaultFilterState(),
  getters: {
    getRequirementSelectedGDPR: state => state.requirementSelectedGDPR,
    getStartDate: state => state.startDate,
    getEndDate: state => state.endDate,
    getAgents: state => state.agents,
    getGroup: state => state.group,
    getFilterDatesEnable: state => state.filterDatesEnable,
    getFilterAgentEnable: state => state.filterAgentEnable,
    getFilterGroupEnable: state => state.filterGroupEnable,
    getReloadButton: state => state.reloadButton,
    getFiltersApplication: state => state.filtersApplication,
    getAdminArea: state => state.adminArea,
    getGranularityCompanySelected: state => state.granularityCompanySelected,
  },
  mutations: {
    resetFilterState(state) {
      Object.assign(state, getDefaultFilterState());
    },
    setRequirementSelectedGDPR(state, value) {
      state.requirementSelectedGDPR = value;
    },
    setStartDate(state, value) {
      state.startDate = value;
    },
    setEndDate(state, value) {
      state.endDate = value;
    },
    setAgents(state, value) {
      state.agents = value;
    },
    setGroup(state, value) {
      state.group = value;
    },
    setFilterDatesEnable(state, value) {
      state.filterDatesEnable = value;
    },
    setFilterAgentEnable(state, value) {
      state.filterAgentEnable = value;
    },
    setFilterGroupEnable(state, value) {
      state.filterGroupEnable = value;
    },
    setReloadButton(state, value) {
      state.reloadButton = value;
    },
    setFiltersApplication(state, value) {
      state.filtersApplication = value;
    },
    setAdminArea(state, value) {
      state.adminArea = value;
    },
    setGranularityCompanySelected(state, value) {
      state.granularityCompanySelected = value;
    },
  },
  actions: {
    resetFilterState(context) {
      context.commit("resetFilterState");
    },
    setRequirementSelectedGDPR(context, value) {
      context.commit("setRequirementSelectedGDPR", value);
    },
    setStartDate(context, value) {
      context.commit("setStartDate", value);
    },
    setEndDate(context, value) {
      context.commit("setEndDate", value);
    },
    setAgents(context, value) {
      context.commit("setAgents", value);
    },
    setGroup(context, value) {
      context.commit("setGroup", value);
    },
    setFilterDatesEnable(context, value) {
      context.commit("setFilterDatesEnable", value);
    },
    setFilterAgentEnable(context, value) {
      context.commit("setFilterAgentEnable", value);
    },
    setFilterGroupEnable(context, value) {
      context.commit("setFilterGroupEnable", value);
    },
    setReloadButton(context, value) {
      context.commit("setReloadButton", value);
    },
    setFiltersApplication(context, value) {
      context.commit("setFiltersApplication", value);
    },
    setAdminArea(context, value) {
      context.commit("setAdminArea", value);
    },
    setGranularityCompanySelected(context, value) {
      context.commit("setGranularityCompanySelected", value);
    }
  }
};
