<template>
  <v-container>
    <v-dialog v-model="dialog" width="700">
      <template #activator="{ on: dialog }">
        <v-tooltip bottom>
          <template #activator="{ on: tooltip }">
            <v-btn
              color="secondary"
              small
              dark
              v-on="{ ...tooltip, ...dialog }"
            >
              {{textApp.label00172}}
            </v-btn>
          </template>
          <span>{{textApp.label00173}}</span>
        </v-tooltip>
      </template>

      <v-card>
        <v-card-title>
          <h4 :style="{ color: $vuetify.theme.themes.light.secondary }">{{textApp.label00174}}</h4>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-select
                v-model="ids_collaborators"
                :items="listUser"
                :label="textApp.label00162"
                item-text="name"
                item-value="id"
                multiple
              ></v-select>
            </v-col>
            <v-col
              v-if="reportType != null"
              cols="12"
              sm="12"
              md="12"
              class="pb-0"
            >
              <v-text-field
                :label="textApp.label00156"
                :value="labelReport"
                outlined
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="pb-0">
              <v-text-field
                :label="textApp.label00175"
                v-model="title"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="pt-0">
              <v-textarea
                outlined
                name="input-7-4"
                :label="textApp.label00176"
                v-model="description"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()"> {{textApp.label00164}} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      title: null,
      ids_collaborators: [],
      listUser: [],
      description: null
    };
  },
  props: ["reportType", "filters"],
  mounted() {
    this.axios({
      method: "GET",
      url: "/warnings/receivers",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`
      },
      params: {
        company: this.company.id
      }
    }).then(resp => {
      console.log(resp.data);
      if (!resp.data.Error) {
        resp.data.forEach(user => {
          this.listUser.push({
            name: user.name + " " + user.surname,
            id: user.id
          });
        });
        console.log(this.listUser);
      }
    });
  },
  computed: {
    textApp: {
      get: function name() {
        return this.$store.getters.getTextApplication;
      },
    },
    token() {
      return this.$store.getters.getToken;
    },
    credentials: {
      get: function() {
        return this.$store.getters.getCredentials;
      },
      set: function(value) {
        this.$store.dispatch("setCredentials", value);
      }
    },
    company: {
      get: function() {
        return this.$store.getters.getCompany;
      },
      set: function(value) {
        this.$store.dispatch("setCompany", value);
      }
    },
    labelReport() {
      let type = null;
      if (this.reportType == "generic") {
        type = "RID";
      } else if (this.reportType == "categ") {
        type = "Categorie GDPR";
      } else {
        type = "Eventi GDPR";
      }
      return (
        type + ": " + this.filters.startDate + " - " + this.filters.endDate
      );
    }
  },
  methods: {
    closeDialog() {
      this.axios({
        method: "POST",
        url: "/warnings/new",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`
        },
        data: {
          title: this.title,
          description: this.description,
          metadata: {
            type: this.reportType,
            filters: this.filters
          },
          company: this.company.id,
          sender: this.credentials.id,
          ids_collaborators: this.ids_collaborators
        }
      }).then(resp => {
        console.log(resp.data);
        this.dialog = false;
        this.$emit("refreshPage");
      });
    }
  }
};
</script>
<style scoped></style>
